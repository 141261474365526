import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RecordsNotValid = (props) => (
  <SvgIcon {...props} width='40px' height='40px' viewBox='0 0 40 40'>
    <title>Records-NotValid</title>
    <g
      id='User-Tables'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Workflow---User-Table-Upload-1.5.1'
        transform='translate(-807.000000, -570.000000)'
      >
        <g id='Content' transform='translate(234.000000, 166.000000)'>
          <g id='Upload-Preview' transform='translate(548.000000, 74.000000)'>
            <g id='Content' transform='translate(20.000000, 13.000000)'>
              <g id='Icons-v3' transform='translate(5.000000, 37.000000)'>
                <g
                  id='Records-NotValid'
                  transform='translate(0.000000, 280.000000)'
                >
                  <g
                    id='note-validation'
                    transform='translate(5.692308, 5.692308)'
                  >
                    <path
                      d='M3.81004324,8.19505957 L2.1013582,6.48637447 C1.60082937,5.98584564 1.60082937,5.15728272 2.1013582,4.65675388 C2.60188704,4.15622504 3.43034436,4.15622504 3.9308732,4.65675388 L4.75935371,5.48528707 L7.52085007,2.9307924 C8.03863856,2.44752316 8.86709628,2.48210979 9.35036552,2.99989828 C9.83363476,3.51768677 9.79915347,4.34614401 9.28136499,4.82941324 L5.60505802,8.22950716 C5.34616377,8.45388216 5.03547386,8.57472054 4.72480077,8.57472054 C4.39686807,8.57472054 4.06893749,8.45395381 3.81004324,8.19505957 Z'
                      id='Vector'
                      fill='#FF9800'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M8.05448729,16.9719554 C7.58272447,16.9719554 7.19150651,16.5807374 7.19150651,16.1089746 C7.19150651,15.6372118 7.58272447,15.2459938 8.05448729,15.2459938 L13.8076925,15.2459938 C14.2794553,15.2459938 14.6706733,15.6372118 14.6706733,16.1089746 C14.6706733,16.5807374 14.2794553,16.9719554 13.8076925,16.9719554 L8.05448729,16.9719554 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M8.05448729,12.3693912 C7.58272447,12.3693912 7.19150651,11.9781732 7.19150651,11.5064104 C7.19150651,11.0346476 7.58272447,10.6434296 8.05448729,10.6434296 L17.2596156,10.6434296 C17.7313784,10.6434296 18.1225964,11.0346476 18.1225964,11.5064104 C18.1225964,11.9781732 17.7313784,12.3693912 17.2596156,12.3693912 L8.05448729,12.3693912 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M23.3004811,18.4102567 L23.3004811,9.19362166 C23.3004811,4.32641003 21.6435579,3.33685903 18.3642311,3.16426286 L11.5064104,3.16426286 C11.0346476,3.16426286 10.6434296,2.77304491 10.6434296,2.30128208 C10.6434296,1.82951926 11.0346476,1.4383013 11.5064104,1.4383013 L18.4102567,1.4383013 C23.1278848,1.69144233 25.0264427,3.9351919 25.0264427,9.19362166 L25.0264427,18.4102567 C25.0264427,18.8820195 24.6352247,19.2732374 24.1634619,19.2732374 C23.6916991,19.2732374 23.3004811,18.8820195 23.3004811,18.4102567 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M10.3557694,26.1768138 C3.88916685,26.1768138 2.58894234,23.1966527 2.58894234,18.3869734 L2.58894234,10.3670061 C2.58894234,9.89524328 2.9801603,9.50402532 3.45192312,9.50402532 C3.92368595,9.50402532 4.31490391,9.89524328 4.31490391,10.3670061 L4.31490391,18.3869734 C4.31490391,22.6673581 5.15487189,24.4508522 10.3557694,24.4508522 L17.2596156,24.4508522 C17.7313784,24.4508522 18.1225964,24.8420701 18.1225964,25.313833 C18.1225964,25.7855958 17.7313784,26.1768138 17.2596156,26.1768138 L10.3557694,26.1768138 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M16.9259298,26.1080458 C16.6037503,25.9699689 16.3966348,25.6592952 16.3966348,25.3141029 L16.3966348,21.8621798 C16.3966348,19.0776284 17.9269873,17.5472759 20.7115388,17.5472759 L24.1634619,17.5472759 C24.5086542,17.5472759 24.8308343,17.7543913 24.9574048,18.0765708 C25.0839753,18.3987503 25.014937,18.7669562 24.7733024,19.0200972 L17.8694551,25.9239435 C17.7083653,26.0850332 17.4782374,26.1770837 17.2596156,26.1770837 C17.1445515,26.1770837 17.0409939,26.1540715 16.9259298,26.1080458 Z M18.1225964,21.8621798 L18.1225964,23.2314432 L22.0808022,19.2732374 L20.7115388,19.2732374 C18.8935259,19.2732374 18.1225964,20.0441669 18.1225964,21.8621798 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                    ></path>
                    <path
                      d='M27.615385,0 L27.615385,27.615385 L0,27.615385 L0,0 L27.615385,0 Z'
                      id='Vector'
                      opacity='0'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default RecordsNotValid;
