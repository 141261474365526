import React from 'react';
import { Route as BaseRoute } from 'react-router';
import { Layout } from '../../components/Layout/index.js';
import { useLocation } from 'react-router-dom';
import RouteErrorBoundary from './RouteErrorBoundary.js';

export default (props) => {
  const {
    component: Component,
    isPrivate,
    noLayout,
    isInternal,
    noScroll,
    logo,
    setLogo,
    isNewLogo,
    orgConfig,
    ...rest
  } = props;
  const location = useLocation();

  const WrappedComponent = noLayout ? (
    <RouteErrorBoundary>
      <Component {...props} />
    </RouteErrorBoundary>
  ) : (
    <Layout
      noScroll={noScroll}
      logo={logo}
      setLogo={setLogo}
      isNewLogo={isNewLogo}
      orgConfig={orgConfig}
    >
      <RouteErrorBoundary>
        <Component {...props} />
      </RouteErrorBoundary>
    </Layout>
  );

  return (
    <BaseRoute {...rest} location={location} render={(_) => WrappedComponent} />
  );
};
