import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { Link } from 'react-router-dom';
import { InputLabel, MenuItem, FormControl, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Select, TextField } from 'formik-mui';
import FormDialog from '../../../components/FormDialog';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useApiPut } from '../../../utils/hooks';
import { useTemplateContext } from '../TemplateContext';

const editDetailsSchema = yup.object().shape({
  templateName: yup
    .string()
    .max(50, 'Name must be at most 50 characters')
    .required('Name is a required field'),
  product: yup.string().required('Product is a required field'),
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  label: {
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectAdornment: {
    marginRight: theme.spacing(3),
  },
  staticLabel: {
    fontWeight: 500,
    fontSize: '1.1rem',
  },
}));

const EditDetails = ({
  open,
  setOpen,
  template,
  setTemplate: setParentTemplate,
}) => {
  const classes = useStyles();
  const { setTemplates: setParentTemplates } = useTemplateContext() || {
    setTemplates: () => {},
  };
  const [newName, setNewName] = useState(null); //hate this but doing it for now... will fix later by moving effect
  const {
    isLoading: loading,
    error,
    fire: makeRequest,
    request,
  } = useApiPut('/templates/update');

  useEffect(() => {
    if (request.isSuccess) {
      //update parent template list and template data so the surrounding ui updates accordingly based on our actions
      let revisedTemplate = {};
      setParentTemplate((prevTemplate) => {
        revisedTemplate = {
          ...prevTemplate,
          name: newName,
        };
        return revisedTemplate;
      });
      setParentTemplates((prevTemplates) => {
        return prevTemplates?.map((template) => {
          return template.id === revisedTemplate.id
            ? revisedTemplate
            : template;
        });
      });

      setNewName(null);
      setOpen(false);
      toast.success('Your changes were successfully saved.');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.isSuccess, error, newName]);

  let createdBy = template?.createdByName ? (
    <Link to={`/user/${template.createdBy}`}>{template.createdByName}</Link>
  ) : (
    'N/A'
  );

  let createdAt = new Date(template?.createdAt);
  if (isNaN(createdAt)) {
    createdAt = 'N/A';
  } else {
    createdAt =
      createdAt.toLocaleDateString() +
      ' at ' +
      createdAt.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  }

  let publishedBy = template?.publishedByName ? (
    <Link to={`/user/${template.publishedBy}`}>{template.publishedByName}</Link>
  ) : (
    'N/A'
  );

  let publishedTime = new Date(template?.publishedTime);
  if (isNaN(publishedTime)) {
    publishedTime = 'N/A';
  } else {
    publishedTime =
      publishedTime.toLocaleDateString() +
      ' at ' +
      publishedTime.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
      });
  }

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      title='Edit Template Details'
      saveButtonText='Save'
      maxWidth='sm'
      formikProps={{
        initialValues: {
          templateName: template?.name,
          product: template?.product,
        },
        validationSchema: editDetailsSchema,
        onSubmit: (values) => {
          setNewName(values.templateName);
          makeRequest({
            template_id: template?.id,
            name: values.templateName,
          });
        },
        enableReinitialize: true,
      }}
      loading={loading}
      checkUnsavedChanges
    >
      {() => (
        <>
          <FormControl variant='outlined' className={classes.formControl}>
            <Field
              component={TextField}
              label='Name'
              type='text'
              name='templateName'
              fullWidth
            />
          </FormControl>

          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel className={classes.label} id='product-label'>
              Product
            </InputLabel>
            <Field
              component={Select}
              labelId='product-label'
              type='text'
              name='product'
              fullWidth
              disabled
            >
              <MenuItem value='risk-messenger'>Risk Messenger</MenuItem>
            </Field>
          </FormControl>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <span className={classes.staticLabel}>Created By</span>
              <br />
              <span>{createdBy}</span>
            </Grid>

            <Grid item xs={12} sm={6}>
              <span className={classes.staticLabel}>Created Date</span>
              <br />
              <span>{createdAt}</span>
            </Grid>

            <Grid item xs={12} sm={6}>
              <span className={classes.staticLabel}>Published By</span>
              <br />
              <span>{publishedBy}</span>
            </Grid>

            <Grid item xs={12} sm={6}>
              <span className={classes.staticLabel}>Published Date</span>
              <br />
              <span>{publishedTime}</span>
            </Grid>
          </Grid>

          <br />
        </>
      )}
    </FormDialog>
  );
};

export default EditDetails;
