import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, Button } from '@mui/material';
import {
  getSelectionText,
  getEntityRange,
  getSelectionEntity,
} from 'draftjs-utils';
import PickerBase from './PickerBase.js';
import usePrevious from '../../../utils/hooks/usePrevious.js';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginBottom: '1rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: '.5rem',
    '& > .MuiButton-root': {
      marginLeft: '.5rem',
    },
  },
}));

const LinkPicker = (props) => {
  const { show, editorState, onClose, onChange } = props;
  const classes = useStyles();
  const [link, setLink] = useState({
    title: '',
    target: '',
    modifying: false,
  });
  const prevProps = usePrevious({ show });

  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (show && !prevProps.show) {
      //link picker was just opened so populate defaults

      const { link: selectedLink, selectionText } = getCurrentValues();

      setLink({
        title: selectedLink?.title || selectionText,
        target: selectedLink?.target,
        modifying: !!selectedLink,
      });
    }
  });

  const getCurrentValues = () => {
    const currentEntity = getSelectionEntity(editorState);
    const contentState = editorState.getCurrentContent();
    const currentValues = {};
    if (
      currentEntity &&
      contentState.getEntity(currentEntity).get('type') === 'LINK'
    ) {
      currentValues.link = {};
      const entityRange =
        currentEntity && getEntityRange(editorState, currentEntity);
      currentValues.link.target =
        currentEntity && contentState.getEntity(currentEntity).get('data').url;
      currentValues.link.targetOption =
        currentEntity &&
        contentState.getEntity(currentEntity).get('data').targetOption;
      currentValues.link.title = entityRange && entityRange.text;
    }
    currentValues.selectionText = getSelectionText(editorState);
    return currentValues;
  };

  const handleChange = (e) => {
    setLink({
      ...link,
      [`${e.target.name}`]: e.target.value,
    });
  };

  const handleAdd = () => {
    const { title, target } = link;
    if (!title || !target) {
      return;
    }

    onChange(title, target);
    onClose();
  };

  const { title, target, modifying } = link;

  return (
    <PickerBase {...props} onMouseDown={false}>
      <div className={classes.form}>
        <TextField
          name='title'
          label='Link Title'
          placeholder='The text that will show'
          variant='outlined'
          size='small'
          value={title}
          onChange={handleChange}
          onBlur={handleChange}
        />
        <TextField
          name='target'
          label='Link Target'
          placeholder='The url (e.g. http://google.com)'
          variant='outlined'
          size='small'
          value={target}
          onChange={handleChange}
          onBlur={handleChange}
        />
      </div>
      <div className={classes.actions}>
        <Button
          color='primary'
          size='small'
          onClick={handleAdd}
          disabled={!title || !target}
        >
          {modifying ? 'Change' : 'Add'}
        </Button>
        <Button color='default' size='small' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </PickerBase>
  );
};

export default LinkPicker;
