import React from 'react';
import { Route as BaseRoute } from 'react-router';
import { useLocation } from 'react-router-dom';
import { EulaAuthProvider } from './../eulaAuth.js';
import RouteErrorBoundary from './RouteErrorBoundary.js';

export default (props) => {
  const { component: Component, ...rest } = props;
  const location = useLocation();

  return (
    <BaseRoute
      {...rest}
      location={location}
      render={(_) => (
        <EulaAuthProvider>
          <RouteErrorBoundary>
            <Component {...props} />
          </RouteErrorBoundary>
        </EulaAuthProvider>
      )}
    />
  );
};
