import React, { useState, memo, useEffect, useRef, useCallback } from 'react';
import {
  Button,
  Tabs,
  Tab,
  Tooltip,
  InputLabel,
  FormControl,
  Select,
  ButtonGroup,
  Skeleton,
  colors,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import { makeStyles } from '@mui/styles';
import {
  StatusChip,
  TooltipButton,
  TooltipTab,
  SendTest,
  EditDetails,
  Preview,
  Distributions,
  TemplateEditor,
} from './';
import { setQueryStringParameter } from '../../../utils/routes';
import {
  UNSAVED_CHANGES_CONFIRM_MESSAGE,
  UnsavedChangesPrompt,
} from '../../../components/UnsavedChangesPrompt';
import { toast } from 'react-toastify';
import { useApiGet, usePrevious } from '../../../utils/hooks';

const TAB_EDITOR = 0;
const TAB_DISTRIBUTIONS = 1;

const useStyles = makeStyles((theme) => ({
  messagingRootContainer: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
  },
  contentBody: {
    width: '100%',
    maxWidth: '1024px',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  header: {
    background: 'white',
    border: `1px solid ${colors.grey[300]}`,
    borderRadius: '4px',
    padding: '.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    height: '55px',
  },
  headerBody: {
    flex: 1,
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  templateTabs: {
    height: '55px',
    '& > div, & > div > div': {
      height: '100%',
    },
    '& button': {
      height: '100%',
    },
    '& .Mui-selected': {
      color: theme.palette.common.black,
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  templateName: {
    display: 'inline-block',
    width: '30%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '1.5rem',
    paddingRight: '1rem',
  },
  tabText: {
    display: 'inline-flex',
    gap: '.5rem',
    alignItems: 'center',
  },
  bodyContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    minHeight: '300px',
    overflow: 'hidden',
  },
  actionHeader: {
    background: 'white',
    border: `1px solid ${colors.grey[300]}`,
    height: '65px',
    borderRadius: '4px 4px 0 0',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    gap: '.5rem',
    '& .MuiNativeSelect-select.MuiNativeSelect-outlined.MuiOutlinedInput-input':
      {
        padding: '8px 32px 8px 12px',
      },
    '& .MuiChip-root': {
      height: 'auto',
      padding: '4px',
    },
    '& > div:first-of-type': {
      flex: 1,
      alignItems: 'center',
      display: 'flex',
      gap: '.5rem',
    },
  },
  editorPanel: {
    flex: 1,
    background: colors.grey[200],
    border: `1px solid ${colors.grey[300]}`,
    borderTop: '0 none',
    borderBottom: '0 none',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '3rem 5rem',
  },
  distributionsPanel: {
    flex: 1,
    background: 'white',
    border: `1px solid ${colors.grey[300]}`,
    borderTop: '0 none',
    borderBottom: '0 none',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '1.5rem 2rem',
  },
  actionFooter: {
    background: 'white',
    border: `1px solid ${colors.grey[300]}`,
    height: '65px',
    borderRadius: '0 0 4px 4px',
    display: 'flex',
    gap: '.5rem',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
  },
  publishedMessage: {
    flex: 1,
    maxWidth: '50%',
    alignSelf: 'center',
    marginRight: 'auto',
    padding: '1rem',
    fontSize: '.85rem',
    color: colors.grey[600],
  },
  unsavedMessage: {
    color: theme.palette.error.main,
  },
}));

const EditTemplate = memo(({ id }) => {
  const classes = useStyles();
  const { error, data } = useApiGet(`/templates/${id}`, { fireOnMount: true });
  const [template, setTemplate] = useState(null);
  const [tab, setTab] = useState(
    parseInt(new URLSearchParams(window.location.search)?.get('tab')) ||
      TAB_EDITOR
  );
  const [previewOpen, setPreviewOpen] = useState(false);
  const [sendTestOpen, setSendTestOpen] = useState(false);
  const [editDetailsOpen, setEditDetailsOpen] = useState(false);
  const [version, setVersion] = useState('draft');
  const editorRef = useRef(null);
  const distributionsRef = useRef(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saving, setSaving] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const history = useHistory();
  const prevProps = usePrevious({ id });

  useEffect(() => {
    setTemplate(data); //this just sets the template state based off the api return data (have to set it indirectly like this so we can manually override it later)
  }, [data]);

  useEffect(() => {
    //handle error occurrence when loading template data

    if (error) {
      toast.error('The selected template could not be found.');
      history.push('/messaging');
    }
  }, [error, history]);

  useEffect(() => {
    if (template?.status === 'draft' && tab !== TAB_EDITOR) {
      setTab(0); //go back to the editor tab if somehow not set to it and template is only a draft
    }
  }, [template, tab]);

  useEffect(() => {
    if (prevProps?.id && prevProps?.id !== id) {
      setTab(0); //go back to the editor tab if switching templates
    }
  }, [id, prevProps]);

  useEffect(() => {
    setVersion(template?.status === 'published' ? 'published' : 'draft'); //reset default version when template changes
  }, [template]);

  const onTabChange = (e, value) => {
    if (!hasUnsavedChanges || window.confirm(UNSAVED_CHANGES_CONFIRM_MESSAGE)) {
      setTab(value);
      setQueryStringParameter('tab', value === TAB_EDITOR ? '' : value);
    }
  };

  const handleVersion = useCallback(
    (e) => {
      if (
        !hasUnsavedChanges ||
        window.confirm(UNSAVED_CHANGES_CONFIRM_MESSAGE)
      ) {
        setVersion(typeof e === 'object' ? e.target.value : e);
      }
    },
    [hasUnsavedChanges]
  );

  const handleSavePub = (action) => {
    if (saving || publishing) {
      return;
    }

    if (tab === TAB_EDITOR) {
      if (editorRef?.current) {
        editorRef.current[action === 'save' ? 'handleSave' : 'handlePublish']();
      }
    } else if (tab === TAB_DISTRIBUTIONS) {
      if (distributionsRef?.current && action === 'save') {
        distributionsRef.current.handleSave();
      }
    }
  };

  const handleSave = () => {
    handleSavePub('save');
  };

  const handlePublish = () => {
    handleSavePub('publish');
  };

  return (
    <div className={classes.contentBody}>
      <UnsavedChangesPrompt
        hasUnsavedChanges={!template ? false : hasUnsavedChanges}
      />

      <div className={classes.header}>
        <div className={classes.headerBody}>
          <div className={classes.templateName}>
            {template?.name ? (
              <Tooltip title={template.name}>
                <span>{template.name}</span>
              </Tooltip>
            ) : (
              <Skeleton animation='wave' />
            )}
          </div>
          <Tabs
            className={classes.templateTabs}
            value={tab}
            onChange={onTabChange}
            indicatorColor='primary'
          >
            <Tab
              label={
                <div className={classes.tabText}>
                  <FormatShapesIcon />
                  Editor
                </div>
              }
            />
            {!template?.status || template?.status === 'draft' ? (
              <TooltipTab
                tooltip='You must publish a template before you can manage it’s distributions.'
                label={
                  <div className={classes.tabText}>
                    <AllInboxIcon />
                    Distributions
                  </div>
                }
                disabled
              />
            ) : (
              <Tab
                label={
                  <div className={classes.tabText}>
                    <AllInboxIcon />
                    Distributions
                  </div>
                }
              />
            )}
          </Tabs>
        </div>
        <Button
          variant='outlined'
          size='small'
          color='default'
          startIcon={<EditIcon />}
          disabled={!template}
          onClick={() => {
            setEditDetailsOpen(true);
          }}
        >
          Details
        </Button>
      </div>
      <div className={classes.bodyContainer}>
        <div className={classes.actionHeader}>
          {tab === TAB_EDITOR && (
            <>
              <div>
                <FormControl
                  variant='outlined'
                  disabled={!template?.status || template?.status === 'draft'}
                >
                  <InputLabel htmlFor='outlined-age-native-simple'>
                    Version
                  </InputLabel>
                  <Select
                    native
                    label='Version'
                    value={version}
                    onChange={handleVersion}
                  >
                    <option value='draft'>Editable Draft</option>
                    <option value='published'>Published</option>
                  </Select>
                </FormControl>
                {template && <StatusChip status={template?.status} />}
              </div>
              {version === 'draft' && (
                <ButtonGroup color='default'>
                  <TooltipButton
                    tooltip='Preview this template version'
                    disabled={!template}
                    onClick={() => {
                      setPreviewOpen({
                        ...template,
                        draftContent: editorRef?.current?.getHtml(),
                      });
                    }}
                  >
                    <VisibilityIcon style={{ fontSize: '1.25rem' }} />
                  </TooltipButton>
                  <TooltipButton
                    tooltip='Send a test message'
                    disabled={!template}
                    onClick={() => {
                      setSendTestOpen(true);
                    }}
                  >
                    <SendIcon style={{ fontSize: '1.25rem' }} />
                  </TooltipButton>
                </ButtonGroup>
              )}
            </>
          )}
        </div>
        <div
          className={
            tab === TAB_EDITOR
              ? classes.editorPanel
              : classes.distributionsPanel
          }
        >
          {tab === TAB_EDITOR ? (
            <TemplateEditor
              ref={editorRef}
              template={template}
              version={version}
              setSaving={setSaving}
              setPublishing={setPublishing}
              setHasUnsavedChanges={setHasUnsavedChanges}
              setTemplate={setTemplate}
              setVersion={handleVersion}
            />
          ) : (
            <Distributions
              ref={distributionsRef}
              template={template}
              setSaving={setSaving}
              setHasUnsavedChanges={setHasUnsavedChanges}
              setTemplate={setTemplate}
            />
          )}
        </div>
        <div className={classes.actionFooter}>
          {version === 'published' && tab === TAB_EDITOR && (
            <div className={classes.publishedMessage}>
              This is the distributed version of this template. Switch to the
              "editable draft" version to make changes.
            </div>
          )}
          {hasUnsavedChanges && (
            <div className={classes.unsavedMessage}>Unsaved changes</div>
          )}
          {(version === 'draft' || tab === TAB_DISTRIBUTIONS) && (
            <LoadingButton
              variant='contained'
              color='primary'
              disabled={!hasUnsavedChanges || saving || publishing}
              onClick={handleSave}
              loading={saving}
            >
              Save Changes
            </LoadingButton>
          )}
          {tab === TAB_EDITOR && version === 'draft' && (
            <LoadingButton
              variant='contained'
              color='success'
              disabled={!template || saving || publishing}
              loading={publishing}
              onClick={handlePublish}
            >
              Publish
            </LoadingButton>
          )}
          <Button
            variant='outlined'
            color='default'
            onClick={() => {
              setHasUnsavedChanges(false);
              setTimeout(() => {
                history.push('/messaging'); //hacky way to wait for hasChanged to finish so the unsaved prompt doesn't show
              }, 50);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
      <Preview
        open={!!previewOpen}
        setOpen={setPreviewOpen}
        handleSendTest={() => {
          setPreviewOpen(false);
          setSendTestOpen(true);
        }}
        template={previewOpen}
      />
      <SendTest
        open={sendTestOpen}
        setOpen={setSendTestOpen}
        product={template?.product} /* to tell what data tags to use */
        templateHtml={editorRef?.current?.getHtml()}
      />
      <EditDetails
        open={editDetailsOpen}
        setOpen={setEditDetailsOpen}
        template={template}
        setTemplate={setTemplate}
      />
    </div>
  );
});

export default EditTemplate;
