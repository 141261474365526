import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import {
  FormControl,
  MenuItem,
  CircularProgress,
  Box,
  Typography,
  DialogContentText,
  Divider,
  Collapse,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Select } from 'formik-mui';
import FormDialog from './FormDialog';
import { useApiGet, useApiPost } from '../utils/hooks';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const migrateSchema = yup.object().shape({
  organization: yup.string().required('You must select an iBank organization'),
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  label: {
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  orgSelect: {
    paddingRight: '3rem',
  },
  progressOverlay: {
    position: 'absolute',
    inset: 0,
    background: 'rgba(255, 255, 255, .65)',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress variant='determinate' {...props} size='5rem' />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          variant='caption'
          component='div'
          color='textSecondary'
          style={{ fontSize: '1rem' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const MigrateIBankUsers = ({ open, setOpen, destinationOrg }) => {
  const classes = useStyles();
  const [jobId, setJobId] = useState();
  const [progress, setProgress] = useState(0);
  const [progressInterval, setProgressInterval] = useState();

  const {
    fire: getOrgs,
    isLoading: loadingOrgs,
    data: organizations,
  } = useApiGet(`/ibank/organizations?org=${destinationOrg?.name || ''}`);

  useEffect(() => {
    if (!organizations?.length && open && destinationOrg?.name) {
      getOrgs();
    }
  }, [getOrgs, organizations, open, destinationOrg]);

  const {
    request: postRequest,
    error: postError,
    fire: makeRequest,
    data: postData,
    isLoading: loading,
  } = useApiPost('/users/migrate-ibank');

  const {
    request: getRequest,
    fire: checkProgress,
    error: progressError,
    data: progressData,
  } = useApiGet(`/users/migrate-ibank/${jobId}/progress`);

  useEffect(() => {
    if (postError) {
      toast.error(postError);
    }

    if (postRequest.isSuccess && !jobId) {
      setJobId(postData.jobId);
    }
    // eslint-disable-next-line
  }, [postRequest.isSuccess, postData, postError]);

  useEffect(() => {
    if (jobId && !progressInterval && progress !== 100) {
      //start the check progress interval (first check is in a second in case we only migrated a few users fast)
      setProgressInterval(
        setTimeout(() => {
          checkProgress();
        }, 1000)
      );
    }
    // eslint-disable-next-line
  }, [jobId, progressInterval, progress]);

  useEffect(() => {
    if (progressError) {
      toast.error(progressError);

      if (progressInterval) {
        //clear the progress interval if set

        clearTimeout(progressInterval);
        setProgressInterval(null);
      }
    }

    if (getRequest.isSuccess) {
      //the interval loop for checking progress every 5 seconds

      if (progressData?.progress > progress) {
        setProgress(+progressData?.progress);
      }

      setProgressInterval(
        setTimeout(() => {
          checkProgress();
        }, 5000)
      );
    }
    // eslint-disable-next-line
  }, [progressError, getRequest.isSuccess, progressData]);

  useEffect(() => {
    //check if job is complete
    if (progress === 100 && progressInterval) {
      clearTimeout(progressInterval); //clear the check progress timeout
      setProgressInterval(null);

      //wait 1 second before showing success message and closing the modal to make the experience less jarring
      setTimeout(() => {
        toast.success('Users successfully migrated.');
        setOpen(false);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [progress]);

  return (
    <FormDialog
      open={open}
      onOpen={() => {
        setJobId(null);
        setProgress(0);
      }}
      onClose={() => {
        setJobId(null);
        setProgress(0);
        if (progressInterval) {
          clearTimeout(progressInterval);
          setProgressInterval(null);
        }
      }}
      setOpen={setOpen}
      maxWidth='sm'
      saveButtonText='Migrate'
      title='Migrate iBank Users'
      formikProps={{
        initialValues: {
          organization: '',
          useExternal: false,
          provider: '',
        },
        validationSchema: migrateSchema,
        onSubmit: (values) => {
          if (loading) {
            return;
          }

          makeRequest({
            sourceOrg: values?.organization,
            destinationOrg: destinationOrg?.name,
            useExternal: values?.useExternal,
            provider: values?.provider,
          });
        },
        enableReinitialize: true,
      }}
      loading={loadingOrgs || loading || !!jobId}
    >
      {({ values, setFieldValue }) => (
        <div style={{ position: 'relative' }}>
          {jobId && (
            <div className={classes.progressOverlay}>
              <CircularProgressWithLabel value={progress || 0} />
            </div>
          )}

          <DialogContentText>
            Select the iBank Organization you would like to migrate users from:
          </DialogContentText>

          <FormControl variant='outlined' className={classes.formControl}>
            {/* <InputLabel className={classes.label} id='org-label'>
              iBank Organization
            </InputLabel> */}
            <Field
              style={{ margin: '0px 159px 0px 0px' }}
              label='iBank Organization'
              component={Select}
              labelId='org-label'
              type='text'
              name='organization'
              fullWidth
              variant='outlined'
              endAdornment={
                loadingOrgs ? (
                  <CircularProgress color='inherit' size={20} />
                ) : undefined
              }
              className={classes.orgSelect}
              disabled={loading || !organizations?.length}
            >
              {loadingOrgs ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : (
                organizations?.map((org) => (
                  <MenuItem value={org.id} key={org.name}>
                    {org.name}
                  </MenuItem>
                ))
              )}
            </Field>
          </FormControl>

          {destinationOrg?.displayName && (
            <DialogContentText>
              Users will be added to "{destinationOrg.displayName}" within the
              hub. Please note: iBank organizations are not equivalent to hub
              organizations.
            </DialogContentText>
          )}

          {destinationOrg?.usesOwnIdp && (
            <>
              <Divider className={classes.divider} />

              <FormControlLabel
                name='useExternal'
                checked={values.useExternal}
                onClick={() => {
                  setFieldValue('provider', ''); //reset provider value on toggle
                  setFieldValue('useExternal', !values.useExternal);
                }}
                control={<Switch />}
                label='Use External IDP'
              />

              <Collapse in={values.useExternal}>
                <FormControl variant='outlined' className={classes.formControl}>
                  {/* <InputLabel className={classes.label} id='idp-name'>
                    Provider Name
                  </InputLabel> */}
                  <Field
                    label='Provider Name'
                    style={{ margin: '0px 159px 0px 0px' }}
                    component={Select}
                    labelId='idp-name'
                    type='text'
                    name='provider'
                    fullWidth
                    variant='outlined'
                  >
                    {destinationOrg?.config?.providers.map((p) => (
                      <MenuItem value={p.name} key={p.name}>
                        {`${p.name}`}
                        {p.description && ` - ${p.description}`}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Collapse>
            </>
          )}
        </div>
      )}
    </FormDialog>
  );
};

export default MigrateIBankUsers;
