import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIsMobileDevice } from '../utils/hooks';

const useStyles = makeStyles((theme) => ({
  title: (props) => ({
    fontSize: props.fontSize,
    fontWeight: '300',
    marginTop: 0,
    marginBottom: theme.spacing(1),
  }),
  subtitle: {
    marginTop: '-.5rem',
    color: theme.palette.text.secondary,
  },
}));

export default function Title(props) {
  const { title, subtitle, variant } = props;
  const isMobile = useIsMobileDevice();
  const fontSizeProps = { fontSize: isMobile ? '16px' : '26px' };
  const classes = useStyles(fontSizeProps);

  return (
    <>
      <Typography variant={variant} className={classes.title}>
        {title}
      </Typography>
      {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
    </>
  );
}
