import React from 'react';

import { useAuthContext } from '../utils/auth/hooks';
import { CIS_INTERNAL } from '../utils/auth/constants';
import IQCXProductAcctSelect from './iQCXProductAcctSelect';

export default function IQCXOrgSelect({ style, value, changeAccts }) {
  const { roleIsAtLeast } = useAuthContext();
  return (
    <>
      {roleIsAtLeast(CIS_INTERNAL) && (
        <IQCXProductAcctSelect
          multiple={true}
          value={value}
          onChange={(e, value) => {
            changeAccts(value);
          }}
          style={style}
        />
      )}
    </>
  );
}
