import React, { useState, useEffect } from 'react';
import Loader from '../../../components/Loader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useApiGet, useApiPost } from '../../../utils/hooks';
import DeleteIcon from '@mui/icons-material/Delete';

const useRestoreDialogStyles = makeStyles((theme) => ({
  maintxt: {
    fontWeight: '500',
    fontSize: 'large',
  },
  bottomtxt: {
    opactiy: '0.65',
  },
  pointer: {
    cursor: 'pointer',
  },
  rowselected: {
    boxShadow: '0px 1px 4px 0px #0061ad',
    padding: '5px',
    border: '1px solid #0061ad',
    borderRadius: '5px',
    marginTop: '5px',
  },
  ptop: {
    paddingTop: '1.5rem',
  },
}));

function TableViewerRestoreDialog(props) {
  const { openRestoreDialog, closeRestoreDialog } = props;
  const [selectedBackupKey, setSelectedBackupKey] = useState('');

  const handleSlectedBackupKey = (selKey) => {
    setSelectedBackupKey(selKey);
  };

  const handleRestoreCloseDialog = () => {
    props.closeRestoreDialog(false);
  };
  return (
    <Dialog open={openRestoreDialog} onClose={closeRestoreDialog}>
      <DialogTitle style={{ fontWeight: 'bold' }}>
        Restore from Backups
      </DialogTitle>
      <DialogContent>
        <BackupsList handleSlectedBackupKey={handleSlectedBackupKey} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRestoreCloseDialog} color='error'>
          Cancel
        </Button>
        <RestoreBackupButton backupKey={selectedBackupKey} />
      </DialogActions>
    </Dialog>
  );
}

export default TableViewerRestoreDialog;

function BackupsList(props) {
  const { handleSlectedBackupKey } = props;
  const {
    params: { id, acct },
  } = useRouteMatch('/workflow-tables/:acct/:id');

  const classes = useRestoreDialogStyles();
  const backupDetails = useApiGet(`/workflow-tables/${acct}/${id}/versions`, {
    fireOnMount: true,
  });
  const { isLoading: loading, error, data } = backupDetails;
  const availableBackups = data?.backupItems
    ? (data?.backupItems).sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    : [];
  const [rowClass, setRowClass] = useState(false);
  const [rowSelected, setRowSelected] = useState(false);

  // Error notification if get Backups request fails.
  useEffect(() => {
    if (backupDetails.error) {
      toast.error(backupDetails.error);
    }
  }, [backupDetails.error]);

  if (loading || error) return <Loader />;

  const handleRestoreSelect = (selectedRow, keySelected) => {
    handleSlectedBackupKey(keySelected);
    setRowSelected(selectedRow);
    //document.getElementById(selectedRow).classList.add('rowselected');
    setRowClass(true);
  };

  const getItemInfo = (date) => {
    const dateFormat = date.split('T');
    const date1 = dateFormat[1].split('.');
    const date2 = date1[0].split(':');
    const date3 = date2[0] + ':' + date2[1];
    return dateFormat[0] + ' at ' + date3 + ' GMT';
  };

  return (
    <Grid container rowSpacing={3} spacing={2}>
      {data?.count ? (
        availableBackups.map((backup, i) => (
          <>
            <Grid item xs={10} className={classes.pointer}>
              <div
                id={'selectedbackup' + i}
                className={
                  rowClass && 'selectedbackup' + i === rowSelected
                    ? classes.rowselected
                    : ''
                }
                onClick={(e) =>
                  handleRestoreSelect('selectedbackup' + i, backup.s3Key)
                }
              >
                <Grid item xs={12} className={classes.maintxt}>
                  {getItemInfo(backup.createdAt)}
                </Grid>
                <Grid item xs={12} className={classes.bottomtxt}>
                  User:{' '}
                  {backup.createdBy !== undefined
                    ? backup.createdBy
                    : backup.org}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.ptop}
                startIcon={<DeleteIcon />}
                //onClick={() => handleRestoreSubmit(backupKey)}
              ></Button>
            </Grid>
          </>
        ))
      ) : (
        <Grid item xs={12}>
          No Backups Found.
        </Grid>
      )}
    </Grid>
  );
}

function RestoreBackupButton(props) {
  const { backupKey } = props;
  const [restoreBtnDisabled, setRestoreBtnDisabled] = useState(false);
  const {
    params: { id, acct },
  } = useRouteMatch('/workflow-tables/:acct/:id');

  const {
    request: postRestoreRequest,
    fireAsync: getBackup,
    error,
  } = useApiPost(`/workflow-tables/restore/${acct}/${id}`);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setRestoreBtnDisabled(false);
    }

    if (postRestoreRequest.isSuccess) {
      toast.success('Table Restored Successfully.');
      window.location.reload();
    }
  }, [error, postRestoreRequest.isSuccess]);

  async function handleRestoreSubmit(keys) {
    setRestoreBtnDisabled(true);
    await getBackup({ keys });
  }

  return (
    <Button
      variant='contained'
      onClick={() => handleRestoreSubmit(backupKey)}
      disabled={restoreBtnDisabled}
    >
      {' '}
      Restore
    </Button>
  );
}
