import React, { useState } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddBusiness from '@mui/icons-material/AddBusiness';
import { OrganizationTable } from '../../components/Table';
import { OrganizationCreate } from '../../components/OrganizationCreate';
import Loader from '../../components/Loader';
import RootContainer from '../../components/RootContainer.js';
import { useApiGet } from '../../utils/hooks';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageTitle from '../../components/PageTitle';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
}));

export const Organizations = ({ logo, setLogo }) => {
  const { data, isLoading: loading } = useApiGet('/organizations', {
    fireOnMount: true,
  });
  const [showOrgCreate, setShowOrgCreate] = useState(false);
  const classes = useStyles();

  return (
    <RootContainer>
      <PageTitle title='Organizations' />
      <Breadcrumbs
        trail={{
          Admin: null,
          Organizations: '/organizations',
        }}
      />
      <br />

      <div className={classes.filterContainer}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setShowOrgCreate(true)}
          startIcon={<AddBusiness fontSize='medium' />}
        >
          CREATE ORGANIZATION
        </Button>
      </div>
      {loading ? <Loader /> : <OrganizationTable data={data || []} />}
      <OrganizationCreate open={showOrgCreate} setOpen={setShowOrgCreate} />
    </RootContainer>
  );
};
