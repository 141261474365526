import React, { memo } from 'react';
import { Button, Tooltip } from '@mui/material';

const TooltipButton = memo(({ tooltip, children, disabled, ...rest }) => {
  return (
    <>
      {disabled ? (
        <Button disabled {...rest}>
          {children}
        </Button>
      ) : (
        <Tooltip title={tooltip}>
          <Button {...rest}>{children}</Button>
        </Tooltip>
      )}
    </>
  );
});

export default TooltipButton;
