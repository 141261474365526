import React from 'react';
import { FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FastField } from 'formik';
import { TextField } from 'formik-mui';
import shouldComponentUpdate from './shouldComponentUpdate.js';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function TextInput(props) {
  const { label, type, name } = props;
  const classes = useStyles();

  return (
    <FormControl variant='outlined' className={classes.formControl}>
      <FastField
        component={TextField}
        label={label}
        type={type ? type : 'text'}
        name={name}
        fullWidth
        variant='outlined'
        {...props}
        shouldUpdate={shouldComponentUpdate}
      />
    </FormControl>
  );
}
