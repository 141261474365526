import React, { useState, memo, useRef } from 'react';
import {
  Button,
  FormControlLabel,
  Checkbox,
  Popover,
  Grid,
  Divider,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterAlt';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles } from '@mui/styles';

const CHECKBOXES = {
  locked: {
    label: 'Table Locked',
  },
  internal_use_only: {
    label: 'Internal Use Only',
  },
  external_sync: {
    label: 'External Sync',
  },
  allow_resrule_edit: {
    label: 'Allow ResRule Edit',
  },
  allow_user_upload: {
    label: 'Allow User Upload',
  },
  // allow_user_edit: { label: 'Allow User Edit', value: true },
  // allowAPIEdits: { label: 'Allow API Edits', value: true },
  // allowSeletedEdits: { label: 'Allow Seleted Edits', value: true },
};

export const SELECT_BOXES = {
  table_type: {
    label: 'Table Type',
    options: [
      { label: '', value: null },
      {
        label: 'QueueManagement',
        value: 'QueueManagement',
      },
      {
        label: 'Standard',
        value: 'Standard',
      },
    ],
  },
  functional_point: {
    label: 'Functional Point',
    options: [
      { label: '', value: null },
      {
        label: 'iQCX Ticketing',
        value: 'iQCX Ticketing',
      },
      {
        label: 'SCM',
        value: 'SCM',
      },
      {
        label: 'Fare Checker',
        value: 'Fare Checker',
      },
      {
        label: 'Undefined',
        value: 'Undefined',
      },
      {
        label: 'Waitlist Checker',
        value: 'Waitlist Checker',
      },
      {
        label: 'Doc Delivery',
        value: 'Doc Delivery',
      },
      {
        label: 'Seat Checker',
        value: 'Seat Checker',
      },
      {
        label: 'File Finishing',
        value: 'File Finishing',
      },
      {
        label: 'Future Actions',
        value: 'Future Actions',
      },
      {
        label: 'PTA/PCM',
        value: 'PTA/PCM',
      },
    ],
  },
  libraryType: {
    label: 'Library Type',
    options: [
      { label: '', value: null },
      {
        label: 'Restricted',
        value: 'restricted',
      },
      {
        label: 'Locked Definition',
        value: 'locked_definition',
      },
      {
        label: 'Local',
        value: 'local',
      },
      {
        label: 'Locked Key',
        value: 'locked_key',
      },
    ],
  },
};

export const DEFAULT_FILTER_VALUES = {
  locked: false,
  internal_use_only: false,
  external_sync: false,
  allow_resrule_edit: false,
  allow_user_upload: false,
  // allow_user_edit: false,
  // allowAPIEdits: false,
  // allowSeletedEdits: false,
  table_type: '',
  functional_point: '',
  libraryType: '',
};

const useStyles = makeStyles((theme) => ({
  itemCheckbox: {
    marginTop: '-.5rem',
    marginBottom: '.5rem',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FilterBy = memo(({ filters, setFilters }) => {
  const filterMenuAnchorRef = useRef(null);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const classes = useStyles();

  const toggleCheckbox = (name) => {
    setFilters((prevValues) => {
      return {
        ...prevValues,
        [name]: !filters[name],
      };
    });
  };

  const setValueKey = (name, value) => {
    setFilters((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      <Button
        variant='text'
        startIcon={<FilterIcon />}
        ref={filterMenuAnchorRef}
        onClick={() => {
          setFilterMenuOpen(true);
        }}
      >
        Filter
      </Button>
      <Popover
        open={filterMenuOpen}
        anchorEl={filterMenuAnchorRef?.current}
        onClose={() => {
          setFilterMenuOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '1rem', width: '26em' }}>
          <Grid container spacing={2}>
            <Grid item xs>
              {Object.keys(CHECKBOXES).map((key) => {
                const filter = CHECKBOXES[key];
                return (
                  <div className={classes.itemCheckbox} key={key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                          checkedIcon={<CheckBoxIcon fontSize='small' />}
                          checked={filters && filters[key]}
                          onClick={() => toggleCheckbox(key)}
                        />
                      }
                      label={filter.label}
                    />
                    <br />
                  </div>
                );
              })}
              <Button
                onClick={() => {
                  setFilters(DEFAULT_FILTER_VALUES);
                }}
                color='secondary'
              >
                Clear Filters
              </Button>
            </Grid>
            <Grid item xs>
              {Object.keys(SELECT_BOXES).map((key, i) => {
                const filter = SELECT_BOXES[key];
                return (
                  <div key={key}>
                    <FormControl fullWidth>
                      <InputLabel id={key}>{filter.label}</InputLabel>
                      <Select
                        labelId={filter.value}
                        id={key}
                        label={filter.label}
                        name={key}
                        value={filters && filters[key]}
                        onChange={(e) => setValueKey(key, e.target.value)}
                      >
                        {filter.options.map((opt) => (
                          <MenuItem key={opt.value} value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* do not render divider under last selectbox */}
                    {i + 1 !== Object.keys(SELECT_BOXES).length && (
                      <Divider className={classes.divider} />
                    )}
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </Popover>
    </>
  );
});

export default FilterBy;
