/*
  This is a customized implementation of Draft.js to resemble a standard rich text editor (e.g. TinyMCE)

  The toolbar utilizes Material UI and which tools are available can be customized using the toolbarOptions prop. You can view TOOLBAR_DEFAULTS below to see the default enabled tools and override them by setting the key to true/false in the toolbarOptions prop like so:

  <RichTextEditor toolbarOptions={{ FONT_SIZE: false, ALIGN_JUSTIFY: true }} />

  If enabling DATA_TAG in the toolbarOptions, you should set it to the pertaining product string instead of the bool value true. The available products are keys in the DATA_TAGS constant in the constants.js file. For instance you could set toolbarOptions={{ DAT_TAG: 'risk-messenger' }} and all data tags relevant to risk messenger would be available.

  The size of the toolbar icons can be changed to fit smaller screens with the toolbarSize prop, which accepts "small", "medium", and "large". You can also override the defaultFontSize by providing a numeric value using the prop of the same name, however, the value must be within the FONT_SIZES array. The boolean props borderless, fullWidth, and fullHeight allow you to further customize the style of the editor and do as the names imply.

  Default content can be set with either the defaultContentRaw or defaultContentHTML props. Only one can be provided, defaultContentRaw being the raw content output format provided by Draft.js and defaultContentHTML being html markup that gets interpretted by Draft.js...

  Similarly, you can get the contents of the editor for saving to the DB with the onChangeRaw and onChangeHTML props respectively.
*/

/* eslint-disable default-case */

import React, {
  useState,
  useRef,
  memo,
  useMemo,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  EditorState,
  ContentState,
  Modifier,
  SelectionState,
  convertFromRaw,
  convertToRaw,
  getDefaultKeyBinding,
  AtomicBlockUtils,
  RichUtils,
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from './utils/html-to-draftjs/src/library';
import ExtendedRichUtils, {
  ALIGNMENT_DATA_KEY,
} from './utils/ExtendedRichUtils.js';
import {
  toggleCustomInlineStyle,
  getEntityRange,
  getSelectionEntity,
} from 'draftjs-utils';
import getSearchText from './utils/getSearchText';
import linkifyIt from 'linkify-it';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { colors, Tooltip } from '@mui/material';
import { linkDecorator, tagDecorator, Toolbar } from './components/';
import { findTagEntities } from './components/tagDecorator';
import usePrevious from '../../utils/hooks/usePrevious.js';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import {
  TOOLBAR_DEFAULTS,
  FONT_COLORS,
  FONT_SIZES,
  DEFAULT_FONT_SIZE,
  DATA_TAGS,
} from './constants';

const makeStyleMap = () => {
  let customStyles = {
    UNDERLINE: {
      textDecoration: 'underline',
    },
    STRIKETHROUGH: {
      textDecoration: 'line-through',
    },
  };

  FONT_SIZES.forEach((size) => {
    customStyles[`fontsize-${size}`] = {
      fontSize: `${size}pt`,
    };
  });

  Object.keys(FONT_COLORS).forEach((color) => {
    customStyles[`color-${color}`] = {
      color: FONT_COLORS[color].color,
    };
  });

  //we have to make a dupe formatted slightly differently for the export function
  let exportCustomStyles = {};
  for (const style in customStyles) {
    if (customStyles.hasOwnProperty(style)) {
      exportCustomStyles[style] = {
        style: customStyles[style],
      };
    }
  }

  return {
    customStyles,
    exportCustomStyles,
  };
};

const { customStyles: styleMap, exportCustomStyles: exportStyleMap } =
  makeStyleMap();

const useStyles = makeStyles((theme) => ({
  editorRoot: {
    background: '#fff',
    border: `1px solid ${colors.grey[300]}`,
    '& .DraftEditor-editorContainer': {
      '& .align-right *': {
        textAlign: 'right',
      },
      '& .align-center *': {
        textAlign: 'center',
      },
      '& .align-justify *': {
        textAlign: 'justify',
        width: '100%',
      },
      '& .align-left *': {
        textAlign: 'left',
      },
    },
    '&.disabled': {
      opacity: 0.65,
      pointerEvents: 'none',
    },
    '&.borderless': {
      border: '0 none',
    },
    '&.full-height': {
      alignSelf: 'stretch',
      display: 'flex',
      flexDirection: 'column',
    },
    '&.full-width': {
      width: '100%',
    },
  },
  editorContainer: {
    borderTop: `1px solid ${colors.grey[300]}`,
    cursor: 'text',
    fontSize: `${DEFAULT_FONT_SIZE}pt`,
    padding: '0 1rem 1rem 1rem',
    minHeight: '100px',
    '& .public-DraftEditorPlaceholder-root, & .public-DraftEditor-content': {
      margin: '0 -1rem -1rem',
      padding: '1rem',
    },
    '&.hidePlaceholder .public-DraftEditorPlaceholder-root': {
      display: 'none',
    },
    '& figure': {
      margin: 0,
    },
    '& hr': {
      display: 'block',
      height: '1px',
      border: 0,
      borderTop: '1px solid #CCCCCC',
      margin: '1em 0',
      padding: 0,
    },
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
  dataTagSuggestionEntry: {
    background: 'transparent',
    '&:hover, &:focus': {
      background: colors.grey[100],
    },
  },
}));

const linkify = linkifyIt();
const linkifyLink = (params) => {
  const links = linkify.match(params.target);
  return {
    ...params,
    target: (links && links[0] && links[0].url) || params.target,
  };
};

const RichTextEditor = memo(
  forwardRef(
    (
      {
        onChangeRaw,
        onChangeHTML,
        defaultContentRaw,
        defaultContentHTML,
        placeholder,
        disabled = false,
        toolbarOptions: toolbarOptionsOverride,
        defaultFontSize: defaultFontSizeOverride, //overrides the default font size (must be numeric value from available FONT_SIZES array)
        fullWidth = false, //makes entired editor expand to fill available width
        fullHeight = false, //makes entire editor expand to fill available flex height
        minHeight: minHeightOverride, //overrides the default min height on the editor body (ignored if fullHeight is true)
        borderless = false, //removes the outer border
        toolbarSize: toolbarSizeOverride, //overrides the size of the icons in the toolbar
      },
      ref
    ) => {
      const classes = useStyles();
      const editorEl = useRef(null);
      const [toolbarOptions, setToolbarOptions] = useState({});
      const product = toolbarOptionsOverride?.DATA_TAG?.length
        ? toolbarOptionsOverride.DATA_TAG
        : undefined;

      useEffect(() => {
        setToolbarOptions({
          ...TOOLBAR_DEFAULTS,
          ...(toolbarOptionsOverride || {}),
        });
      }, [toolbarOptionsOverride]);

      const defaultEditorState = () => {
        if (defaultContentRaw) {
          return EditorState.createWithContent(
            convertFromRaw(defaultContentRaw)
          );
        } else if (defaultContentHTML) {
          const headingWithFontSize = (mult) => {
            return (
              '<p><span style="font-size: ' +
              Math.round(
                (defaultFontSizeOverride || DEFAULT_FONT_SIZE) * mult
              ) +
              'pt;"><strong>'
            );
          };

          const { contentBlocks, entityMap } = htmlToDraft(
            defaultContentHTML
              //convert heading tags
              .replace(/<h1>/gi, headingWithFontSize(2))
              .replace(/<h2>/gi, headingWithFontSize(1.5))
              .replace(/<h3>/gi, headingWithFontSize(1.15))
              .replace(/<h4>/gi, headingWithFontSize(1))
              .replace(/<h5>/gi, headingWithFontSize(0.9))
              .replace(/<h6>/gi, headingWithFontSize(0.8))
              .replace(/<\/h\d>/gi, '</strong></span></p>\n')
              //replace underline tags that don't have inline styling
              .replaceAll('<u>', '<u style="text-decoration: underline">')
              //replace linebreaks
              .replaceAll('<br>', '\n'),
            (nodeName, node) => {
              switch (nodeName) {
                case 'hr':
                  return {
                    type: 'HORIZONTAL_RULE',
                    mutability: 'MUTABLE',
                    data: {},
                  };
              }
            }
          );

          let newContentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );

          /* START: FIND DATA TAGS IN CONTENT AND CONVERT TO ENTITIES */
          newContentState.getBlockMap().forEach((block) => {
            findTagEntities(block, (start, end, tag) => {
              const blockKey = block.getKey();
              const blockSelection = SelectionState.createEmpty(blockKey)
                .set('anchorOffset', start)
                .set('focusOffset', end);

              const parts = tag?.replaceAll(/[^\w\-:]/g, '').split(':');
              tag = parts.length === 2 ? parts[1] : parts[0];

              if (DATA_TAGS[product]?.find((_) => _.tag === tag)) {
                //only create an entity if this is a defined data tag and not a code block

                const tagEntity = newContentState.createEntity(
                  'DATA_TAG',
                  'IMMUTABLE',
                  { tag, product }
                );
                const entityKey = tagEntity.getLastCreatedEntityKey();

                newContentState = Modifier.applyEntity(
                  tagEntity,
                  blockSelection,
                  entityKey
                );
              }
            });
          });
          /* END: FIND DATA TAGS IN CONTENT AND CONVERT TO ENTITIES */

          return EditorState.createWithContent(newContentState);
        }

        return EditorState.createEmpty();
      };

      const prevProps = usePrevious({ defaultContentRaw, defaultContentHTML });

      const [editorState, setEditorState] = useState();
      const [editorKey, setEditorKey] = useState(1);
      const contentState = editorState?.getCurrentContent();

      /* START: DATA TAG AUTOCOMPLETE STUFF */
      const [dataTagOpen, setDataTagOpen] = useState(false);
      const [dataTagSuggestions, setDataTagSuggestions] = useState(
        DATA_TAGS[product]
      );

      const { MentionSuggestions: DataTagSuggestions, plugins } =
        useMemo(() => {
          const mentionPlugin = createMentionPlugin({
            entityMutability: 'IMMUTABLE',
            mentionTrigger: ['{{'],
          });
          // eslint-disable-next-line no-shadow
          const { MentionSuggestions } = mentionPlugin;
          // eslint-disable-next-line no-shadow
          const plugins = [mentionPlugin];
          return { plugins, MentionSuggestions };
        }, []);

      const onDataTagOpenChange = useCallback((open) => {
        setDataTagOpen(open);
      }, []);

      useEffect(() => {
        if (editorState === undefined) {
          setEditorState(defaultEditorState());
          refreshEditor();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
      }, [editorState]);

      const refreshEditor = useCallback(() => {
        //increment the editor key to remount the editor
        //we have to do this to reload plugins and decorators whenever the editorstate is reset and not updated

        setEditorKey((prevEditorKey) => {
          return prevEditorKey + 1;
        });
      }, []);

      const onDataTagSearchChange = useCallback(
        ({ value }) => {
          const filteredTags =
            DATA_TAGS[product]?.filter((dataTag) =>
              dataTag?.tag?.toLowerCase()?.includes(value.toLowerCase())
            ) || [];
          setDataTagSuggestions(filteredTags);
        },
        [product]
      );
      /* END: DATA TAG AUTOCOMPLETE STUFF */

      useEffect(() => {
        if (
          (defaultContentRaw &&
            prevProps?.defaultContentRaw &&
            JSON.stringify(defaultContentRaw) !==
              JSON.stringify(prevProps?.defaultContentRaw)) ||
          (defaultContentHTML &&
            prevProps?.defaultContentHTML &&
            defaultContentHTML !== prevProps?.defaultContentHTML)
        ) {
          setEditorState(defaultEditorState()); //default content prop has changed so update state to reflect the changes
          refreshEditor();
        }
        //eslint-disable-next-line
      }, [prevProps, defaultContentRaw, defaultContentHTML]);

      useImperativeHandle(ref, () => ({
        //this exposes these methods to the parent ref

        resetDefault() {
          onChange(
            defaultEditorState(),
            true //force=true in case disabled
          );
          refreshEditor();
        },
      }));

      //handle prop overrides to the editor style
      let editorContainerStyleOverrides = {};
      //eslint-disable-next-line
      {
        if (defaultFontSizeOverride) {
          editorContainerStyleOverrides.fontSize = defaultFontSizeOverride;
        }

        if (fullHeight) {
          editorContainerStyleOverrides.flex = 1;
        } else if (minHeightOverride) {
          editorContainerStyleOverrides.minHeight = minHeightOverride;
        }
      }

      const onChange = useCallback(
        (newEditorState, force = false) => {
          if (disabled && !force) {
            return;
          }

          const newContentState = newEditorState?.getCurrentContent();
          if (newContentState) {
            setEditorState(newEditorState);

            if (typeof onChangeRaw === 'function') {
              const rawData = convertToRaw(newContentState);
              onChangeRaw(rawData);
            }

            if (typeof onChangeHTML === 'function') {
              const htmlData = stateToHTML(newContentState, {
                inlineStyles: exportStyleMap,
                blockStyleFn: handleBlockStyle,
                blockRenderers: {
                  atomic: (block) => {
                    const entityKey = block.getEntityAt(0);
                    const entity = newContentState.getEntity(entityKey);
                    if (entity) {
                      switch (entity.type) {
                        case 'HORIZONTAL_RULE':
                          return '<hr/>';
                      }
                    }
                  },
                },
              });

              onChangeHTML(
                htmlData.replace(/\n/gi, '') //remove any unescaped new line characters we inserted when setting the default content
              );
            }
          }
        },
        [disabled, onChangeRaw, onChangeHTML]
      );

      const onFocus = useCallback(() => {
        editorEl.current.focus();
      }, [editorEl]);

      const handleKeyCommand = (command) => {
        const newState = ExtendedRichUtils.handleKeyCommand(
          editorState,
          command
        );
        if (newState) {
          onChange(newState);
          return true;
        }
        return false;
      };

      const addDataTagToState = useCallback(
        (oldState, tag, autoComplete) => {
          const currentContent = oldState?.getCurrentContent();
          const tagEntity = currentContent.createEntity(
            'DATA_TAG',
            'IMMUTABLE',
            { tag, product }
          );
          const entityKey = tagEntity.getLastCreatedEntityKey();
          const tagText = '{{' + tag + '}}';

          let newContentStateWithTag;
          if (autoComplete) {
            const currentSelectionState = oldState.getSelection();
            const { begin, end } = getSearchText(
              oldState,
              currentSelectionState,
              [autoComplete]
            );

            const mentionTextSelection = currentSelectionState.merge({
              anchorOffset: begin,
              focusOffset: end,
            });

            newContentStateWithTag = Modifier.replaceText(
              currentContent,
              mentionTextSelection,
              tagText,
              undefined,
              entityKey
            );

            //if tag is inserted at the end of the content, add a space after
            const blockKey = mentionTextSelection.getAnchorKey();
            const blockSize = oldState
              .getCurrentContent()
              .getBlockForKey(blockKey)
              .getLength();
            if (blockSize === end) {
              newContentStateWithTag = Modifier.insertText(
                newContentStateWithTag,
                newContentStateWithTag.getSelectionAfter(),
                ' '
              );
            }
          } else {
            newContentStateWithTag = Modifier.insertText(
              currentContent,
              oldState.getSelection(),
              tagText,
              undefined,
              entityKey
            );
          }

          return EditorState.push(
            oldState,
            newContentStateWithTag,
            'insert-characters'
          );
        },
        [product]
      );

      const handleCatchall = useCallback(
        (type, oldState, value) => {
          //if editor is not currently focused, set focus
          let newState = oldState.getSelection().getHasFocus()
            ? oldState
            : EditorState.forceSelection(
                oldState,
                oldState.getSelection().set('hasFocus', true)
              );

          switch (type) {
            case 'format':
              newState = ExtendedRichUtils.toggleInlineStyle(newState, value);
              break;
            case 'fontSize':
              newState = toggleCustomInlineStyle(newState, 'fontSize', value);
              break;
            case 'fontColor':
              newState = toggleCustomInlineStyle(newState, 'color', value);
              break;
            case 'align':
              newState = ExtendedRichUtils.toggleAlignment(newState, value);
              break;
            case 'block':
              newState = ExtendedRichUtils.toggleBlockType(newState, value);
              break;
            case 'undo':
              newState = EditorState.undo(newState);
              break;
            case 'redo':
              newState = EditorState.redo(newState);
              break;
            case 'hr': {
              const hrEntity = newState
                .getCurrentContent()
                .createEntity('HORIZONTAL_RULE', 'IMMUTABLE', {});
              const entityKey = hrEntity.getLastCreatedEntityKey();
              newState = AtomicBlockUtils.insertAtomicBlock(
                newState,
                entityKey,
                ' '
              );
              break;
            }
            case 'link':
              const linkified = linkifyLink({
                title: value.title,
                target: value.target,
                targetOption: value.targetOption,
              });
              newState = addLink(
                newState,
                linkified.title,
                linkified.target,
                linkified.targetOption
              );
              break;
            case 'unlink':
              newState = removeLink(newState);
              break;
            case 'tag':
              newState = addDataTagToState(newState, value);
              break;
            default:
              return; //invalid type, do nothing
          }

          onChange(newState);
        },
        [onChange, addDataTagToState]
      );

      const removeLink = (oldState) => {
        const currentEntity = getSelectionEntity(oldState);
        let selection = oldState.getSelection();
        if (currentEntity) {
          const entityRange = getEntityRange(oldState, currentEntity);
          const isBackward = selection.getIsBackward();
          if (isBackward) {
            selection = selection.merge({
              anchorOffset: entityRange.end,
              focusOffset: entityRange.start,
            });
          } else {
            selection = selection.merge({
              anchorOffset: entityRange.start,
              focusOffset: entityRange.end,
            });
          }
          return RichUtils.toggleLink(oldState, selection, null);
        }

        return null;
      };

      const addLink = (oldState, linkTitle, linkTarget, linkTargetOption) => {
        const currentEntity = getSelectionEntity(oldState);
        let selection = oldState.getSelection();

        if (currentEntity) {
          const entityRange = getEntityRange(oldState, currentEntity);
          const isBackward = selection.getIsBackward();
          if (isBackward) {
            selection = selection.merge({
              anchorOffset: entityRange.end,
              focusOffset: entityRange.start,
            });
          } else {
            selection = selection.merge({
              anchorOffset: entityRange.start,
              focusOffset: entityRange.end,
            });
          }
        }
        const entityKey = oldState
          .getCurrentContent()
          .createEntity('LINK', 'MUTABLE', {
            url: linkTarget,
            targetOption: linkTargetOption,
          })
          .getLastCreatedEntityKey();

        let contentState = Modifier.replaceText(
          oldState.getCurrentContent(),
          selection,
          `${linkTitle}`,
          oldState.getCurrentInlineStyle(),
          entityKey
        );
        let newEditorState = EditorState.push(
          oldState,
          contentState,
          'insert-characters'
        );

        // insert a blank space after link
        if (linkTitle.charAt(linkTitle.length - 1) !== ' ') {
          selection = newEditorState.getSelection().merge({
            anchorOffset: selection.get('anchorOffset') + linkTitle.length,
            focusOffset: selection.get('anchorOffset') + linkTitle.length,
          });
          newEditorState = EditorState.acceptSelection(
            newEditorState,
            selection
          );
          contentState = Modifier.insertText(
            newEditorState.getCurrentContent(),
            selection,
            ' ',
            newEditorState.getCurrentInlineStyle(),
            undefined
          );
        }

        return EditorState.push(
          newEditorState,
          contentState,
          'insert-characters'
        );
      };

      const handleReturn = () => {
        onChange(ExtendedRichUtils.splitBlock(editorState));
        return 'handled';
      };

      const handleKeyBinding = (e) => {
        if (e.key === 'Tab') {
          //multiline tab indent, shift + tab to remove indents, prevent indent if alignment is not left

          const currentState = editorState;
          const isShift = e.shiftKey;

          if (
            currentState &&
            ExtendedRichUtils.getAlignmentType(currentState) === 'LEFT'
          ) {
            const TAB_INDENT = '    ';
            const currentContent = currentState.getCurrentContent();
            const selectionState = currentState.getSelection();
            const start = selectionState.getStartOffset();
            const end = selectionState.getEndOffset();
            const startKey = selectionState.getStartKey();
            const endKey = selectionState.getEndKey();
            const startText = currentContent.getBlockForKey(startKey).getText();

            if (
              startKey !== endKey ||
              (start === 0 && end === startText.length)
            ) {
              //if this is a multiline selection or it is a single line and the entire line is selected

              e.preventDefault();
              const blocks = currentContent.getBlocksAsArray();
              let nextContentWithIndent = currentContent;

              //we can use the start/end spaces modified to keep track of how many spaces were removed from each block respectively
              //later when adjusting the selection, we can account for the new offsets using these numbers... it's awkward but it works
              let startSpacesModified = 0;
              let endSpacesModified = 0;

              let inSelection = false;
              for (const block of blocks) {
                const blockKey = block.getKey();
                const blockText = block.getText();
                if (blockKey === startKey) {
                  inSelection = true;
                }

                if (inSelection) {
                  const blockSelection = SelectionState.createEmpty(
                    blockKey
                  ).set('focusOffset', blockText.length);
                  const newText = isShift
                    ? blockText.replace(/\s{1,4}/, '')
                    : TAB_INDENT + blockText;

                  nextContentWithIndent = Modifier.replaceText(
                    nextContentWithIndent,
                    blockSelection,
                    newText
                  );

                  if (blockKey === startKey) {
                    startSpacesModified = newText.length - blockText.length;
                  } else if (blockKey === endKey) {
                    endSpacesModified = newText.length - blockText.length;
                    break;
                  }
                }
              }

              onChange(
                //we are going to need to forcibly adjust the selection since we added or removed characters
                EditorState.forceSelection(
                  EditorState.push(
                    currentState,
                    nextContentWithIndent,
                    'indent'
                  ),
                  selectionState.merge({
                    anchorKey: startKey,
                    anchorOffset:
                      start === 0
                        ? 0
                        : start +
                          (isShift ? startSpacesModified : TAB_INDENT.length),
                    focusKey: endKey,
                    focusOffset:
                      end + (isShift ? endSpacesModified : TAB_INDENT.length),
                    isBackward: false,
                    hasFocus: true,
                  })
                )
              );
              return 'handled';
            }
          }
        } else if (e.key === 'Backspace') {
          if (!contentState?.hasText()) {
            e.preventDefault();
            if (contentState?.getBlockMap().first().getType() !== 'unstyled') {
              onChange(EditorState.moveFocusToEnd(EditorState.createEmpty())); //if removing an empty styled block (i.e. a ul/ol), create a new empty state
            }

            return 'handled'; //prevent weird content error if backspacing when no content is in the editor
          }
        } else if (e.key === ' ') {
          //automatically insert list block type when triggered with "*" or "1."

          const currentState = editorState;

          if (currentState) {
            const selectionState = currentState.getSelection();
            const anchorKey = selectionState.getAnchorKey();
            const currentContent = currentState.getCurrentContent();
            const currentContentBlock =
              currentContent.getBlockForKey(anchorKey);
            const start = selectionState.getStartOffset();
            const end = selectionState.getEndOffset();
            const text = currentContentBlock.getText();
            const selectedText = text.slice(start, end);
            const depth = currentContentBlock.getDepth();
            const blockType = currentContentBlock.getType();

            if (
              (text === '*' || text === '1.') &&
              depth === 0 &&
              blockType === 'unstyled' &&
              !selectedText.length &&
              text.length === start
            ) {
              e.preventDefault();

              const newContentState = Modifier.replaceText(
                currentContent,
                selectionState.merge({
                  anchorOffset: 0,
                  focusOffset: text.length,
                }),
                ''
              );

              handleCatchall(
                'block',
                EditorState.push(currentState, newContentState),
                `${text === '*' ? 'un' : ''}ordered-list-item`
              );
              return 'handled';
            }
          }
        }

        return getDefaultKeyBinding(e);
      };

      const handleBlockStyle = (block) => {
        const textAlignStyle = block.getData().get(ALIGNMENT_DATA_KEY);

        switch (textAlignStyle) {
          case 'RIGHT':
            return 'align-right';
          case 'CENTER':
            return 'align-center';
          case 'JUSTIFY':
            return 'align-justify';
          case 'LEFT':
          default:
            return 'align-left';
        }
      };

      const handleBlockRender = (block) => {
        if (block.getType() === 'atomic') {
          const entityKey = block.getEntityAt(0);
          if (entityKey) {
            const entity = contentState?.getEntity(entityKey);
            if (entity) {
              switch (entity.getType()) {
                case 'HORIZONTAL_RULE':
                  return {
                    component: () => {
                      return <hr />;
                    },
                    editable: false,
                  };
              }
            }
          }
        }

        return undefined;
      };

      return (
        <div
          className={clsx(
            classes.editorRoot,
            disabled && 'disabled',
            borderless && 'borderless',
            fullWidth && 'full-width',
            fullHeight && 'full-height'
          )}
        >
          <Toolbar
            editorState={editorState}
            handleCatchall={handleCatchall}
            options={toolbarOptions}
            defaultFontSizeOverride={defaultFontSizeOverride}
            toolbarSize={toolbarSizeOverride}
          />
          <div
            className={clsx(
              classes.editorContainer,
              !contentState?.hasText() &&
                contentState?.getBlockMap().first().getType() !== 'unstyled' &&
                'hidePlaceholder'
            )}
            style={editorContainerStyleOverrides}
            onClick={onFocus}
          >
            <Editor
              key={editorKey}
              customStyleMap={styleMap}
              blockStyleFn={handleBlockStyle}
              blockRendererFn={handleBlockRender}
              keyBindingFn={handleKeyBinding}
              handleKeyCommand={handleKeyCommand}
              editorState={editorState || EditorState.createEmpty()}
              handleReturn={handleReturn}
              onChange={onChange}
              placeholder={placeholder}
              ref={editorEl}
              spellCheck={true}
              plugins={plugins}
              decorators={[linkDecorator, tagDecorator]}
            />
            {toolbarOptions?.DATA_TAG && (
              <DataTagSuggestions
                open={dataTagOpen}
                onOpenChange={onDataTagOpenChange}
                suggestions={dataTagSuggestions || []}
                onSearchChange={onDataTagSearchChange}
                entryComponent={(props) => {
                  const {
                    mention: dataTag,
                    theme,
                    searchValue,
                    selectMention,
                    isFocused,
                    ...parentProps
                  } = props;

                  return (
                    <Tooltip
                      title={dataTag?.description || ''}
                      disableInteractive
                    >
                      <div
                        {...parentProps}
                        className={clsx(
                          parentProps.className,
                          classes.dataTagSuggestionEntry
                        )}
                        onMouseUp={(props) => {
                          onDataTagOpenChange(false);
                          onChange(
                            addDataTagToState(
                              editorState,
                              dataTag?.tag,
                              '{{' + parentProps?.searchValue
                            )
                          );
                        }}
                      >
                        <span className={theme?.mentionSuggestionsEntryText}>
                          {dataTag?.tag}
                        </span>
                      </div>
                    </Tooltip>
                  );
                }}
              />
            )}
          </div>
        </div>
      );
    }
  )
);

export default RichTextEditor;
