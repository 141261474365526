import React, { useEffect } from 'react';
import { Prompt } from 'react-router';

export const UNSAVED_CHANGES_CONFIRM_MESSAGE =
  'You have unsaved changes, are you sure you want to leave?';

export const UnsavedChangesPrompt = ({ hasUnsavedChanges }) => {
  useEffect(() => {
    //this gets it's own useEffect since it has to run every time the component rerenders since it will use the state from the time of render, not at the time of the hasUnsavedChanges execution

    window.onbeforeunload = hasUnsavedChangesPrompt;

    return () => {
      window.onbeforeunload = undefined;
    };
  });

  const hasUnsavedChangesPrompt = (e) => {
    if (hasUnsavedChanges) {
      if (e !== undefined) {
        (e || window.event).returnValue = UNSAVED_CHANGES_CONFIRM_MESSAGE;
      }
      return UNSAVED_CHANGES_CONFIRM_MESSAGE;
    } else {
      return;
    }
  };

  return (
    <>
      {!!hasUnsavedChanges && (
        <Prompt when={true} message={UNSAVED_CHANGES_CONFIRM_MESSAGE} />
      )}
    </>
  );
};

export default UnsavedChangesPrompt;
