import React, { memo } from 'react';
import { Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    '& a': {
      color: theme.palette.text.primary,
      opacity: 0.65,
      textDecoration: 'none',
      '&:hover, &:focus-visible': {
        textDecoration: 'underline',
      },
    },
  },
}));

const formatter = (title) => {
  switch (true) {
    case title.toUpperCase() === title:
      return title;
    case title[0].toUpperCase() !== title[0]:
      return title;
    case title.split(' ').filter((section) => section.toUpperCase() === section)
      .length > 0:
      return title;
    default:
      return title.split(/(?=[A-Z])/).join(' ');
  }
};

const Breadcrumbs = memo(({ trail = {} }) => {
  const classes = useStyles();

  const trailKeys = Object.keys(trail);
  const lastTitle = trailKeys?.[trailKeys?.length - 1];
  return (
    <MuiBreadcrumbs
      className={classes.breadcrumbs}
      maxItems={3}
      itemsAfterCollapse={2}
      aria-label='breadcrumb'
    >
      {trailKeys?.map((title) => {
        const url = trail[title];
        const newTitle = formatter(title);
        if (!url || title === lastTitle) {
          return (
            <Typography key={newTitle} color='textPrimary'>
              {newTitle}
            </Typography>
          );
        } else {
          return (
            <Link key={newTitle} to={url}>
              {newTitle}
            </Link>
          );
        }
      })}
    </MuiBreadcrumbs>
  );
});

export default Breadcrumbs;
