import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { Box, Grid, Paper, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useHistory, Link } from 'react-router-dom';
import { loginStyles } from './loginStyles';
import { useAuthContext } from '../../utils/auth/hooks';

const LoginForm = ({ handleInputChange, formData, loading, classes }) => (
  <>
    <h2 className={classes.title}>Login</h2>

    <TextField
      variant='outlined'
      onChange={handleInputChange}
      placeholder='Enter your email address'
      label='Email'
      name='email'
      type='email'
      className={[classes.input, classes.basicInput]}
      InputLabelProps={{
        style: { color: '#0061AD' },
      }}
    />

    <TextField
      variant='outlined'
      onChange={handleInputChange}
      placeholder='Enter your password'
      label='Password'
      name='password'
      type='password'
      className={[classes.input, classes.basicInput]}
      InputLabelProps={{
        style: { color: '#0061AD' },
      }}
    />

    <LoadingButton
      loading={loading}
      color='secondary'
      variant='contained'
      type='submit'
      disabled={!formData.email || !formData.password || loading}
    >
      Log In
    </LoadingButton>

    <Link
      to='/forgot-password'
      style={{
        marginTop: '.5rem',
        textDecoration: 'none',
        color: '#0061AD',
      }}
    >
      Forgot password?
    </Link>
  </>
);

const NewPasswordForm = ({ handleInputChange, formData, loading, classes }) => (
  <>
    <h2 className={classes.title}>Reset Password</h2>
    <p className={classes.description}>Enter your new password below.</p>

    <TextField
      variant='outlined'
      onChange={handleInputChange}
      placeholder='Enter your new password'
      label='New Password'
      name='password'
      type='password'
      className={[classes.input, classes.basicInput]}
      InputLabelProps={{
        style: { color: '#0061AD' },
      }}
    />

    <LoadingButton
      loading={loading}
      color='secondary'
      variant='contained'
      type='submit'
      disabled={!formData.password || loading}
    >
      Reset Password
    </LoadingButton>
  </>
);

export const Login = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [resetPasswordStep, setResetPasswordStep] = useState(false);
  const [cognitoResponse, setCognitoResponse] = useState();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const classes = loginStyles();
  const { setAuthenticated } = useAuthContext();

  const submitClick = async () => {
    setLoading(true);
    if (!resetPasswordStep) {
      try {
        const trimEmail = formData.email.trim();
        const trimPassword = formData.password.trim();
        const user = await Auth.signIn(trimEmail, trimPassword);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setResetPasswordStep(true);
          setFormData({
            password: '',
          });
          setCognitoResponse(user);
          setLoading(false);
          return;
        }
        setAuthenticated(true);
        setFormData({
          email: '',
          password: '',
        });
        setLoading(false);

        history.push('/');
      } catch (error) {
        if (error.code === 'PasswordResetRequiredException') {
          const trimEmail = formData.email.trim();
          await Auth.forgotPassword(trimEmail);

          history.push('/confirm-password-reset', { email: trimEmail });
        }
        setLoading(false);
        toast.error(`Failed to login. ${error.message}`);
      }
    }
    if (resetPasswordStep) {
      try {
        const trimPassword = formData.password.trim();
        await Auth.completeNewPassword(cognitoResponse, trimPassword);
        history.push('/');
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error(`Failed to create new password. ${error.message}`);
      }
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      style={{ minHeight: '100vh' }}
      className={classes.wrapper}
    >
      <Box m='auto' width={'80%'} maxWidth={400}>
        <div className={classes.logo} />

        <Box
          component={Paper}
          width={'100%'}
          bgcolor='white'
          justifyContent='center'
          display='flex'
          elevation={12}
          className={classes.paper}
        >
          {
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault();
                submitClick();
              }}
            >
              {!resetPasswordStep ? (
                <LoginForm
                  handleInputChange={handleInputChange}
                  formData={formData}
                  classes={classes}
                  loading={loading}
                />
              ) : (
                <NewPasswordForm
                  handleInputChange={handleInputChange}
                  formData={formData}
                  classes={classes}
                  loading={loading}
                />
              )}
            </form>
          }
        </Box>
      </Box>
    </Grid>
  );
};
