import { fireEvent } from '@testing-library/react';
import useApiGet from './useApiGet.js';

function useGetOrgByName(orgName) {
  const {
    loading,
    error,
    data: org,
    fire: refreshOrg,
  } = useApiGet(`/organization/${orgName}`, { fireOnMount: true });

  return {
    loading,
    error,
    org,
    refreshOrg,
  };
}

export default useGetOrgByName;
