import React, { useState, useCallback } from 'react';
import Loader from '../../components/Loader';
import UserTableView from '../../components/Table/UserTableView';
import { makeStyles } from '@mui/styles';
import Fuse from 'fuse.js';
import { TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteIcon from '@mui/icons-material/Delete';
import TableViewerExportDialog from './components/TableViewerExportDialog';
import TableViewerRestoreDialog from './components/TableViewerRestoreDialog';
import SendIcon from '@mui/icons-material/Send';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    background: '',
    padding: theme.spacing(1),
    borderRadius: '4px',
  },
  searchWrapper: {
    display: 'flex',
    flex: 1,
    marginRight: '1rem',
    '& > div:only-child': {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    '& > div:first-of-type': {
      width: '50%',
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        width: '41.66%',
      },
    },
    '& > div:last-of-type': {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '33.33%',
      },
    },
  },
  description: {
    paddingLeft: '44px',
    paddingTop: '33px',
  },
}));

function TableViewer({ detailsRequest }) {
  const { isLoading: loading, data } = detailsRequest;
  const [response, setResponse] = useState(data != null ? data : {});
  const [redirectLoading, setRedirectLoading] = useState(false);
  const classes = useStyles();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false); //ST2-1778
  const [disableRestoreBtn, setDisableRestoreBtn] = useState(false); //ST2-1778
  const [disableExportBtn] = useState(
    data.tableData != null && data.tableData.length > 0 ? false : true
  ); //HD-13
  const [totalRowsCount] = useState(
    data.tableDataCount != null && data.tableDataCount.length > 0
      ? data.tableDataCount[0]['']
      : 0
  );
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(
    data.tableData != null && data.tableData.length > 0
      ? data.tableData.length
      : 0
  );
  if (loading || data.tableData == null) return <Loader />;

  // ST2-1778 Start

  function handleRestoreClick() {
    setOpenRestoreDialog(true);
  }

  function closeRestoreDialog() {
    setOpenRestoreDialog(false);
  }

  function handleRestoreDisableBtn(val) {
    setDisableRestoreBtn(val);
  }
  //ST2-1778 Ends

  const handleFilters = () => {
    console.log('handleFilters called');
  };
  const handleExport = () => {
    setOpenExportDialog(true);
  };

  function getMoreRecords() {
    var tr = parseInt(totalRowsCount, 10);
    var t = parseInt(to, 10);
    var nextCount = t + 10000;
    if (tr > t) {
      setFrom(t);
      if (nextCount > tr) {
        setTo(tr);
      } else {
        setTo(nextCount);
      }
      getConfigData();
    }
  }

  const getConfigData = useCallback(async () => {
    if (loading) {
      return;
    }
    setRedirectLoading(true);
    let headers = {};
    try {
      const { idToken } = await Auth.currentSession();
      headers = {
        Authorization: 'Bearer ' + idToken.jwtToken,
      };
    } catch (e) {
      console.log(e);
      setRedirectLoading(false);
      toast.error(
        'Your session has expired. Please sign in again to continue.'
      );
    }

    // TODO: this should be switched to useApiRequest hook
    Axios.get(
      `${process.env.REACT_APP_INVOKE_URL}/workflow-tables/${response.accnt}/${response.id}/${to}/10000`,
      { headers: headers }
    )
      .then((res) => {
        setResponse(res.data);
        onFilter();
        setRedirectLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setRedirectLoading(false);
        toast.error('An error occurred.');
      });
  }, [from, to]);

  const handleCloseExportDialog = (
    performExport,
    noHeader,
    exportFormat,
    exportDataSet
  ) => {
    setOpenExportDialog(false);
  };

  /* const handleBackup = () => {
    console.log('handleBackup called');
  }; */
  const handleDelete = () => {
    console.log('handleDelete called');
  };

  const onFilter = (event) => {
    if (
      response == null ||
      (response.fail != null && response.fail === true) ||
      response.tableData == null
    ) {
      return [];
    }
    if (searchInputValue.length) {
      const options = {
        threshold: 0.4,
        keys: ['key_value', 'data1', 'data2'],
      };
      const fuse = new Fuse(response.tableData, options);
      const filtered = fuse.search(searchInputValue).map((res) => ({
        ...res.item,
        matches: res.matches,
      }));
      return filtered;
    }

    return response.tableData;
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.searchWrapper}>
          <TextField
            label='Search Table'
            variant='outlined'
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
            onKeyDown={onFilter}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.description}>
            Shows {from} - {to} records out of {totalRowsCount}
          </div>
          <Button
            disabled={redirectLoading}
            variant='contained'
            onClick={getMoreRecords}
            endIcon={<SendIcon />}
          >
            Get More Records
          </Button>
        </div>
      </div>
      {redirectLoading ? (
        <Loader />
      ) : (
        <UserTableView
          resp={response}
          columns={[{ Header: 'ID' }]}
          data={onFilter() || []}
        ></UserTableView>
      )}
      <Button
        variant='outlined'
        onClick={handleFilters}
        className={classes.button}
        startIcon={<FilterAltIcon />}
        disabled
      >
        Filters
      </Button>
      <Button
        variant='outlined'
        onClick={handleExport}
        className={classes.button}
        startIcon={<CloudDownloadIcon />}
        disabled={disableExportBtn} //HD-13
      >
        Export
      </Button>
      <Button
        variant='outlined'
        className={classes.button}
        startIcon={<RestoreIcon />}
        onClick={() => handleRestoreClick()} //ST2-1778
        disabled={disableRestoreBtn} //ST2-1778
      >
        Backups
      </Button>
      <Button
        variant='outlined'
        onClick={handleDelete}
        className={classes.button}
        startIcon={<DeleteIcon />}
        disabled
      >
        Delete
      </Button>
      <TableViewerExportDialog
        openDialog={openExportDialog}
        handleCloseDialog={handleCloseExportDialog}
        data={response || []}
      ></TableViewerExportDialog>
      {/* ST2-1778 Start */}
      <TableViewerRestoreDialog
        openRestoreDialog={openRestoreDialog}
        closeRestoreDialog={closeRestoreDialog}
        handleRestoreDisableBtn={handleRestoreDisableBtn}
      ></TableViewerRestoreDialog>
      {/* ST2-1778 Ends */}
    </div>
  );
}

export default TableViewer;
