// User role -> Table type -> ?Special settings -> Permissions.
const permissions = {
  CIS_INTERNAL: {
    locked_definition: {
      customer: {
        overview: 'view',
        schema: 'view',
        upload: 'edit',
        table_viewer: 'edit',
        notifications: 'edit',
      },
      library: {
        overview: 'edit',
        schema: 'edit',
      },
    },
    locked_key: {
      customer: {
        overview: 'view',
        schema: 'view',
        upload: 'edit',
        table_viewer: 'edit',
        notifications: 'edit',
      },
      library: {
        overview: 'edit',
        schema: 'edit',
      },
    },
    local: {
      customer: {
        overview: 'edit',
        schema: 'edit',
        upload: 'edit',
        table_viewer: 'edit',
        notifications: 'edit',
      },
      library: {
        overview: 'edit',
        schema: 'edit',
        upload: 'edit',
        table_viewer: 'edit',
        notifications: 'edit',
      },
    },
    restricted: {
      customer: {
        overview: 'view',
        schema: 'view',
        upload: 'edit',
        table_viewer: 'edit',
        notifications: 'edit',
      },
      library: {
        overview: 'edit',
        schema: 'edit',
      },
    },
  },
  ADMIN: {
    locked_definition: {
      overview: 'view',
      schema: 'view',
      upload: 'edit',
      table_viewer: 'edit',
      notifications: 'edit',
    },
    locked_key: {
      overview: 'view',
      schema: 'view',
      upload: 'edit',
      table_viewer: 'edit',
      notifications: 'edit',
    },
    local: {
      overview: 'edit',
      schema: 'edit',
      upload: 'edit',
      table_viewer: 'edit',
      notifications: 'edit',
    },
    restricted: {
      overview: 'view',
      schema: 'view',
    },
  },
  // 'end user': // table permissions matter{ locked_definition: [], local: [] },
};

export default function getPermissions(user, table, acct = null) {
  var tablePermissions = permissions[user.role];
  if (!tablePermissions) {
    throw `Improper user role "${user.role}" supplied!`;
  }
  var tablePermissions = tablePermissions[table.libraryType];
  if (!tablePermissions) {
    throw `Improper library type "${table.libraryType}" supplied!`;
  }

  // Special case for internal users to determine permissions based on account type.
  if (user.role === 'CIS_INTERNAL') {
    if (!acct) {
      throw 'getPermissions(Internal user) requires account parameter!';
    }

    let acctType =
      table.cis_account_id === parseInt(acct) ? 'library' : 'customer';
    tablePermissions = tablePermissions[acctType];
  }

  return tablePermissions;
}

// sserrano36 is example of organization admin.
// marmstrong is example of internal user.
// "" is example of end user.
// Should also work for impersonated user.
// Write this as a helper function so we can access in index and details (they use routing, not parent/child, so we can't pass as props)

// Need example of a restricted + locked key library table.
// Have we started caring about end users yet? If so, example of end user account.

// We determine:
// Whether someone can upload in index
// Whether someone can upload or view table in details <- literally doesn't follow the chart for notifications.
// Whether someone can edit overview/schema in overview/schema.

// Special UI:
// Upload allowed/not allowed icon in index.
// Tooltip over tab in upload/view/notifications table in details for internal users.
// Overview and notifications are view-only for internal-library-customer
// Whats the story on smViewport in details.js?
// Notifications are not available to internal-library-library.

// Fix the backend to be consistent in how it names keys.

// Big warning searching in users table
// Refreshing page removes impersonated user.
