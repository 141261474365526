import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

export const ChangePassword = () => {
  const useStyles = makeStyles((theme) => ({
    wrapper: {
      backgroundColor: '#0061AD',
      background: `url("${process.env.REACT_APP_STATIC_ASSETS_URL}cis-hub-bg.jpg")`,
      backgroundPosition: 'bottom center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    form: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
    },
    paper: {
      borderRadius: '8px',
    },
    logo: {
      background: `url("${process.env.REACT_APP_STATIC_ASSETS_URL}cis-logo-color.png")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      width: '100%',
      height: '55px',
    },
    button: {
      color: '#ff9900',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    signInLoading: {
      visibility: 'hidden',
    },
    signInLoaded: {
      visibility: 'visible',
    },
    textField: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor:
          matches && formData.newPassword && formData.newRepeat
            ? 'green'
            : formData.newPassword && formData.newRepeat
            ? 'red'
            : '#0061AD',
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor:
          matches && formData.newPassword && formData.newRepeat
            ? 'green'
            : formData.newPassword && formData.newRepeat
            ? 'red'
            : '#0061AD',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor:
          matches && formData.newPassword && formData.newRepeat
            ? 'green'
            : formData.newPassword && formData.newRepeat
            ? 'red'
            : '#0061AD',
      },
    },
    basicTextField: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.cisBlue,
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.cisBlue,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.cisBlue,
      },
    },
  }));

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    newRepeat: '',
  });
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [matches, setMatches] = useState(false);
  const classes = useStyles();
  const [filledIn, setFilledIn] = useState(false);

  useEffect(() => {
    const both = formData.newPassword && formData.newRepeat;
    both && formData.newPassword === formData.newRepeat
      ? setMatches(true)
      : setMatches(false);
    both ? setFilledIn(true) : setFilledIn(false);
  }, [formData]);

  const handleShowPassword = (passwordChoice, passwordSetter) =>
    passwordSetter(!passwordChoice);

  const submitForm = async () => {
    setLoading(true);
    try {
      const trimOld = formData.oldPassword.trim();
      const trimNew = formData.newPassword.trim();
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, trimOld, trimNew);
      setFormData({
        oldPassword: '',
        newPassword: '',
        newRepeat: '',
      });
      setLoading(false);
      history.push('/');
      toast.success('Password change successful.');
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(`Failed to change password. ${error.message}`);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      style={{ minHeight: '100vh' }}
      className={classes.wrapper}
    >
      <Box
        component={Paper}
        m='auto'
        width={400}
        height={350}
        bgcolor='white'
        justifyContent='center'
        display='flex'
        elevation={12}
        className={classes.paper}
      >
        {
          <form
            className={classes.form}
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <div className={classes.logo} />

            <TextField
              variant='outlined'
              onChange={handleInputChange}
              placeholder='Enter your old password'
              label='Old Password'
              name='oldPassword'
              type={showPasswordOld ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() =>
                        handleShowPassword(showPasswordOld, setShowPasswordOld)
                      }
                      onMouseDown={() =>
                        handleShowPassword(showPasswordOld, setShowPasswordOld)
                      }
                    >
                      {showPasswordOld ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: '#0061AD' },
              }}
            />
            <TextField
              variant='outlined'
              onChange={handleInputChange}
              placeholder='Enter your new password'
              label='New Password'
              name='newPassword'
              className={classes.textField}
              error={!matches && filledIn}
              helperText={
                !matches && filledIn && 'New password fields must match'
              }
              type={showPasswordNew ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() =>
                        handleShowPassword(showPasswordNew, setShowPasswordNew)
                      }
                      onMouseDown={() =>
                        handleShowPassword(showPasswordNew, setShowPasswordNew)
                      }
                    >
                      {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: '#0061AD' },
              }}
            />
            <TextField
              variant='outlined'
              onChange={handleInputChange}
              placeholder='Re-enter your new password'
              label='Repeat New Password'
              name='newRepeat'
              className={classes.textField}
              error={!matches && filledIn}
              helperText={
                !matches && filledIn && 'New password fields must match'
              }
              type={showPasswordRepeat ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() =>
                        handleShowPassword(
                          showPasswordRepeat,
                          setShowPasswordRepeat
                        )
                      }
                      onMouseDown={() =>
                        handleShowPassword(
                          showPasswordRepeat,
                          setShowPasswordRepeat
                        )
                      }
                    >
                      {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: '#0061AD' },
              }}
            />

            <Button
              color='secondary'
              variant='contained'
              type='submit'
              disabled={!formData.oldPassword || !matches || loading}
            >
              <span
                className={
                  loading ? classes.signInLoading : classes.signInLoaded
                }
              >
                Submit
              </span>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </form>
        }
      </Box>
    </Grid>
  );
};
