import React, { useState, useRef } from 'react';
import {
  Autocomplete,
  TextField,
  Chip,
  Typography,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Fuse from 'fuse.js';
import axios from 'axios';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import IBankAgencySelect from './IBankAgencySelect';
import { CIS_INTERNAL } from '../utils/auth/constants';
import { useAuthContext } from '../utils/auth/hooks';

const useStyles = makeStyles((theme) => ({
  tag: { height: '100%', padding: '.5rem' },

  email: { color: '#504949' },
}));

const iBankUserSortFn = (a, b) => {
  const agencyA = (a?.item?.[0]?.v || a?.agency)?.toLowerCase();
  const agencyB = (b?.item?.[0]?.v || b?.agency)?.toLowerCase();
  const firstNameA = a?.item?.[3]?.v || a?.firstName;
  const firstNameB = b?.item?.[3]?.v || b?.firstName;

  if (agencyA === agencyB) {
    return firstNameA > firstNameB ? 1 : -1;
  } else {
    return agencyA > agencyB ? 1 : -1;
  }
};

export default function IBankUserSelect({
  multiple,
  limitTags,
  name,
  value,
  onChange,
  style,
  className,
}) {
  const classes = useStyles();
  const { roleIsAtLeast } = useAuthContext();
  const queryInputRef = useRef(null);
  const [iBankUserList, setIBankUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [axiosSource, setAxiosSource] = useState(null);
  const [agencies, setAgencies] = useState([]);

  const iBankUserFilter = new Fuse(iBankUserList, {
    threshold: 0.35,
    keys: ['agency', 'id', 'email', 'firstName', 'lastName'],
    shouldSort: true,
    sortFn: iBankUserSortFn,
  });

  const handleQueryChangeDebounce = AwesomeDebouncePromise(async (query) => {
    if (axiosSource) {
      axiosSource.cancel('cancel');
    }

    if (!query) {
      setIBankUserList([]);
      setLoading(false);
      return;
    }

    const newAxiosSource = axios.CancelToken.source();
    setAxiosSource(newAxiosSource);
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_INVOKE_URL}/ibank/users`;
      const res = await axios.get(url, {
        cancelToken: newAxiosSource?.token,
        params: {
          query,
          agencies:
            agencies.length > 0
              ? agencies.map((a) => encodeURIComponent(a)).join(',')
              : undefined,
        },
      });

      const data = JSON.parse(res.request.response);
      setIBankUserList(data);
      setLoading(false);
    } catch (e) {
      if (e?.message !== 'cancel') {
        setIBankUserList([]);
        setLoading(false);
      }
    }
  }, 250);

  const handleQueryChange = (e) => {
    handleQueryChangeDebounce(e?.target?.value);
  };

  return (
    <>
      {roleIsAtLeast(CIS_INTERNAL) && (
        <IBankAgencySelect
          multiple={true}
          value={agencies}
          onChange={(e, value) => setAgencies(value)}
        />
      )}

      <Autocomplete
        multiple={!!multiple}
        limitTags={isNaN(limitTags) ? 2 : limitTags}
        name={name}
        options={iBankUserList || []}
        filterOptions={(options, state) =>
          state.inputValue
            ? iBankUserFilter.search(state.inputValue).map((res) => res.item)
            : options.sort(iBankUserSortFn)
        }
        getOptionLabel={(option) =>
          `${option?.agency} - ${option?.id}${
            option?.disabled ? ':DISABLED' : ''
          }`
        }
        renderOption={(props, option) => {
          return (
            <div
              {...props}
              key={props?.key + option?.agency}
              style={{ padding: '.5rem1rem', display: 'block' }}
            >
              {option?.agency} - {option?.id}
              {option?.email ? (
                <>
                  <br />
                  <span className={classes.email}>{option?.email}</span>
                </>
              ) : null}
              {option?.disabled ? (
                <>
                  <br />
                  <span className={classes.email}>Disabled</span>
                </>
              ) : (
                <>
                  <br />
                  <span className={classes.email}>Enabled</span>
                </>
              )}
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            style={{ margin: '18px 15px 6px 0px' }}
            {...params}
            variant='outlined'
            label='iBank Users'
            placeholder='Search available iBank users'
            disabled={roleIsAtLeast(CIS_INTERNAL) && !agencies}
            onChange={handleQueryChange}
            onBlur={() => {
              if (axiosSource) {
                axiosSource.cancel('cancel');
              }

              setLoading(false);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            inputRef={queryInputRef}
          />
        )}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <Chip
              style={{ height: '100%' }}
              label={
                <Typography style={{ whitespace: 'normal' }}>
                  <span className={classes.email}>
                    {' '}
                    {option?.agency} - {option?.id}
                  </span>
                  <br />
                  <span className={classes.email}>{option?.email}</span>
                  <br />
                  <span className={classes.email}>
                    {option?.disabled ? 'Disabled' : 'Enabled'}
                  </span>
                </Typography>
              }
              {...getTagProps({ index })}
              className={getTagProps({ index })?.className + '' + classes.tag}
            />
          ));
        }}
        value={value}
        onChange={onChange}
        groupBy={(option) => option?.agency}
        filterSelectedOptions={true}
        noOptionsText={
          queryInputRef?.current?.value
            ? 'No users match your search'
            : 'Type to search users'
        }
        loading={iBankUserList?.length ? false : loading}
        className={className}
        style={style}
      />
    </>
  );
}
