import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { Divider, Tooltip, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ToggleAccordion from '../../../components/formik/ToggleAccordion';
import IBankUserSelect from '../../../components/IBankUserSelect';
import HelpIcon from '@mui/icons-material/Help';
import SimpleSaveActionBar from '../../../components/SimpleSaveActionBar';
import { useApiPut } from '../../../utils/hooks';
import { toast } from 'react-toastify';
import { CIS_INTERNAL } from '../../../utils/auth/constants';
import { useAuthContext } from '../../../utils/auth/hooks';
import UnsavedChangesPrompt from '../../../components/UnsavedChangesPrompt';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '26px',
    fontWeight: '300',
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: '-.5rem',
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  helpIcon: {
    fontSize: '1.25rem',
    marginLeft: '.5rem',
    marginTop: '.25rem',
    color: theme.palette.secondary.main,
  },
}));

export default function Products({ user, refreshUser, isMe }) {
  const classes = useStyles();
  const history = useHistory();
  const { hasProduct } = useAuthContext();

  const { fire: updateUser, request } = useApiPut(
    `/users/${encodeURIComponent(user?.email)}`
  );

  useEffect(() => {
    if (request.isSuccess) {
      toast.success('User products successfully updated.');
    }
  }, [request.isSuccess]);

  async function handleProductSubmit(values, actions) {
    await updateUser({
      ...user,
      config: {
        ...user?.config,
        iBank: values?.iBank,
        riskMessenger: values?.riskMessenger,
      },
    });
    await refreshUser();
    actions.setSubmitting(false);
  }

  const shouldBeDisabled = (product) => {
    if (isMe) {
      return 'You cannot modify your own access';
    } else if (user?.role === CIS_INTERNAL) {
      return "You cannot modify a Cornerstone Internal user's access";
    } else if (!hasProduct(product)) {
      return 'You do not have access to modify this product';
    }

    return false;
  };

  const shouldBeExpandable = (product) => {
    return user?.role !== CIS_INTERNAL && hasProduct(product);
  };

  return (
    <Formik
      initialValues={{
        iBank: {
          active: false,
          users: [],
          ...user?.config?.iBank,
        },
        riskMessenger: {
          active: false,
          ...user?.config?.riskMessenger,
        },
      }}
      onSubmit={handleProductSubmit}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        dirty,
        resetForm,
      }) => (
        <>
          <UnsavedChangesPrompt hasUnsavedChanges={dirty} />

          <Form>
            <p className={classes.subtitle}>
              {isMe ? 'View your' : "Modify the user's"} ability to access
              different Cornerstone products.
            </p>

            <ToggleAccordion
              name='iBank.active'
              value={user?.role === CIS_INTERNAL ? true : values?.iBank?.active}
              setFieldValue={setFieldValue}
              label='iBank'
              disabled={shouldBeDisabled('iBank')}
              expandable={shouldBeExpandable('iBank')}
            >
              {!values?.iBank?.users?.length && (
                <>
                  <Alert severity='warning'>
                    This user has access to iBank but does not have any iBank
                    users configured to allow them to use single sign-on.
                  </Alert>
                  <br />
                </>
              )}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '1.2rem' }}>User Access</span>
                <Tooltip
                  title='Define which iBank user(s) this user should have login access to.'
                  placement='right-end'
                >
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
              </div>
              <IBankUserSelect
                multiple={true}
                name='iBank.users'
                value={values?.iBank?.users || []}
                onChange={(e, value) => {
                  setFieldValue('iBank.users', value);
                }}
                style={{ marginTop: '1rem', maxWidth: '850px' }}
              />
            </ToggleAccordion>

            <br />

            <ToggleAccordion
              name='riskMessenger.active'
              value={
                user?.role === CIS_INTERNAL
                  ? true
                  : values?.riskMessenger?.active
              }
              setFieldValue={setFieldValue}
              label='Risk Messenger'
              disabled={shouldBeDisabled('riskMessenger')}
              expandable={shouldBeExpandable('riskMessenger')}
            />

            <Divider className={classes.divider} />

            <SimpleSaveActionBar
              onCancel={() => {
                resetForm();
                setTimeout(() => {
                  history.push('/users'); //hacky way to wait for resetForm to finish so the unsaved prompt doesn't show
                }, 50);
              }}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              hasUnsavedChanges={dirty}
            />
          </Form>
        </>
      )}
    </Formik>
  );
}
