import React from 'react';
import { Divider, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../../components/PageTitle';
import { Form, Formik } from 'formik';
import IBankAgencySelect from '../../../components/IBankAgencySelect';
import IQCXProductSelect from '../../../components/iQCXProductSelect';
import NameAndDescList from '../../../components/formik/NameAndDescList';
import { toast } from 'react-toastify';
import { useApiPut } from '../../../utils/hooks';
import ToggleAccordion from '../../../components/formik/ToggleAccordion';
import HelpIcon from '@mui/icons-material/Help';
import SimpleSaveActionBar from '../../../components/SimpleSaveActionBar';
import UnsavedChangesPrompt from '../../../components/UnsavedChangesPrompt';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '26px',
    fontWeight: '300',
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: '-.5rem',
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  helpIcon: {
    fontSize: '1.25rem',
    marginLeft: '.5rem',
    marginTop: '.25rem',
    color: theme.palette.secondary.main,
  },
}));

export const Products = (props) => {
  const { org, refreshOrg } = props;

  const classes = useStyles();
  const history = useHistory();

  const { fire: updateOrg } = useApiPut('/organizations');

  async function handleProductSubmit(values, actions) {
    await updateOrg({
      ...org,
      config: {
        ...org?.config,
        iBank: values?.iBank,
        riskMessenger: values?.riskMessenger,
        iQCX: values?.iQCX,
      },
    });
    await refreshOrg();
    toast.success('Organization products successfully updated.');
    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{
        iBank: {
          active: false,
          agencies: [],
          ...org?.config?.iBank,
        },
        iQCX: {
          active: false,
          accts: [],
          ...org?.config?.iQCX,
        },
        riskMessenger: {
          active: false,
          iQCXAccts: [],
          ...org?.config?.riskMessenger,
        },
      }}
      onSubmit={handleProductSubmit}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        dirty,
        resetForm,
      }) => (
        <>
          <UnsavedChangesPrompt hasUnsavedChanges={dirty} />

          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <PageTitle
              title=''
              subtitle="Modify the organization's product access and settings."
            />

            <Divider className={classes.divider} />

            <ToggleAccordion
              name='iBank.active'
              value={values?.iBank?.active}
              setFieldValue={setFieldValue}
              label='iBank'
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '1.2rem' }}>Agency Access</span>
                <Tooltip
                  title='Define which iBank agencies this organization should have access to.'
                  placement='right-end'
                >
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
              </div>
              <IBankAgencySelect
                multiple={true}
                name='iBank.agencies'
                value={
                  values?.iBank?.agencies
                    ?.map((_) => (typeof _ === 'object' ? _?.name : _))
                    ?.filter((_) => !!_) || []
                } /* TODO: this weird map/filter logic is to handle pre-existing agency objects in dynamo and can be removed in the future once all agencies in the org config are simple arrays */
                onChange={(e, value) => {
                  setFieldValue('iBank.agencies', value);
                }}
                style={{ marginTop: '1rem', maxWidth: '850px' }}
              />
            </ToggleAccordion>

            <br />

            <ToggleAccordion
              name='iQCX.active'
              value={values?.iQCX?.active}
              setFieldValue={setFieldValue}
              label='iQCX'
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '1.2rem' }}>Account Access</span>
                <Tooltip
                  title='Define which iQCX accounts this organization should have access to.'
                  placement='right-end'
                >
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
              </div>
              <IQCXProductSelect
                multiple={true}
                name='iQCX.accts'
                value={
                  values?.iQCX?.accts
                    ?.map((_) => (typeof _ === 'object' ? _ : _))
                    ?.filter((_) => !!_) || []
                }
                changeAccts={(value) => {
                  setFieldValue('iQCX.accts', value);
                }}
                style={{ marginTop: '1rem', maxWidth: '850px' }}
              />
            </ToggleAccordion>

            <br />

            <ToggleAccordion
              name='riskMessenger.active'
              value={values?.riskMessenger?.active}
              setFieldValue={setFieldValue}
              label='Risk Messenger'
            >
              <NameAndDescList
                name='riskMessenger.iQCXAccts'
                value={values?.riskMessenger?.iQCXAccts || []}
                label='Risk Messenger iQCX Account IDs'
                nameLabel='iQCX Account ID'
              />
            </ToggleAccordion>

            <br />

            <SimpleSaveActionBar
              onCancel={() => {
                resetForm();
                setTimeout(() => {
                  history.push('/organizations'); //hacky way to wait for resetForm to finish so the unsaved prompt doesn't show
                }, 50);
              }}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              hasUnsavedChanges={dirty}
            />
          </Form>
        </>
      )}
    </Formik>
  );
};
