"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = _assertThisInitialized;
function _assertThisInitialized(self) {
    if (self === void 0) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }
    return self;
}
