import { FormControlLabel } from '@mui/material';
import { Field } from 'formik';
import { Checkbox as fmuiCheckbox, Switch } from 'formik-mui';
import React from 'react';

export default function Checkbox(props) {
  const { isSwitch, label, name, ...rest } = props;

  return (
    <FormControlLabel
      label={label}
      control={
        <Field
          component={isSwitch ? Switch : fmuiCheckbox}
          type='checkbox'
          name={name}
          {...rest}
        />
      }
    />
  );
}
