import React from 'react';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import Accordion from '../Accordion';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  accordionTitle: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  disabled: {
    opacity: 0.65,
    '& *': {
      pointerEvents: 'none',
    },
  },
}));

function ToggleAccordion({
  name,
  value,
  setFieldValue,
  label,
  disabled,
  children,
  expandable = true,
}) {
  const classes = useStyles();

  return (
    <Tooltip title={disabled || ''}>
      <div>
        <Accordion
          expanded={expandable && !!value}
          hideExpandIcon
          Header={
            <div
              style={{ width: '100%' }}
              onClick={() => {
                if (disabled) {
                  return;
                }

                setFieldValue(name, !value);
              }}
            >
              <FormControlLabel
                aria-label={label}
                checked={!!value}
                control={<Switch />}
                label={label}
                className={classes.accordionTitle}
              />
            </div>
          }
          className={disabled ? classes.disabled : ''}
        >
          {children}
        </Accordion>
      </div>
    </Tooltip>
  );
}

export default ToggleAccordion;
