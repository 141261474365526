import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import {
  CircularProgress,
  FormControl,
  Checkbox,
  Autocomplete,
  colors,
} from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TextField } from 'formik-mui';
import FormDialog from '../../../components/FormDialog';
import { useApiGet } from '../../../utils/hooks';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { usePrevious } from '../../../utils/hooks/';
import { DATA_TAGS } from '../../../components/RichTextEditor/constants.js';
import { TAG_REGEX } from '../../../components/RichTextEditor/components/tagDecorator.js';
import { fireEvent } from '@testing-library/react';

const sendTestSchema = yup.object().shape({
  to: yup.array().of(yup.string().email()).required('To is a required field'),
  cc: yup.array().of(yup.string().email()),
  bcc: yup.array().of(yup.string().email()),
  subject: yup.string().required('Subject is a required field'),
  templateData: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  label: {
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectAdornment: {
    marginRight: theme.spacing(3),
  },
}));

const validateEmail = (email) => {
  //this function doesn't actually "validate" if an email is real or working, only checks if the string provided vaguely matches the [string]@[string].[string] format
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const RenderEmailFieldOption = (props, option, { selected }) => (
  <li {...props}>
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
      checkedIcon={<CheckBoxIcon fontSize='small' />}
      style={{ marginRight: 8 }}
      checked={selected}
    />
    {option}
  </li>
);

const FormikAutocomplete = ({
  name,
  label,
  options,
  values,
  loading,
  setFieldValue,
  setFieldTouched,
  validateField,
  errors,
  touched,
  disabled,
}) => {
  //this component is a bit specific to this form but could be generalized and put in components at some point...
  const classes = useStyles();

  return (
    <Autocomplete
      name={name}
      fullWidth
      multiple
      freeSolo
      value={values[name]}
      options={options}
      renderOption={RenderEmailFieldOption}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={label}
          variant='outlined'
          error={!!(errors[name] && touched[name])}
          helperText={touched[name] ? errors[name] : ''}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(e, value) => {
        setFieldValue(
          name,
          value.filter((_) => validateEmail(_))
        );
      }}
      onBlur={() => {
        setFieldTouched(name);
        validateField(name);
      }}
      className={classes.formControl}
      disabled={disabled}
    />
  );
};

const SendTest = ({ open, setOpen, product, templateHtml }) => {
  const classes = useStyles();
  const {
    isLoading: loadingUserEmails,
    data,
    error: usersError,
  } = useApiGet('/users', { fireOnMount: true });
  const [intialTemplateData, setIntialTemplateData] = useState('');
  const [userEmails, setUserEmails] = useState([]);
  const prevProps = usePrevious({ open });

  const { loading, clearData, makeRequest, requestCompleted } = {
    url: '/messages/test',
    method: 'post',
  };

  useEffect(() => {
    let filterFunction = (data) => data?.map((_) => _.email);
    setUserEmails(filterFunction(data));
  }, [data]);

  useEffect(() => {
    if (requestCompleted) {
      clearData();
      setOpen(false);
      toast.success('An email was successfully sent.');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCompleted, clearData]);

  useEffect(() => {
    if (usersError) {
      toast.error(
        typeof usersError === 'string'
          ? usersError
          : 'An unexpected error occurred.'
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersError]);

  useEffect(() => {
    if (open && !prevProps.open) {
      //just opened so reset the intialTemplateData (which could be slowish if the template is large)

      let newData = [],
        tags = [];
      if (templateHtml.length) {
        const matches = [...templateHtml.matchAll(TAG_REGEX)]; //regular expression to get all of the data tags in the template
        matches.forEach((match) => {
          const matchParts = [...match[0].matchAll(/([\w-]+)/g)]; //split the tag into parts in case it was custom code (ex: {{#each this.questions}})
          matchParts.forEach((tag) => {
            tag = tag[0];
            if (!tags.includes(tag)) {
              tags.push(tag); //so we only do each tag once
              const defaultVal = DATA_TAGS?.[product]?.find(
                (_) => _.tag === tag
              )?.default;
              if (defaultVal !== undefined) {
                try {
                  //try/catch in case the JSON.stringify fails
                  const value = (
                    typeof defaultVal === 'string'
                      ? defaultVal
                      : JSON.stringify(defaultVal)
                  ).replace(/"/g, '\\"'); //escape quotes in the value string
                  newData.push(`  "${tag}": "${value}"`); //looks weird but it sets each tag up to be joined later into a json looking string
                } catch (e) {}
              }
            }
          });
        });
      }
      setIntialTemplateData(
        newData.length ? '{\r\n' + newData.join(',\r\n') + '\r\n}' : ''
      ); //if newData has any tag values then join them together into a json string, otherwise blank
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      title='Send Test Email'
      saveButtonText='Send'
      maxWidth='sm'
      formikProps={{
        initialValues: {
          to: [],
          cc: [],
          bcc: [],
          subject: '',
          templateData: intialTemplateData,
        },
        initialErrors: { to: '', subject: '' },
        validationSchema: sendTestSchema,
        onSubmit: (values) => {
          //we have to convert any json data tag values back into objects before sending
          let templateData = JSON.parse(values?.templateData || '{}');
          for (const key in templateData) {
            try {
              templateData[key] = JSON.parse(templateData[key]); //if it doesn't throw an error, it was json
            } catch (e) {}
          }

          try {
            makeRequest({
              dataPayload: {
                basic: false,
                content: templateHtml,
                subject: values?.subject,
                data_tags: templateData,
              },
              recipients: {
                to: values?.to,
                cc: values?.cc,
                bcc: values?.bcc,
              },
            });
          } catch (e) {
            toast.error('An unexpected error occurred.');
          }
        },
        enableReinitialize: true,
      }}
      loading={loading}
    >
      {(formikProps) => (
        <>
          <FormikAutocomplete
            name='to'
            label='To'
            options={userEmails || []}
            loading={loadingUserEmails}
            {...formikProps}
            disabled={loading}
          />

          <FormikAutocomplete
            name='cc'
            label='CC'
            options={userEmails || []}
            loading={loadingUserEmails}
            {...formikProps}
            disabled={loading}
          />

          <FormikAutocomplete
            name='bcc'
            label='BCC'
            options={userEmails || []}
            loading={loadingUserEmails}
            {...formikProps}
            disabled={loading}
          />

          <FormControl variant='outlined' className={classes.formControl}>
            <Field
              component={TextField}
              label='Subject'
              type='text'
              name='subject'
              fullWidth
              disabled={loading}
            />
          </FormControl>

          <div style={{ opacity: intialTemplateData ? 1 : 0.65 }}>
            <div style={{ fontWeight: 'bold', marginTop: '1.25rem' }}>
              Template Data
            </div>
            <div style={{ fontSize: '.9rem', color: colors.grey[600] }}>
              JSON formatted object containing key/value replacement pairs for
              the data tags in the template. You can use the defaults or set
              your own.
            </div>

            <FormControl variant='outlined' className={classes.formControl}>
              <Field
                component={TextField}
                name='templateData'
                fullWidth
                multiline
                rows={6}
                spellCheck={false}
                disabled={!intialTemplateData}
              />
            </FormControl>
          </div>
        </>
      )}
    </FormDialog>
  );
};

export default SendTest;
