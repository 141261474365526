import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Autocomplete, TextField, Button, Tooltip } from '@mui/material';
import PickerBase from './PickerBase.js';
import usePrevious from '../../../utils/hooks/usePrevious';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginBottom: '1rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: '.5rem',
    '& > .MuiButton-root': {
      marginLeft: '.5rem',
    },
  },
}));

const DataTagPicker = (props) => {
  const { show, tags, onClose, onChange } = props;
  const classes = useStyles();
  const [tag, setTag] = useState(null);
  const prevProps = usePrevious({ show });

  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (show && !prevProps.show) {
      setTag(null); //data tag picker was just opened so reset field
    }
  });

  const handleChange = (e, value) => {
    setTag(value);
  };

  const handleAdd = () => {
    if (!tag) {
      return;
    }

    onChange(tag);
    onClose();
  };

  return (
    <PickerBase {...props}>
      <div className={classes.form}>
        <Autocomplete
          options={tags || []}
          onChange={handleChange}
          size='small'
          blurOnSelect={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Data Tag'
              style={{ width: '100%', margin: 0 }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <Tooltip
                title={option?.description}
                key={props?.key + option?.label}
                disableInteractive
              >
                <div
                  {...props}
                  style={{ padding: '.5rem 1rem', display: 'block' }}
                  onMouseDown={(e) => {
                    e.nativeEvent.stopImmediatePropagation();
                  }}
                >
                  {option?.label}
                </div>
              </Tooltip>
            );
          }}
          isOptionEqualToValue={(option, value) =>
            option?.label === value?.label
          }
          value={tag}
        />
      </div>
      <div className={classes.actions}>
        <Button
          color='primary'
          size='small'
          onClick={handleAdd}
          disabled={!tag}
        >
          Add
        </Button>
        <Button color='default' size='small' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </PickerBase>
  );
};

export default DataTagPicker;
