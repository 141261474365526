import React, { useRef, useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Grow } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  pickerWrapper: {
    position: 'absolute',
    bottom: '-4px',
    left: '2px',
    transform: 'translateY(100%)',
    zIndex: 99,
    '&.right': {
      left: 'auto',
      right: '2px',
    },
  },
  picker: {
    width: '276px',
    background: '#FFFFFF',
    border: '0px solid rgba(0, 0, 0, 0.25)',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 2px 1px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 16%)',
    borderRadius: '4px',
    position: 'relative',
  },
  triangleOne: {
    width: '0px',
    height: '0px',
    borderStyle: 'solid',
    borderWidth: '0px 9px 10px',
    borderColor: 'transparent transparent rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    top: '-11px',
    left: '12px',
    '&.right': {
      left: 'auto',
      right: '12px',
    },
  },
  triangleTwo: {
    width: '0px',
    height: '0px',
    borderStyle: 'solid',
    borderWidth: '0px 9px 10px',
    borderColor: 'transparent transparent rgb(255, 255, 255)',
    position: 'absolute',
    top: '-10px',
    left: '12px',
    '&.right': {
      left: 'auto',
      right: '12px',
    },
  },
  pickerBody: {
    padding: '1rem 1rem .25rem 1rem',
  },
}));

export default ({ children, onMouseDown, width, show = false, onClose }) => {
  const classes = useStyles();
  const node = useRef(null);
  const [isShown, setIsShown] = useState(false);
  const [right, setRight] = useState(false);

  const handleFlipPosition = useCallback(() => {
    /*
      This function checks if the picker exceeds the width (plus a 10px padding) of the closest parent with overflow set to hidden. If it does, it flips the picker to the right. It does not work if the picker exceeds the width of the overflow parent completely... but there isn't much of a solution for that.
    */
    if (!isShown || !node?.current) {
      return;
    }

    let el = node.current;
    while (
      (el = el.parentElement) &&
      !(
        getComputedStyle(el)?.overflow === 'hidden' ||
        getComputedStyle(el)?.overflowX === 'hidden'
      )
    );
    const parentBounds = el?.getBoundingClientRect();
    const pickerBounds = node.current.getBoundingClientRect();
    if (pickerBounds && parentBounds) {
      setRight(
        (prevRight) =>
          pickerBounds?.x + pickerBounds?.width * (prevRight ? 2 : 1) + 10 >=
          parentBounds?.x + parentBounds?.width
      );
    }
  }, [isShown, node]);

  useEffect(() => {
    handleFlipPosition(); //if show is toggled or the width of the pickers changes
  }, [show, width, handleFlipPosition]);

  useEffect(() => {
    //if the window is resized we need to check whether the picker should be flipped again

    window.addEventListener('resize', handleFlipPosition);

    return () => {
      window.removeEventListener('resize', handleFlipPosition);
    };
  }, [handleFlipPosition]);

  const handleClick = useCallback(
    (e) => {
      if (!node.current.contains(e.target)) {
        //outside click should close the picker
        if (typeof onClose === 'function') {
          onClose();
        }
      }
    },
    [node, onClose]
  );

  useEffect(() => {
    //if show is toggled, handle the outside click event listener

    if (show) {
      document.addEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [show, handleClick]);

  return (
    <div className={clsx(classes.pickerWrapper, right && 'right')}>
      <Grow
        in={show}
        onEnter={() => {
          setIsShown(true);
        }}
        onExited={() => {
          setIsShown(false);
        }}
      >
        {isShown ? (
          <div
            className={classes.picker}
            style={width ? { width: width } : {}}
            ref={node}
            onMouseDown={
              onMouseDown !== false
                ? (e) => {
                    e.preventDefault();
                  }
                : undefined
            }
          >
            <div className={clsx(classes.triangleOne, right && 'right')}></div>
            <div className={clsx(classes.triangleTwo, right && 'right')}></div>
            <div className={classes.pickerBody}>{children}</div>
          </div>
        ) : (
          <div />
        )}
      </Grow>
    </div>
  );
};
