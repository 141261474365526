import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Field } from 'formik';
import { CIS_INTERNAL, ROLE_OPTIONS, USER } from '../../utils/auth/constants';
import {
  Divider,
  FormControl,
  Grid,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Select, TextField } from 'formik-mui';
import userCreateSchema from '../../data/schemas/createUserSchema';
import Loader from '../Loader';
import { useAuthContext } from '../../utils/auth/hooks';
import FormDialog from '../FormDialog';
import { useApiPost } from '../../utils/hooks';
import generatePassword from '../../utils/auth/generatePassword';
import ToggleAccordion from '../formik/ToggleAccordion';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  orgSelect: {
    paddingRight: '3rem',
  },
}));

export const UserCreate = ({
  open,
  setOpen,
  organizations = [],
  loadingOrgs,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { user, roleIsAtLeast } = useAuthContext();
  const {
    request,
    error,
    fire: makeRequest,
    data,
    isLoading: loading,
  } = useApiPost('/users');

  function getOrganization(val) {
    return organizations?.filter((o) => o.name === val)[0] || [];
  }

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    if (request.isSuccess) {
      toast.success('User successfully created.');
      history.push({
        pathname: `/user/${encodeURIComponent(data.email)}/products`,
        state: { initialUser: data },
      });
    }
  }, [request.isSuccess, data, history, error]);

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      title='Create User'
      formikProps={{
        initialValues: {
          organization: user.organization || '',
          email: '',
          firstName: '',
          lastName: '',
          role: USER,
          userId: '',
          useExternal: false,
        },
        validationSchema: userCreateSchema,
        onSubmit: (values) => {
          if (loading) {
            return;
          }

          makeRequest({
            ...values,
            organization: values.organization,
            password: values.useExternal ? null : generatePassword(),
          });
        },
        enableReinitialize: true,
      }}
      loading={loading}
    >
      {({ values, setFieldValue }) => (
        <>
          {roleIsAtLeast(CIS_INTERNAL) && (
            <FormControl variant='outlined' className={classes.formControl}>
              <Field
                component={Select}
                labelId='org-label'
                label='Organization'
                type='text'
                name='organization'
                fullWidth
                variant='outlined'
                endAdornment={
                  loadingOrgs ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : undefined
                }
                className={classes.orgSelect}
                disabled={loading || !organizations?.length}
              >
                {loadingOrgs ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : (
                  organizations?.map((org) => (
                    <MenuItem value={org.name} key={org.name}>
                      {`${org.name} - ${org.displayName}`}
                    </MenuItem>
                  ))
                )}
              </Field>
            </FormControl>
          )}

          <Divider className={classes.divider} />

          <FormControl variant='outlined' className={classes.formControl}>
            <Field
              component={TextField}
              label='Email Address'
              type='email'
              name='email'
              fullWidth
              variant='outlined'
              disabled={loading}
            />
          </FormControl>

          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl variant='outlined' className={classes.formControl}>
                <Field
                  component={TextField}
                  label='First Name'
                  type='text'
                  name='firstName'
                  fullWidth
                  variant='outlined'
                  disabled={loading}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl variant='outlined' className={classes.formControl}>
                <Field
                  component={TextField}
                  label='Last Name'
                  type='text'
                  name='lastName'
                  fullWidth
                  variant='outlined'
                  disabled={loading}
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormControl variant='outlined' className={classes.formControl}>
            <Field
              component={Select}
              label='Role'
              labelId='role-label'
              type='text'
              name='role'
              fullWidth
              variant='outlined'
              disabled={loading}
            >
              {ROLE_OPTIONS.map((option) =>
                roleIsAtLeast(option.value) ? (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ) : null
              )}
            </Field>
          </FormControl>

          {getOrganization(values.organization).usesOwnIdp && (
            <>
              <Divider className={classes.divider} />

              <ToggleAccordion
                name='useExternal'
                value={values.useExternal}
                setFieldValue={setFieldValue}
                label='Use External IDP'
                disabled={loading}
              >
                <FormControl variant='outlined' className={classes.formControl}>
                  <Field
                    label='Provider Name'
                    component={Select}
                    labelId='idp-name'
                    type='text'
                    name='provider'
                    fullWidth
                    variant='outlined'
                    startAdornment={loadingOrgs ? <Loader /> : null}
                  >
                    {loadingOrgs ? (
                      <Loader />
                    ) : (
                      getOrganization(
                        values.organization
                      ).config?.providers.map((p) => (
                        <MenuItem value={p.name} key={p.name}>
                          {`${p.name}`}
                          {p.description && ` - ${p.description}`}
                        </MenuItem>
                      ))
                    )}
                  </Field>
                </FormControl>

                {
                  // this is very hardcoded to handle one pattern of user access that we are now trying to discourage
                  values['provider'] === 'morgan-stanley' && (
                    <FormControl
                      variant='outlined'
                      className={classes.formControl}
                    >
                      <Field
                        component={TextField}
                        label='User id'
                        type='text'
                        name='stanleyUsername'
                        fullWidth
                        variant='outlined'
                      />
                    </FormControl>
                  )
                }
              </ToggleAccordion>
            </>
          )}
        </>
      )}
    </FormDialog>
  );
};
