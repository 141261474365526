import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Sections from './Sections';
import SectionNavigation from '../../components/SectionNavigation';
import Loader from '../../components/Loader';
import ErrorScreen from '../../components/ErrorScreen';
import { useAuthContext } from '../../utils/auth/hooks';
import RootContainer from '../../components/RootContainer.js';
import { useApiGet } from '../../utils/hooks';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageTitle from '../../components/PageTitle';

export const UserEdit = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [userToDisplay, setUserToDisplay] = useState({});
  const {
    path,
    url,
    params: { email },
  } = useRouteMatch('/user/:email');
  const { user: signedInUser } = useAuthContext();
  const { isMe = email.toLowerCase() === 'me' } = props;
  const userEmail = isMe ? signedInUser?.email : decodeURIComponent(email);

  useEffect(() => {
    //redirect to /me route if the user is viewing themself
    if (
      email.toLowerCase() !== 'me' &&
      signedInUser?.email === decodeURIComponent(email)
    ) {
      history.push(location?.pathname?.replace(email, 'me'));
    }
  });

  const {
    isLoading: loading,
    error,
    data: apiUser,
    fire: refreshUser,
  } = useApiGet(`/users/${isMe ? 'me' : encodeURIComponent(userEmail)}`, {
    fireOnMount: true,
  });

  const locationUser = props?.location?.state?.initialUser;
  useEffect(() => {
    if (apiUser?.email === userEmail) {
      /*
        only use apiUser if it is the current result of the user we want to display...

        if you are viewing a user and click the "Account Settings" link in the navigation the component doesn't unmount so apiUser is already populated with the other users details, that is why we need to compare the email values
      */
      setUserToDisplay(apiUser);
    } else if (isMe) {
      setUserToDisplay(signedInUser);
    } else if (locationUser) {
      setUserToDisplay(locationUser);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUser, isMe, locationUser]);

  //have to get this separate to keep the path and url sans section for the nested section router below
  const sectionMatch = useRouteMatch('/user/:email/:section');
  const section = sectionMatch?.params?.section;

  let crumbsTrail = {};
  if (!isMe) {
    crumbsTrail['Admin'] = null;
  }

  crumbsTrail['Users'] = '/users';

  crumbsTrail[
    userToDisplay?.email
      ? `${userToDisplay.firstName} ${userToDisplay.lastName}`
      : 'N/A'
  ] = url;
  if (section) {
    let sectionTitle = 'N/A';
    //eslint-disable-next-line default-case
    switch (section) {
      case 'details':
        sectionTitle = 'Profile';
        break;
      case 'products':
        sectionTitle = 'Products';
        break;
    }
    crumbsTrail[sectionTitle] = `${url}/${section}`;
  }

  const initialUserDisplayed = loading && !!userToDisplay?.email;
  return (
    <RootContainer>
      <PageTitle title='Edit User' />
      <Breadcrumbs trail={crumbsTrail} />
      <br />

      <div>
        <SectionNavigation
          sections={[
            {
              text: 'Profile',
              label: 'details',
              url: `${url}/details`,
            },
            {
              text: 'Product Access',
              label: 'products',
              url: `${url}/products`,
            },
          ]}
        />

        <div>
          {loading && !userToDisplay?.email ? (
            <Loader />
          ) : !userEmail || (error && error !== 'TOKEN FAILURE') ? (
            <ErrorScreen
              title='Not Found'
              code='404'
              message='The user you were looking for does not exist'
              fontSize='.75rem'
            />
          ) : (
            <Switch>
              <Route path={`${path}/:section`}>
                <Sections
                  user={userToDisplay}
                  isMe={isMe}
                  initialUserDisplayed={initialUserDisplayed}
                  refreshUser={refreshUser}
                />
              </Route>
              <Redirect to={`${url}/details`} />
            </Switch>
          )}
        </div>
      </div>
    </RootContainer>
  );
};
