import { Button, Chip, TextField, Tooltip } from '@mui/material';
import { FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Title from '../Title';
import { FieldArray } from 'formik';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  addButtonBox: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    width: '100%',
  },
  container: {
    width: '100%',
    padding: theme.spacing(4),
  },
  label: {
    textAlign: 'center',
  },
  wrap: {
    display: 'flex',
  },
  newItem: {
    minWidth: '400px',
    padding: theme.spacing(2),
  },
  chipBag: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(2),
  },
}));

export default function NameAndDescList(props) {
  const { name, value, label, nameLabel, descLabel } = props;

  const [currentName, setCurrentName] = useState('');
  const [currentDesc, setCurrentDesc] = useState('');
  const classes = useStyles();

  return (
    <FieldArray name={name} value={value}>
      {({ remove, push }) => (
        <div className={classes.container}>
          <div className={classes.label}>
            <Title title={label} variant='h4' />
          </div>

          <div className={classes.wrap}>
            <div className={classes.newItem}>
              <FormControl variant='outlined' className={classes.formControl}>
                <TextField
                  variant='outlined'
                  label={nameLabel || 'Name'}
                  value={currentName}
                  onChange={(e) => setCurrentName(e.target.value)}
                />
              </FormControl>
              <FormControl variant='outlined' className={classes.formControl}>
                <TextField
                  variant='outlined'
                  label={descLabel || 'Description'}
                  value={currentDesc}
                  onChange={(e) => setCurrentDesc(e.target.value)}
                />
              </FormControl>
              <Button
                variant='contained'
                color='primary'
                type='button'
                size='large'
                onClick={() => {
                  push({
                    name: currentName,
                    desc: currentDesc,
                  });
                  setCurrentName('');
                  setCurrentDesc('');
                }}
              >
                Add
              </Button>
            </div>

            <div className={classes.chipBag}>
              {value &&
                Array.isArray(value) &&
                value.map((item, index) => (
                  <Tooltip title={item.desc} key={item.name}>
                    <Chip
                      label={item.name}
                      onDelete={() => remove(index)}
                      className={classes.chip}
                    />
                  </Tooltip>
                ))}
            </div>
          </div>
        </div>
      )}
    </FieldArray>
  );
}
