import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import AddUserIcon from '@mui/icons-material/PersonAdd';
import MigrateUsersIcon from '@mui/icons-material/GroupAdd';
import SearchIcon from '@mui/icons-material/Search';
import Fuse from 'fuse.js';
import { makeStyles } from '@mui/styles';
import { UserCreate } from '../UserCreate';
import usePrevious from '../../utils/hooks/usePrevious.js';
import { useAuthContext } from '../../utils/auth/hooks';
import { CIS_INTERNAL } from '../../utils/auth/constants';
import { setQueryStringParameter } from '../../utils/routes';
import SplitButton from '../SplitButton';
import MigrateIBankUsers from '../MigrateIBankUsers';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    background: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: '4px',
  },
  searchWrapper: {
    flex: 1,
    marginRight: '1rem',
    '& > div:only-child': {
      width: '75%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    '& > div:first-of-type': {
      width: '50%',
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        width: '41.66%',
      },
    },
    '& > div:last-of-type': {
      width: '25%',
      [theme.breakpoints.down('md')]: {
        width: '33.33%',
      },
    },
  },
  iqcxAccountSelect: {
    paddingRight: '3rem',
  },
  selectItem: {
    whiteSpace: 'normal',
    paddingRight: '3rem',
    display: 'block',
  },
}));

export const UserListFilters = ({
  setSearchResults,
  searchOrg,
  setSearchOrg,
  users = [],
  organizations = [],
  loadingOrgs,
}) => {
  const classes = useStyles();
  const { roleIsAtLeast } = useAuthContext();
  const [showUserCreate, setShowUserCreate] = useState(false);
  const [showMigrateIBankUsers, setShowMigrateIBankUsers] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [canMigrateIBankUsers, setCanMigrateIBankUsers] = useState(false);
  const prevProps = usePrevious({ users });

  useEffect(() => {
    if (
      !prevProps?.users ||
      JSON.stringify(users) !== JSON.stringify(prevProps?.users)
    ) {
      onFilter();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const onFilter = (event) => {
    if (!searchInputValue.length) {
      setSearchResults(users || []);
      return;
    }

    const options = {
      threshold: 0.4,
      keys: ['email', 'firstName', 'lastName'],
    };
    const fuse = new Fuse(users, options);
    const filtered = fuse.search(searchInputValue).map((res) => ({
      ...res.item,
      matches: res.matches,
    }));
    setSearchResults(filtered);
  };

  const handleOrgChange = useCallback(
    (e) => {
      const value = e.target.value;

      setSearchOrg(value);
      setQueryStringParameter('org', value);
    },
    [setSearchOrg]
  );

  useEffect(() => {
    //toggle whether or not migrating ibank users should be enabled in the create button
    //should be disabled if the hub org doesn't have ibank configured with agencies
    const orgIBankConfig = organizations.find((_) => _.name === searchOrg)
      ?.config?.iBank;
    setCanMigrateIBankUsers(
      orgIBankConfig?.active && orgIBankConfig?.agencies?.length
    );
  }, [organizations, searchOrg]);

  return (
    <div className={classes.container}>
      <div className={classes.searchWrapper}>
        <TextField
          label='Search users'
          variant='outlined'
          value={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
          onKeyDown={onFilter}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {roleIsAtLeast(CIS_INTERNAL) && (
          <FormControl variant='outlined'>
            <InputLabel htmlFor='organization'>Organization</InputLabel>
            <Select
              value={searchOrg || ''}
              onChange={handleOrgChange}
              label='Organization'
              endAdornment={
                loadingOrgs ? (
                  <CircularProgress color='inherit' size={20} />
                ) : undefined
              }
              inputProps={{
                name: 'organization',
                id: 'organization',
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              disabled={loadingOrgs}
              className={classes.iqcxAccountSelect}
            >
              {organizations?.map((org) => (
                <MenuItem
                  key={org?.name}
                  value={org?.name}
                  className={classes.selectItem}
                >
                  {org?.name} - {org?.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      {roleIsAtLeast(CIS_INTERNAL) ? (
        <SplitButton
          size='large'
          options={[
            {
              text: 'Create User',
              icon: <AddUserIcon fontSize='medium' />,
              callback: () => setShowUserCreate(true),
            },
            {
              text: 'Migrate iBank Users',
              icon: <MigrateUsersIcon fontSize='medium' />,
              disabled: !canMigrateIBankUsers,
              tooltip: !canMigrateIBankUsers
                ? 'The selected organization is not configured for iBank'
                : '', //should we have a link to the product access page?
              callback: () => setShowMigrateIBankUsers(true),
            },
          ]}
        />
      ) : (
        <Button
          size='large'
          variant='contained'
          onClick={() => setShowUserCreate(true)}
          startIcon={<AddUserIcon fontSize='medium' />}
        >
          CREATE USER
        </Button>
      )}
      <UserCreate
        open={showUserCreate}
        setOpen={setShowUserCreate}
        organizations={organizations}
        loadingOrgs={loadingOrgs}
      />
      <MigrateIBankUsers
        open={showMigrateIBankUsers}
        setOpen={setShowMigrateIBankUsers}
        destinationOrg={organizations?.find((_) => _.name === searchOrg)}
      />
    </div>
  );
};
