import { makeStyles } from '@mui/styles';

export const loginStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: '#0061AD',
    background: `url("${process.env.REACT_APP_STATIC_ASSETS_URL}cis-hub-bg.jpg")`,
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  paper: {
    borderRadius: '8px',
    padding: '1rem 1rem 2rem',
    boxSizing: 'border-box',
  },
  logo: {
    background: `url("${process.env.REACT_APP_STATIC_ASSETS_URL}cis-logo-white.png")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: '55px',
    marginBottom: '1rem',
  },
  title: {
    marginTop: 0,
  },
  description: {
    marginBottom: '1.5rem',
    marginTop: '-1rem',
    color: theme.palette.text.secondary,
  },
  input: {
    marginBottom: '1rem',
  },
  basicInput: {
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.cisBlue,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.cisBlue,
    },
  },
}));
