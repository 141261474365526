import React, { useEffect } from 'react';
import { Field } from 'formik';
import {
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Select, TextField, RadioGroup } from 'formik-mui';
import FormDialog from '../../../components/FormDialog';
import { useApiPost } from '../../../utils/hooks';
import * as yup from 'yup';

const templateCreateSchema = yup.object().shape({
  templateName: yup
    .string()
    .max(50, 'Name must be at most 50 characters')
    .required('Name is a required field'),
  product: yup.string().required('Product is a required field'),
  from: yup.string().matches(/(blank|existing)/, { excludeEmptyString: true }),
  existing: yup.string().when('from', {
    is: 'existing',
    then: yup.string().required('Select an existing template'),
  }),
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  label: {
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectAdornment: {
    marginRight: theme.spacing(3),
  },
}));

const NewTemplate = ({
  open,
  setOpen,
  templates = [],
  loadingTemplates = false,
  onSubmit,
}) => {
  const classes = useStyles();
  const {
    request,
    isLoading: loadingPost,
    fire: makeRequest,
    data,
  } = useApiPost('/templates');

  useEffect(() => {
    if (!open) {
      return;
    }

    if (request.isSuccess) {
      if (typeof onSubmit === 'function') {
        onSubmit(data);
      }
      setOpen(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, request.isSucess, data, onSubmit]);

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      title='New Template Draft'
      saveButtonText='Add'
      maxWidth='sm'
      loading={loadingPost}
      formikProps={{
        initialValues: {
          templateName: '',
          product: 'risk-messenger',
          from: 'blank',
          existing: '',
        },
        validationSchema: templateCreateSchema,
        onSubmit: (values) => {
          if (loadingPost) {
            return;
          }

          makeRequest({
            name: values.templateName,
            product: values.product,
            copy: values.existing,
          });
        },
      }}
    >
      {({ values }) => (
        <>
          <FormControl variant='outlined' className={classes.formControl}>
            <Field
              component={TextField}
              label='Name'
              type='text'
              name='templateName'
              fullWidth
              disabled={loadingPost}
            />
          </FormControl>

          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel className={classes.label} id='product-label'>
              Product
            </InputLabel>
            <Field
              component={Select}
              labelId='product-label'
              type='text'
              name='product'
              fullWidth
              disabled={loadingPost}
            >
              <MenuItem value='risk-messenger'>Risk Messenger</MenuItem>
            </Field>
          </FormControl>

          <div style={{ fontWeight: 'bold' }}>Create from</div>

          <Field component={RadioGroup} name='from'>
            <FormControlLabel
              control={<Radio />}
              label='Blank'
              value='blank'
              disabled={loadingPost}
            />
            <FormControlLabel
              control={<Radio />}
              label='Existing template'
              value='existing'
              disabled={loadingPost}
            />
          </Field>

          <FormControl
            variant='outlined'
            className={classes.formControl}
            style={{ marginLeft: '42px' }}
          >
            <InputLabel className={classes.label} id='existing-label'>
              Select a template
            </InputLabel>
            <Field
              component={Select}
              labelId='existing-label'
              type='text'
              name='existing'
              fullWidth
              endAdornment={
                loadingTemplates ? (
                  <InputAdornment
                    className={classes.selectAdornment}
                    position='end'
                  >
                    <CircularProgress color='inherit' size={20} />
                  </InputAdornment>
                ) : null
              }
              disabled={
                loadingPost || !templates?.length || values.from !== 'existing'
              }
            >
              {loadingTemplates ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : (
                templates?.map((template) => (
                  <MenuItem value={template.id} key={template.id}>
                    {template.name}
                  </MenuItem>
                ))
              )}
            </Field>
          </FormControl>
        </>
      )}
    </FormDialog>
  );
};

export default NewTemplate;
