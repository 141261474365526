import React, { useEffect, useRef, useState, useContext } from 'react';
import { Header, HEADER_HEIGHT } from '../Header';
import { Sidebar } from '../Sidebar';
import { makeStyles } from '@mui/styles';
import { CssBaseline } from '@mui/material';
import { useIsMobileDevice } from '../../utils/hooks';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';

const useStyles = makeStyles((theme) => ({
  viewport: {
    display: 'flex',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.palette.background.default,
  },
  main: {
    width: '100%',
    display: 'flex',
    flex: 1,
  },
  container: {
    overflow: 'auto',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  stickyActionsBar: {
    '& .decoy': {
      position: 'fixed',
      bottom: 0,
      opacity: 0,
      pointerEvents: 'none',
      boxShadow: 'none',
      backgroundColor: theme.palette.white.main,
      transition: 'box-shadow 250ms',
      '&.show': {
        padding: '1rem',
        boxSizing: 'border-box',
        zIndex: 1,
        boxShadow:
          '0px -1px 4px 0px rgb(0 0 0 / 5%), 0px -2px 5px 0px rgb(0 0 0 / 10%), 0px 0px 12px 0px rgb(0 0 0 / 15%)',
        opacity: 1,
        pointerEvents: 'all',
      },
    },
    '& .target': {
      opacity: 1,
      pointerEvents: 'all',
      '&.hide': {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
}));

let onLayoutScrollCallbacks = []; //should only ever have one StickyActionsBar on screen at once, but this handles multiple onScroll events
const onLayoutScroll = (e) => {
  onLayoutScrollCallbacks.forEach((_) => _(e));
};

export const StickyActionsBar = ({ children }) => {
  const classes = useStyles();
  const parentEl = useContext(LayoutContext);
  const [sticky, setSticky] = useState(false);
  const [width, setWidth] = useState('auto');
  const [left, setLeft] = useState(0);
  const targetEl = useRef(null);
  const decoyEl = useRef(null);

  const handleScroll = (e) => {
    const parent = parentEl?.current || e?.currentTarget;
    if (!parent) {
      return;
    }

    const parentHeight = parent?.offsetHeight || 0;
    const parentWidth = parent?.clientWidth || 0;
    const parentPos = parent?.getBoundingClientRect();
    const parentLeft = parentPos.x || 0;
    const actionsBarPos = targetEl?.current?.getBoundingClientRect();
    const relativeTop = (actionsBarPos.top || 0) - (parentPos.top || 0);

    setSticky(
      relativeTop > parentHeight - (decoyEl?.current?.offsetHeight || 0)
    );
    setWidth(parentWidth);
    setLeft(parentLeft);
  };

  useEffect(() => {
    onLayoutScrollCallbacks.push(handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('resize', handleScroll);
      const index = onLayoutScrollCallbacks.findIndex(
        (_) => _ === handleScroll
      );
      if (~index) {
        onLayoutScrollCallbacks.splice(index, 1);
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleScroll(); //update on every render in case contents of page changed
  });

  return (
    <div className={classes.stickyActionsBar}>
      <div
        className={clsx('decoy', sticky && 'show')}
        style={{ width, left }}
        ref={decoyEl}
      >
        {children}
      </div>
      <div className={clsx('target', sticky && 'hide')} ref={targetEl}>
        {children}
      </div>
    </div>
  );
};

const LayoutContext = React.createContext(null);

export const Layout = ({
  noScroll,
  children,
  logo,
  setLogo,
  isNewLogo,
  orgConfig,
}) => {
  const classes = useStyles();
  const containerEl = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isMobile = useIsMobileDevice();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setMobileMenuOpen(open);
  };

  return (
    <>
      <CssBaseline />

      <Header
        logo={logo}
        setLogo={setLogo}
        isMobile={isMobile}
        toggleMobileMenu={toggleDrawer}
      />

      <div className={classes.viewport}>
        {!isMobile ? (
          <Sidebar
            isNewLogo={isNewLogo}
            isMobile={isMobile}
            orgConfig={orgConfig}
          />
        ) : (
          <Drawer
            anchor={'left'}
            open={mobileMenuOpen}
            onClose={toggleDrawer(false)}
          >
            <Sidebar
              logo={logo}
              isNewLogo={isNewLogo}
              isMobile={isMobile}
              orgConfig={orgConfig}
            />
          </Drawer>
        )}
        <div
          className={classes.container}
          onScroll={onLayoutScroll}
          ref={containerEl}
        >
          <LayoutContext.Provider value={containerEl}>
            <div
              className={classes.main}
              style={noScroll ? { overflow: 'hidden' } : null}
            >
              {children}
            </div>
          </LayoutContext.Provider>
        </div>
      </div>
    </>
  );
};
