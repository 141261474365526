import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { Box, Grid, Paper, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { loginStyles } from './loginStyles';

export const ConfirmPasswordReset = ({ location }) => {
  const email = location?.state?.email?.trim();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    code: '',
    password: '',
  });
  const classes = loginStyles();

  const submitForm = async () => {
    setLoading(true);
    try {
      const trimCode = formData?.code?.trim();
      const trimPassword = formData?.password?.trim();
      await Auth.forgotPasswordSubmit(email, trimCode, trimPassword);
      setFormData({
        code: '',
        password: '',
      });
      setLoading(false);
      history.push('/');
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(`Failed to reset password. ${error.message}`);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      style={{ minHeight: '100vh' }}
      className={classes.wrapper}
    >
      <Box m='auto' width={'80%'} maxWidth={400}>
        <div className={classes.logo} />

        <Box
          component={Paper}
          width={'100%'}
          bgcolor='white'
          justifyContent='center'
          display='flex'
          elevation={12}
          className={classes.paper}
        >
          {
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault();
                submitForm();
              }}
            >
              <h2 className={classes.title}>Reset Password</h2>
              <p className={classes.description}>
                Enter your new password and the confirmation code we emailed you
                below.
              </p>

              <TextField
                variant='outlined'
                onChange={handleInputChange}
                className={[classes.basicInput, classes.input]}
                placeholder='Enter the code emailed to you'
                label='Verification Code'
                name='code'
                InputLabelProps={{
                  style: { color: '#0061AD' },
                }}
              />

              <TextField
                variant='outlined'
                onChange={handleInputChange}
                placeholder='Enter your new password'
                label='New Password'
                name='password'
                type='password'
                className={[classes.input, classes.basicInput]}
                InputLabelProps={{
                  style: { color: '#0061AD' },
                }}
              />

              <LoadingButton
                loading={loading}
                color='secondary'
                variant='contained'
                type='submit'
                disabled={!formData.code || !formData.password || loading}
              >
                Reset Password
              </LoadingButton>
            </form>
          }
        </Box>
      </Box>
    </Grid>
  );
};
