import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RecordsUploaded = (props) => (
  <SvgIcon {...props} width='40px' height='40px' viewBox='0 0 40 40'>
    <title>Records-Uploaded</title>
    <g
      id='User-Tables'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Workflow---User-Table-Upload-1.5.1'
        transform='translate(-807.000000, -346.000000)'
      >
        <g id='Content' transform='translate(234.000000, 166.000000)'>
          <g id='Upload-Preview' transform='translate(548.000000, 74.000000)'>
            <g id='Content' transform='translate(20.000000, 13.000000)'>
              <g id='Icons-v3' transform='translate(5.000000, 37.000000)'>
                <g
                  id='Records-Uploaded'
                  transform='translate(0.000000, 56.000000)'
                >
                  <g
                    id='document-text'
                    transform='translate(5.692308, 6.692308)'
                  >
                    <g
                      id='Group-5'
                      transform='translate(18.122596, 14.670673)'
                      stroke='#656565'
                      strokeDasharray='0,0'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                    >
                      <path
                        d='M1.72596156,5.17788469 L1.72596156,0 L0,1.72596156'
                        id='Vector'
                        fillRule='nonzero'
                      ></path>
                      <line
                        x1='1.72596156'
                        y1='0'
                        x2='3.45192312'
                        y2='1.72596156'
                        id='Vector'
                      ></line>
                    </g>
                    <path
                      d='M25.3141029,11.5064104 L25.3141029,17.2596156 C25.3141029,23.0128208 23.0128208,25.3141029 17.2596156,25.3141029 L10.3557694,25.3141029 C4.60256417,25.3141029 2.30128208,23.0128208 2.30128208,17.2596156 L2.30128208,10.3557694 C2.30128208,4.60256417 4.60256417,2.30128208 10.3557694,2.30128208 L16.1089746,2.30128208'
                      id='Vector'
                      strokeOpacity='0.6'
                      stroke='#2196F3'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeDasharray='0,0'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M20.7115388,11.5064104 C17.2596156,11.5064104 16.1089746,10.3557694 16.1089746,6.90384625 L16.1089746,2.30128208 L25.3141029,11.5064104 L20.7115388,11.5064104 Z'
                      id='Vector'
                      strokeOpacity='0.6'
                      stroke='#2196F3'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeDasharray='0,0'
                      fillRule='nonzero'
                    ></path>
                    <line
                      x1='8.05448729'
                      y1='14.9583335'
                      x2='14.9583335'
                      y2='14.9583335'
                      id='Vector'
                      strokeOpacity='0.6'
                      stroke='#2196F3'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeDasharray='0,0'
                    ></line>
                    <line
                      x1='8.05448729'
                      y1='19.5608977'
                      x2='12.6570515'
                      y2='19.5608977'
                      id='Vector'
                      strokeOpacity='0.6'
                      stroke='#2196F3'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeDasharray='0,0'
                    ></line>
                    <path
                      d='M27.615385,0 L27.615385,25.8894234 L0,25.8894234 L0,0 L27.615385,0 Z'
                      id='Vector'
                      opacity='0'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default RecordsUploaded;
