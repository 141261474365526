import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  indent: {
    textIndent: '2rem',
  },
}));

export default function EulaText() {
  const classes = useStyles();

  return (
    <>
      <p className={classes.center}>
        Risk Messenger™ Software Service End User License Agreement
        <br />
        Terms and Conditions of Use
        <br />
        <u>Cornerstone Information Systems, Inc.</u>
      </p>

      <p>
        Each access of, review of, or use of the Risk Messenger™ hosted software
        services product or any part of it (the “Product”) by you (“You” “Your”)
        in any manner for any purpose constitutes the agreement of You, as a
        party, and Cornerstone Information Systems, Inc. (“Cornerstone,” “Us,”
        “We,” “Our”), as a party, to these terms and conditions ("Terms"), and
        the Terms shall govern your relationship with any access or use of the
        Product. We may change the Terms from time to time, and the Terms in
        effect at the time of any access of the Product shall constitute the
        Terms and shall control. If You do not agree to the Terms, in whole or
        in part, please do not access or use the Product.
      </p>

      <br />

      <p className={classes.indent}>
        1. <u>Availability, Errors, and Inaccuracies</u>
      </p>
      <p>
        In order to provide best service, We may update the Product from time to
        time. We do not guarantee the accuracy or completeness of any
        information provided by or through the Product, directly or indirectly.
        We reserve the right to change or update information supporting the
        Product and to correct errors, inaccuracies, or omissions of the Product
        at any time without prior notice. We reserve the right at any time and
        from time to time to modify or discontinue, temporarily or permanently,
        the Product (or any part thereof) with or without notice.
      </p>

      <p>
        Cornerstone provides the Product to provide limited information to
        assist travelers with regard to their destinations. Some supporting
        information for the Product may be sourced from third parties and may be
        further interpreted by Cornerstone as incorporated into the Product.
        Cornerstone does not represent or warrant to You that: (1) Your use of
        the Product will meet your requirements; (2) Your use of the Product
        will be uninterrupted, timely, secure or free from error; (3) Any
        information obtained by You as a result of your use of these Services
        will be accurate or reliable; or (4) The Product will be free of viruses
        or other components.
      </p>

      <p>
        Any purportedly applicable warranties, terms, and conditions, other than
        as appearing in the Terms, are excluded, to the fullest extent permitted
        by law. Your use of the Product is at your sole risk. The Product is
        provided on an "AS IS" and "AS AVAILABLE" basis. The Product is provided
        without warranties of any kind, whether express or implied, including,
        but not limited to, implied warranties of merchantability, fitness for a
        particular purpose, non-infringement, or course of performance.
      </p>

      <br />

      <p className={classes.indent}>
        2. <u>Shared Information</u>
      </p>
      <p>
        The Product may allow You to share and otherwise make available certain
        information, which may include personally identifiable information of
        and about users of the Product, text, other material ("Shared
        Information"). You represent that You have the complete and unencumbered
        right to share and otherwise make available any Shared Information and
        that the Shared Information and the sharing and making it available is
        legal. You further represent that: (i) You own the Shared Information,
        or You have the right to use it and to grant us any rights and license
        to it, and (ii) the sharing or use of the Shared Information on or
        through the Product does not violate the privacy rights, publicity
        rights, copyrights, contract rights, or any other rights of any person
        or organization or party. We may block or remove any Shared Information
        that We determine in Our sole discretion to be: (a) abusive, defamatory,
        or obscene; (b) fraudulent, deceptive, or misleading; (c) in violation
        of a copyright, trademark or, other intellectual property right of
        another or; (d) offensive or otherwise unacceptable to Us.
      </p>

      <br />

      <p className={classes.indent}>
        3. <u>Accounts</u>
      </p>
      <p>
        If You create an account with Us for any reason, including to use or
        receive the Product, You shall provide Us information that is accurate,
        complete, and current at all times. You shall notify Us immediately upon
        becoming aware of any breach of security or unauthorized use of any
        account owned by You.
      </p>

      <p>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than You without
        appropriate authorization, or a name that is otherwise offensive, vulgar
        or obscene.
      </p>

      <br />

      <p className={classes.indent}>
        4. <u>Intellectual Property</u>
      </p>
      <p>
        The Product and all of its components and contents, visible or not
        visible, including but not limited to text, images, graphics, or code
        are the property of Cornerstone and/or its suppliers and are protected
        by copyright, trademarks, database and other intellectual property
        rights. To the extent You are otherwise permitted, You may display and
        copy, download, or print portions of any information gained from the
        Product, directly or indirectly, only for Your own non-commercial use.
        The Terms do not grant You a license to use any trademark of
        Cornerstone. You shall not use, change, or delete any proprietary
        notices arising out of or related to the Product.
      </p>

      <br />

      <p className={classes.indent}>
        5. <u>External Links</u>
      </p>
      <p>
        The Product may contain information provided by licensors and/or other
        suppliers to Cornerstone and links to third-party websites or services
        that are not owned or controlled by Cornerstone. Cornerstone assumes no
        responsibility for the content, privacy policies, or practices of any
        third-party or its websites or services. You further acknowledge and
        agree that You will not hold Cornerstone liable, directly or indirectly,
        for any damage or loss caused or alleged to be caused by or in
        connection with use of or reliance on any such content, goods, or
        services available on or through any such websites or services.
      </p>

      <br />

      <p className={classes.indent}>
        6. <u>Termination</u>
      </p>
      <p>
        We may terminate or suspend access to the Product immediately, without
        prior notice or liability, for any reason whatsoever, including, without
        limitation, if You breach the Terms. All provisions of the Terms shall
        survive termination, including, without limitation, ownership
        provisions, warranty disclaimers, indemnity and limitations of
        liability. Upon termination, Your right to use the Service will
        immediately cease.
      </p>

      <br />

      <p className={classes.indent}>
        7. <u>Indemnification and Limitation of Liability</u>
      </p>
      <p>
        You shall indemnify, defend and hold harmless Cornerstone, its
        principals, officers, directors, representatives, employees,
        contractors, licensors, licensees, suppliers, vendors and agents from
        and against any claims, losses, damages, obligations, costs, actions or
        demands arising out of or related to the Product.
      </p>

      <p>
        Neither Cornerstone nor its directors, employees, partners, agents,
        suppliers, or affiliates, shall be liable for any loss or damage, direct
        or indirect, incidental, special, consequential or punitive damages,
        including without limitation, economic loss, loss or damage to
        electronic media or data, goodwill, or other intangible losses,
        resulting from (i) Your access to or use of the Product; (ii) Your
        inability to access or use the Product; (iii) any conduct or content of
        any third-party on or related to the Product; (iv) any content obtained
        from or through the Product; and (v) the unauthorized access to, use of,
        or alteration of Your transmissions or content, whether based on
        warranty, contract, tort (including negligence) or any other claim in
        law, whether or not we have been informed of the possibility of such
        damage, and even if a remedy set forth herein is found to have failed of
        its essential purpose.
      </p>

      <p>
        Cornerstone shall not be liable for any personal injuries, death,
        property damage, or other damages or expenses resulting from using the
        Product or any of it. In no event shall Cornerstone be liable for any
        direct, indirect, punitive, incidental, special, or consequential
        damages arising out of, or in any way connected with, your access to,
        display of, or use of the Product or with the delay or inability to
        access, display or use of the Product.
      </p>

      <br />

      <p className={classes.indent}>
        8. <u>Copyright and Trademark Notices</u>
      </p>
      <p>
        All contents of the Product are © 2021 Cornerstone Information Systems,
        Inc. or its suppliers, and all provided logos of Cornerstone and its
        suppliers are either registered trademarks or trademarks of Cornerstone
        Information Systems, Inc. or its suppliers.
      </p>

      <br />

      <p className={classes.indent}>
        9. <u>Prohibited Activities</u>
      </p>
      <p>
        The content and information in the Product is proprietary to Us or our
        suppliers and providers. While You may make limited copies of your
        travel information (and related documents) for travel, You may not
        otherwise modify, copy, distribute, transmit, display, perform,
        reproduce, publish, license, create derivative works from, transfer, or
        sell or re-sell any information, software, products, or services
        obtained from our Product. Additionally, You shall not use the Product
        or any of its contents for any commercial purpose without explicit
        authorization from Cornerstone.
      </p>

      <br />

      <p className={classes.indent}>
        10. <u>Governing Law and Severability</u>
      </p>
      <p>
        The Terms shall be governed by and interpreted and enforced in
        accordance with the laws in the State of Indiana, United States. If any
        provision of the Terms is held to be invalid or unenforceable by a court
        of competent jurisdiction, then any remaining provisions of the Terms
        shall remain in effect.
      </p>

      <br />

      <p>
        If You have any questions about these Terms, please contact us.
        <br />
        Please dial +1 (888) 778-0897. Select option 3 for Customer Support.
        <br />
        If you are located in Europe, Asia, Australia, Latin America or another
        part of the world, please dial +1 (812) 330-4361. Select option 3 for
        Customer Support.
        <br />
        If you call outside of business hours in Eastern Standard Time
        (1200-2100 GMT), please follow the directions for escalation on the
        phone menu.
        <br />
        Requests for support or additional questions can always be sent to{' '}
        <a href='mailto:support@ciswired.com'>support@ciswired.com</a>.
      </p>

      <p>Version 1.0 19 February 2021</p>

      <p>
        © 2021 Cornerstone Information Systems, Inc. All rights reserved.
        <br />
        Cornerstone Information Systems, Inc.
        <br />
        304 W. Kirkwood Ave. Suite 101
        <br />
        Bloomington, IN 47404 USA
        <br />
        <a href='ciswired.com' target='_BLANK'>
          www.ciswired.com
        </a>
        <br />
      </p>
    </>
  );
}
