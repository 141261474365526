import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  TablePagination,
  // IconButton,
} from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { makeStyles, styled } from '@mui/styles';
import { styled } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   editCell: {
//     display: 'flex',
//   },
// }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const UserTableView = ({ resp, columns, data, children }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const editRow = (row) => {
  //   console.log(row);
  // };

  // const deleteRow = (row) => {
  //   console.log(row);
  // };

  function getMyLabel(val) {
    if (
      resp.schema[val] != null &&
      resp.schema[val].name != null &&
      resp.schema[val].name !== ''
    ) {
      return resp.schema[val].name;
    }
    return val;
  }

  const isColumnUsed = (column) => {
    for (let item of data) {
      if (item[column]) {
        return true;
      }
    }
    return false;
  };

  const usedColumns =
    data != null && data.length > 0
      ? Object.keys(data[0]).filter(isColumnUsed)
      : [];

  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {/* <TableCell>Actions</TableCell> */}
              {usedColumns.map((column) => (
                <TableCell key={column}>{getMyLabel(column)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => (
                <StyledTableRow key={row?.key_value}>
                  {/* <TableCell
                  component='th'
                  scope='row'
                  className={classes.editCell}
                >
                  <IconButton onClick={() => editRow(row)} disabled>
                    <EditIcon></EditIcon>
                  </IconButton>
                  <IconButton onClick={() => deleteRow(row)} disabled>
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                </TableCell> */}
                  {usedColumns.map((column) => (
                    <TableCell component='th' scope='row' key={column}>
                      {row[column]}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={data?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default UserTableView;
