import React from 'react';
import RootContainer from '../../components/RootContainer';
import { useAuthContext } from '../../utils/auth/hooks';
import { Card, IconButton, Tooltip, Typography, Button } from '@mui/material';
import { ExitToApp as ExitToAppIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageTitle from '../../components/PageTitle';
import { CIS_INTERNAL, IBANK_URL } from '../../utils/auth/constants';
import IBankUserSelect from '../../components/IBankUserSelect';
import { Form, Formik } from 'formik';
import { useLog } from '../../utils/hooks';

const useStyles = makeStyles((theme) => ({
  cardBox: { width: '100%', display: 'flex', flexWrap: 'wrap' },
  card: {
    width: '200px',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export function IBank() {
  const { cognitoUser, user, roleIsAtLeast } = useAuthContext();

  const classes = useStyles();

  const { log, status } = useLog('IBANK');

  const handleInternalUserSelect = (values) => {
    if (!!values?.user) {
      log('ERROR', `accessed user ${values.user.email}`);
      window.open(
        `${IBANK_URL}/loginCognitoSSO.cfm?id_token=${cognitoUser?.signInUserSession?.idToken?.jwtToken}&name=${values.user.id}&agency=${values.user.agency}`,
        '_blank'
      );
    }
  };

  return (
    <RootContainer>
      <PageTitle
        title='iBank Users'
        subtitle='Choose a user to login to iBank'
      />
      <Breadcrumbs trail={{ Products: null, iBank: '/iBank' }} />
      <br />

      <div className={classes.cardBox}>
        {roleIsAtLeast(CIS_INTERNAL) ? (
          <Formik
            initialValues={{ user: undefined }}
            onSubmit={handleInternalUserSelect}
          >
            {({ values, setFieldValue }) => (
              <Form style={{ width: '100%' }}>
                <IBankUserSelect
                  multiple={false}
                  name='user'
                  onChange={(e, value) => {
                    setFieldValue('user', value);
                  }}
                  style={{ marginTop: '1rem' }}
                />

                <Tooltip title='User must be enabled in iBank to login'>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={values?.user?.disabled}
                    style={{ marginTop: '1rem' }}
                  >
                    Login as User
                  </Button>
                </Tooltip>
              </Form>
            )}
          </Formik>
        ) : (
          user?.config?.iBank?.users?.map((user) => (
            <Card className={classes.card} key={`${user.agency}-${user.id}`}>
              <div>
                <Typography component='h5' variant='h5'>
                  {user.id}
                </Typography>

                <Typography component='p' variant='subtitle1'>
                  {user.agency}
                </Typography>

                <Typography component='p' variant='subtitle2'>
                  {user.disabled ? 'Disabled' : 'Enabled'}
                </Typography>
              </div>

              <div>
                <Tooltip title='User must be enabled in iBank to login'>
                  <IconButton
                    color='primary'
                    disabled={user.disabled}
                    onClick={() => {
                      window.open(
                        `${IBANK_URL}/loginCognitoSSO.cfm?id_token=${cognitoUser?.signInUserSession?.idToken?.jwtToken}&name=${user.id}&agency=${user.agency}`,
                        '_blank'
                      );
                    }}
                  >
                    <ExitToAppIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Card>
          ))
        )}
      </div>
    </RootContainer>
  );
}
