import React, { useState, useEffect, memo, useRef, useCallback } from 'react';
import { Button, FormControlLabel, Checkbox, Popover } from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterAlt';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  itemGroup: {
    fontSize: '.9rem',
    fontWeight: 'bold',
    marginBottom: '.5rem',
  },
  itemCheckbox: {
    marginTop: '-.5rem',
    marginBottom: '.5rem',
  },
}));

/*
  TODO:
  At the moment this component is very specific to the templating filters and only supports groups of checkboxes...
  Maybe we could improve it to work for other sections of the site and be more flexible?
*/

const FilterByItem = ({ item, toggleValue }) => {
  const classes = useStyles();
  const isGroup = typeof item !== 'object';

  const handleClick = () => {
    toggleValue(item.name, !item.value);
  };

  return (
    <>
      {isGroup ? (
        <div className={classes.itemGroup}>{item}</div>
      ) : (
        <div className={classes.itemCheckbox}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                checked={item.value}
                onClick={handleClick}
              />
            }
            label={item.label}
          />
          <br />
        </div>
      )}
    </>
  );
};

const FilterBy = memo(
  ({ buttonText = 'Filter By', open = false, filters = {}, onChange }) => {
    /*
    The filters object can contain checkboxes or groups of checkboxes.

    Individual checkboxes must follow this format:
    [name of value]: { label, value } (ex: 'published': { label: 'Published', value: true } )

    Where 'name of value' is a string that will be used to identify the boolean 'value' that is displayed and returned with the onChange function provided. 'label' is the string that will be displayed in the ui next to the checkbox.

    To group a set of checkboxes with a title, simply wrap the group of checkbox objects in an object where the key value is the display text of the group, for instance:
    'Products': {
      'risk-messenger': { label: 'Risk Messenger', value: true },
      'ibank': { label: 'iBank', value: true },
    }
  */

    const filterMenuAnchorRef = useRef(null);
    const [filterMenuOpen, setFilterMenuOpen] = useState(open);
    const [values, setValues] = useState({});

    useEffect(() => {
      setFilterMenuOpen(open);
    }, [open]);

    useEffect(() => {
      //set the initial values structure

      setValues(filtersToValues(filters));
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      //update values structure if filters changed

      setValues(filtersToValues(filters));
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
      if (typeof onChange === 'function') {
        onChange(values);
      }
    }, [onChange, values]);

    const filtersToValues = (filtersToParse) => {
      let newValues = {};
      for (const key in filtersToParse) {
        if (typeof filtersToParse[key] !== 'object') {
          continue;
        }

        if (filtersToParse[key].label) {
          //is checkbox

          newValues[key] = filtersToParse[key].value === true ? true : false;
        } else {
          //is group

          newValues = { ...newValues, ...filtersToValues(filtersToParse[key]) };
        }
      }

      return newValues;
    };

    const filtersToItems = (filters) => {
      let items = [];

      for (const key in filters) {
        if (typeof filters[key] !== 'object') {
          continue;
        }

        if (filters[key]?.label) {
          //is checkbox

          items.push(
            <FilterByItem
              key={key + '-' + (values?.[key] ? 'true' : 'false')}
              item={{
                name: key,
                value: values[key],
                label: filters[key].label,
              }}
              toggleValue={toggleValue}
            />
          );
        } else {
          //is group

          items.push(<FilterByItem key={key + '-group'} item={key} />);

          items = items.concat(filtersToItems(filters[key]));
        }
      }

      return items;
    };

    const toggleValue = useCallback((name, value) => {
      setValues((prevValues) => {
        return {
          ...prevValues,
          [name]: value,
        };
      });
    }, []);

    return (
      <>
        <Button
          variant='text'
          startIcon={<FilterIcon />}
          ref={filterMenuAnchorRef}
          onClick={() => {
            setFilterMenuOpen(true);
          }}
        >
          {buttonText}
        </Button>
        <Popover
          open={filterMenuOpen}
          anchorEl={filterMenuAnchorRef?.current}
          onClose={() => {
            setFilterMenuOpen(false);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: '1rem' }}>{filtersToItems(filters)}</div>
        </Popover>
      </>
    );
  }
);

export default FilterBy;
