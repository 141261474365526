import React, { memo } from 'react';
import {
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  List,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Question from './Question.js';

const useStyles = makeStyles(() => ({
  listContainer: {
    '& .MuiListItem-container': {
      display: 'flex',
      '& > .MuiListItem-root': {
        paddingRight: 0,
        flex: 1,
      },
    },
  },
  headerAvatar: {
    display: 'flex',
    gap: '1rem',
    textAlign: 'center',
  },
  itemText: {
    '& > span': {
      display: 'flex',
      '& > hr': {
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, .08)',
        borderBottomWidth: 'thin',
        flex: 1,
        alignSelf: 'center',
        marginLeft: '1rem',
      },
    },
  },
  listSecondaryAction: {
    top: 0,
    right: 0,
    position: 'relative',
    transform: 'none',
    alignItems: 'center',
    display: 'flex',
    padding: '0 16px',
    textAlign: 'center',
    '& > .MuiSwitch-root': {
      marginRight: '2rem', //offset for the longer title
    },
  },
  listItemTitle: {
    fontSize: '.85rem',
    fontWeight: '500',
  },
  loadingContainer: {
    margin: '3rem 0',
    textAlign: 'center',
    '& .MuiCircularProgress-root': {
      width: '2rem',
      height: '2rem',
    },
  },
  notFoundWrapper: {
    textAlign: 'center',
    fontSize: '1.25rem',
    margin: '3rem 0',
  },
  questionHeader: {
    fontSize: '1.15rem',
    fontWeight: 500,
    padding: '1.5rem 1rem 0 1rem',
  },
}));

const QuestionList = memo(({ questions, toggleQuestionValue, retryFn }) => {
  const classes = useStyles();

  let usedHeaders = [];
  const list = questions?.map((item) => {
    let header = '';
    if (item?.header && !usedHeaders.includes(item.header)) {
      header = item.header;
      usedHeaders.push(header);
    }

    return (
      <React.Fragment key={item?.id}>
        {header && <div className={classes.questionHeader}>{header}</div>}
        <Question item={item} toggleQuestionValue={toggleQuestionValue} />
      </React.Fragment>
    );
  });

  return (
    <>
      {list?.length ? (
        <List className={classes.listContainer}>
          <ListItem>
            <ListItemAvatar className={classes.headerAvatar}>
              <span className={classes.listItemTitle}>
                Domestic
                <br />
                Enabled
              </span>
              <span className={classes.listItemTitle}>
                International
                <br />
                Enabled
              </span>
            </ListItemAvatar>
            <ListItemText />
            <ListItemSecondaryAction className={classes.listSecondaryAction}>
              <span className={classes.listItemTitle}>
                Include
                <br />
                Verbose Comments
              </span>
            </ListItemSecondaryAction>
          </ListItem>
          {list}
        </List>
      ) : (
        <div className={classes.notFoundWrapper}>
          <p>No questions could be found at this time.</p>
          {retryFn && <Button onClick={retryFn}>Retry</Button>}
        </div>
      )}
    </>
  );
});

export default QuestionList;
