import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem 2rem 4rem 2rem',
    width: '100%',
  },
}));

const RootContainer = ({ children, style = {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={style}>
      {children}
    </div>
  );
};

export default RootContainer;
