import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Alert,
  CircularProgress,
  colors,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import handlebars from 'handlebars';
import DesktopIcon from '@mui/icons-material/DesktopWindows';
import MobileIcon from '@mui/icons-material/PhoneAndroid';
import clsx from 'clsx';
import usePrevious from '../../../utils/hooks/usePrevious.js';
import { DATA_TAGS } from '../../../components/RichTextEditor/constants.js';
import { toast } from 'react-toastify';
import { useApiGet } from '../../../utils/hooks';

let formattedDataTags = {};
for (const product in DATA_TAGS) {
  if (typeof formattedDataTags[product] !== 'object') {
    formattedDataTags[product] = {};
  }

  DATA_TAGS[product].forEach((tag) => {
    formattedDataTags[product][tag?.tag] = tag?.default;
  });
}

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  displayToggle: {
    alignSelf: 'center',
  },
  previewContainer: {
    border: `2px solid ${colors.grey[300]}`,
    padding: '1rem',
    height: '560px',
    margin: '1rem',
    alignSelf: 'center',
    width: '85%',
    minWidth: '500px',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&.mobile': {
      width: '50%',
      minWidth: '320px',
    },
    '& p, & ul, & ol': {
      margin: 0,
    },
  },
  fauxLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    transition: '250ms opacity',
    opacity: 1,
    '&:hover, &:focus': {
      opacity: 0.75,
    },
  },
  loadingContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Preview = ({
  open,
  setOpen,
  handleSendTest,
  template: intialTemplate,
}) => {
  const classes = useStyles();
  const {
    error,
    isLoading: loading,
    data: template,
    fire: makeRequest,
  } = useApiGet(`/templates/${intialTemplate?.id}`);
  const [previewHtml, setPreviewHtml] = useState('');
  const [display, setDisplay] = useState('desktop');
  const prevProps = usePrevious({ open });

  const generatePreview = useCallback(() => {
    if (!open || loading) {
      return;
    }

    if (template || typeof intialTemplate?.draftContent !== 'undefined') {
      const content = template?.draftContent || intialTemplate?.draftContent;

      if (!content?.replace(/(<([^>]+)>)/gi, '')) {
        setPreviewHtml(
          '<div style="display: flex; height: 100%; width: 100%; justify-content: center; align-items: center; font-size: 1.25rem; color: grey;"><span>Your message is blank</span></div>'
        );
        return;
      }

      const compiledTemplate = handlebars.compile(content, { noEscape: true });
      setPreviewHtml(
        compiledTemplate(
          formattedDataTags[template?.product || intialTemplate?.product]
        )
      );
    }
  }, [open, loading, intialTemplate, template]);

  useEffect(() => {
    if (error) {
      toast.error('An error occurred while loading the selected template.');
      setOpen(false);
    }
  }, [error, setOpen]);

  useEffect(() => {
    if (open && !prevProps.open) {
      //just opened

      if (typeof intialTemplate?.draftContent === 'undefined') {
        //template content was not provided so we are accessing from the template list

        setPreviewHtml(''); //clear the previewHtml
        makeRequest(); //get the template data
      } else {
        //template content was provided because we are accessing from the EditTemplate component

        generatePreview();
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    generatePreview(); //piggyback off of the useCallback dependencies to call the generate function when needed
  }, [generatePreview]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      maxWidth='md'
      fullWidth
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>
        Previewing{' '}
        {template?.name || intialTemplate?.name
          ? `"${template?.name || intialTemplate?.name}"`
          : 'Template'}
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Alert severity='info'>
          All email clients display messages slightly differently so this
          preview is not 100% accurate, it is simply to show you what your data
          tags might look like. You can{' '}
          {typeof handleSendTest === 'function' ? (
            <span className={classes.fauxLink} onClick={handleSendTest}>
              send a test message
            </span>
          ) : (
            'send a test message'
          )}{' '}
          to see exactly how your message will look when received.
        </Alert>
        <br />
        <ToggleButtonGroup
          value={display}
          onChange={(e, value) => {
            setDisplay(value);
          }}
          exclusive
          className={classes.displayToggle}
        >
          <ToggleButton value='desktop'>
            <Tooltip title='Desktop' disableInteractive>
              <DesktopIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value='mobile'>
            <Tooltip title='Mobile' disableInteractive>
              <MobileIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        {loading ? (
          <div className={clsx(classes.previewContainer, display)}>
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          </div>
        ) : (
          <div
            className={clsx(classes.previewContainer, display)}
            dangerouslySetInnerHTML={{ __html: previewHtml || '' }}
          />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Preview;
