import React from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PickerBase from './PickerBase.js';

const useStyles = makeStyles((theme) => ({
  colorSwatchesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  swatch: {
    height: '30px',
    width: '30px',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    outline: 'none',
    borderRadius: '4px',
    margin: '0px 6px 6px 0px',
    border: '2px solid white',
    '&:not(:hover):not(:focus)': {
      boxShadow: 'none !important',
    },
  },
}));

const ColorPicker = (props) => {
  const { colors, value, onChange, onClose } = props;
  const classes = useStyles();

  const swatches =
    colors &&
    Object.keys(colors).map((c) => {
      return (
        <Tooltip key={c} title={colors[c]?.title} disableInteractive>
          <div
            className={classes.swatch}
            style={{
              backgroundColor: colors[c]?.color,
              boxShadow: `0px 0px 0px 2px ${colors[c]?.color}`,
            }}
            tabIndex='0'
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={() => {
              if (typeof onChange === 'function') {
                onChange(c);
              }

              if (typeof onClose === 'function') {
                onClose();
              }
            }}
          >
            {c === value && <CheckIcon style={{ color: 'white' }} />}
          </div>
        </Tooltip>
      );
    });

  return (
    <PickerBase {...props} width={props?.width || 284}>
      <div className={classes.colorSwatchesContainer}>{swatches}</div>
    </PickerBase>
  );
};

export default ColorPicker;
