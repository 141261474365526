import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: '1rem',
  },
}));

const ConfirmDialog = forwardRef(({ ...props }, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  useImperativeHandle(ref, () => ({
    //this exposes these methods to the parent ref

    open(newData) {
      setData(newData);
      setOpen(true);
    },
  }));

  const { title, message, confirmText, onCancel, onConfirm } = data;

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullWidth
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>{title || 'Confirm'}</DialogTitle>
      <DialogContent>
        <Typography>
          {message || 'Are you sure you want to perform this action?'}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color='default'
          variant='outlined'
          onClick={() => {
            if (typeof onCancel === 'function') {
              onCancel();
            }
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            if (typeof onConfirm === 'function') {
              onConfirm();
            }
            setOpen(false);
          }}
        >
          {confirmText || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmDialog;
