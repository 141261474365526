import { FormControlLabel, Switch } from '@mui/material';
import Axios from 'axios';
import Loader from '../../../components/Loader';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useAuthContext } from '../../../utils/auth/hooks';

// move into own directory ??
const API_INVOKE_URL = process.env.REACT_APP_INVOKE_URL;
const toggleStatus = (user, headers) => {
  const params = { enabled: !user.enabled };

  return Axios.put(`${API_INVOKE_URL}/users/${user.id}`, params, {
    headers: headers,
  });
};

export default function UserStatus({ user }) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { sso, logout } = useAuthContext();
  // we should convert this to the useApiRequest hook
  const mutation = useMutation(async () => {
    let headers = {};
    try {
      const { idToken } = await Auth.currentSession();
      headers = {
        Authorization: 'Bearer ' + idToken.jwtToken,
      };
    } catch (e) {
      headers = {};
      toast.error(
        'Your session has expired. Please sign in again to continue.'
      );
      logout();
      if (sso) {
        history.push('/access-denied');
      } else {
        history.push('/login');
      }
    }

    return (
      // eslint-disable-next-line
      toggleStatus(user, headers),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['editUser', user.id]);
        },
      }
    );
  });
  return (
    <>
      {mutation.isLoading ? (
        <Loader color='inherit' size={20} />
      ) : (
        <FormControlLabel
          value={user?.enabled}
          control={
            <Switch
              checked={user?.enabled}
              onChange={mutation.mutate}
              disabled={mutation.isLoading}
              color='primary'
            />
          }
          label={user?.enabled ? 'Enable' : 'Disable'}
          labelPlacement='end'
        />
      )}
    </>
  );
}
