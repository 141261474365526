import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Link,
  Button,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Avatar,
} from '@mui/material';
import { useAuthContext } from '../../utils/auth/hooks';
import { makeStyles } from '@mui/styles';
import { ADMIN, CIS_INTERNAL } from '../../utils/auth/constants';
import { ExitToApp, KeyboardArrowDown, Settings } from '@mui/icons-material';
import { generateColor } from '../../utils/colors';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../Logo';

const useStyles = makeStyles((theme) => ({
  navContentContainer: {
    height: '100%',
  },
  menuButton: {
    display: 'inline-flex',
    width: 'auto',
    alignItems: 'center',
    paddingRight: '1rem',
    height: '100%',
    textTransform: 'capitalize',
    '& > *': {
      margin: '8px',
    },
  },
  dropdownContent: {
    backgroundColor: '#fff',
    minWidth: '100px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 40px',
    cursor: 'pointer',
    borderRadius: '2px',
    '& svg': {
      marginRight: '6px',
    },
  },
  menuLabel: {
    margin: '4px',
    cursor: 'default',
    textTransform: 'uppercase',
    fontSize: '0.7rem',
    color: theme.palette.grey.lighter,
    fontWeight: 'bold',
  },
}));

function HeaderContent(props) {
  const { isMobile, toggleMobileMenu, logo } = props;
  const history = useHistory();
  const classes = useStyles();
  const {
    logout,
    user,
    isImpersonating,
    clearImpersonatedUser,
    roleIsAtLeast,
  } = useAuthContext();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const editAccount = () => {
    history.push({
      pathname: `/user/me`,
      state: { initialUser: user },
    });
  };

  const goToUsers = () => {
    closeMenu();
    history.push({
      pathname: `/users`,
    });
  };

  const goToOrganization = () => {
    closeMenu();
    history.push({
      pathname: `/organizations`,
    });
  };

  const goToConfig = () => {
    closeMenu();
    history.push({
      pathname: '/hub-configuration',
    });
  };

  const changePasswordRedirect = () => {
    history.push('/change-password');
  };

  const logoutClick = () => {
    logout();
    history.push('/login');
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      {isMobile ? (
        <Button onClick={toggleMobileMenu(true)}>
          <MenuIcon />
        </Button>
      ) : (
        <Link component={RouterLink} to='/'>
          <Logo logo={logo} />
        </Link>
      )}
      <div style={{ flex: 1 }} />
      <div className={classes.navContentContainer}>
        <Button
          className={classes.menuButton}
          onClick={handleOpenMenu}
          color='grey'
        >
          <Avatar style={{ backgroundColor: generateColor(user?.lastName) }}>
            {user?.firstName && user?.firstName[0]}
            {user?.lastName && user?.lastName[0]}
          </Avatar>
          {isImpersonating && 'Impersonating'} {user?.firstName}{' '}
          {user?.lastName}
          <Settings />
          <KeyboardArrowDown />
        </Button>
        <Menu
          id='account-menu'
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={closeMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          {roleIsAtLeast(ADMIN) && (
            <p className={classes.menuLabel}>Account Settings</p>
          )}
          {roleIsAtLeast(CIS_INTERNAL) && (
            <MenuItem onClick={goToOrganization}>Organizations</MenuItem>
          )}
          {roleIsAtLeast(ADMIN) && (
            <MenuItem onClick={goToUsers}>Users</MenuItem>
          )}
          {roleIsAtLeast(ADMIN) && (
            <MenuItem onClick={goToConfig}>Hub Configuration</MenuItem>
          )}
          {roleIsAtLeast(ADMIN) && <Divider />}
          {isImpersonating && (
            <MenuItem onClick={() => clearImpersonatedUser()}>
              Stop Impersonating User
            </MenuItem>
          )}
          <p className={classes.menuLabel}>Personal Settings</p>
          <MenuItem onClick={editAccount}>My Profile</MenuItem>
          <MenuItem onClick={changePasswordRedirect}>Change Password</MenuItem>
          <Divider />
          <MenuItem onClick={logoutClick}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText>Log Out</ListItemText>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}

export default HeaderContent;
