import React from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import { useAuthContext } from './../auth/hooks';
import Route from './Route.js';
import { AccessDenied } from '../../pages';
import { toast } from 'react-toastify';

const PrivateRoute = (props) => {
  const { component, componentProps, noLayout, role, product, ...rest } = props;
  const {
    isLoading,
    isAuthenticated,
    roleIsAtLeast,
    hasProduct,
    hasAuths,
    sso,
  } = useAuthContext();
  const history = useHistory();

  let routeComponent = component;

  if (!isLoading && !isAuthenticated) {
    if (sso) {
      history.push('/access-denied');
    } else {
      history.push('/login');
    }
    toast.error('Authentication failure. Please login to continue.');
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && isAuthenticated) {
    // allowed if role isn't defined OR roleIsAtLeast
    const roleAllowed = !role || roleIsAtLeast(role);
    // allowed if product isn't defined OR hasProduct
    const productAllowed = !product || hasProduct(product);

    const isAllowed = roleAllowed && productAllowed;
    if (!isAllowed) {
      if (hasAuths()) {
        history.push('/authorizations');
        return null;
      }
      // should we pass roleAllowed & product allowed to accessDenied?
      routeComponent = AccessDenied; //don't redirect but instead render the AccessDenied page so the url/history stays the same
    }
  }

  return (
    <Route
      component={routeComponent}
      {...rest}
      {...componentProps}
      noLayout={noLayout}
    />
  );
};

export default PrivateRoute;
