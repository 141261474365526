import React, { useRef, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useApiGet } from '../utils/hooks';
import { toast } from 'react-toastify';

export default function IBankAgencySelect({
  multiple,
  limitTags,
  name,
  value,
  onChange,
  style,
  className,
}) {
  const queryInputRef = useRef(null);
  const {
    isLoading: loading,
    data: iBankAgencyList,
    error,
  } = useApiGet('/ibank/agencies', { fireOnMount: true });

  useEffect(() => {
    if (error) {
      toast.error(
        typeof error === 'string' ? error : 'An unexpected error occurred.'
      );
    }
  }, [error]);

  return (
    <Autocomplete
      multiple={!!multiple}
      limitTags={isNaN(limitTags) ? 4 : limitTags}
      name={name}
      options={iBankAgencyList || []}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          label='iBank Agencies'
          placeholder='Search available iBank agencies'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputRef={queryInputRef}
        />
      )}
      value={value}
      onChange={onChange}
      filterSelectedOptions={true}
      noOptionsText={
        queryInputRef?.current?.value
          ? 'No agencies match your search'
          : 'Type to search agencies'
      }
      loading={loading}
      className={className}
      style={style}
    />
  );
}
