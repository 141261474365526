import { createContext, useContext, useState, useEffect } from 'react';
import { useApiGet } from '../../utils/hooks';
import { toast } from 'react-toastify';
import { fireEvent } from '@testing-library/react';

export const TemplateContext = createContext();

//TODO: move the selected template for editing from EditTemplate to this context so it can be accessed and updated easier from children

export function useTemplateStore() {
  const {
    isLoading: loading,
    data,
    error,
  } = useApiGet('/templates', { fireOnMount: true });
  const {
    fire: refreshActiveRiskMessengerTemplate,
    data: activeRiskMessengerTemplate,
  } = useApiGet('/templates/active/risk-messenger', { fireOnMount: true });
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (error) {
      toast.error(
        typeof error === 'string' ? error : 'An unexpected error occurred.'
      );
    }
  }, [error]);

  useEffect(() => {
    setTemplates(data || []);
  }, [data, setTemplates]);

  return {
    templates,
    setTemplates,
    loadingTemplates: loading,
    activeRiskMessengerTemplate,
    refreshActiveRiskMessengerTemplate,
  };
}

export function useTemplateContext() {
  return useContext(TemplateContext);
}
