import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RecordsAdded = (props) => (
  <SvgIcon {...props} width='40px' height='40px' viewBox='0 0 40 40'>
    <title>Records-Added</title>
    <g
      id='User-Tables'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Workflow---User-Table-Upload-1.5.1'
        transform='translate(-807.000000, -458.000000)'
      >
        <g id='Content' transform='translate(234.000000, 166.000000)'>
          <g id='Upload-Preview' transform='translate(548.000000, 74.000000)'>
            <g id='Content' transform='translate(20.000000, 13.000000)'>
              <g id='Icons-v3' transform='translate(5.000000, 37.000000)'>
                <g
                  id='Records-Added'
                  transform='translate(0.000000, 168.000000)'
                >
                  <g id='note-remove' transform='translate(5.692308, 5.692308)'>
                    <g
                      id='Group'
                      transform='translate(5.873933, 4.724730) rotate(45.000000) translate(-5.873933, -4.724730) translate(2.833903, 1.682700)'
                      fill='#4CAF50'
                      fillRule='nonzero'
                    >
                      <path
                        d='M4.61011494,5.78484746 L0.295211344,1.46994397 C-0.0384745489,1.13625808 -0.0384745489,0.58395032 0.295211344,0.250264428 C0.628897236,-0.0834214645 1.18120486,-0.0834214645 1.51489075,0.250264428 L5.82979493,4.5651683 C6.16348082,4.89885419 6.16348082,5.45116157 5.82979493,5.78484746 C5.65719877,5.94593721 5.43857673,6.0379888 5.21995493,6.0379888 C5.00133314,6.0379888 4.7827111,5.95744363 4.61011494,5.78484746 Z'
                        id='Vector'
                      ></path>
                      <path
                        d='M0.250264428,5.8309186 C-0.0834214645,5.49723271 -0.0834214645,4.94492451 0.250264428,4.61123861 L4.56516803,0.296335017 C4.89885392,-0.037350876 5.45116157,-0.037350876 5.78484746,0.296335017 C6.11853336,0.630020909 6.11853336,1.18232853 5.78484746,1.51601442 L1.46994383,5.8309186 C1.30885409,6.00351476 1.07872591,6.08405939 0.860104113,6.08405939 C0.641482318,6.08405939 0.422860591,6.00351476 0.250264428,5.8309186 Z'
                        id='Vector'
                      ></path>
                    </g>
                    <path
                      d='M8.05448729,16.9719554 C7.58272447,16.9719554 7.19150651,16.5807374 7.19150651,16.1089746 C7.19150651,15.6372118 7.58272447,15.2459938 8.05448729,15.2459938 L13.8076925,15.2459938 C14.2794553,15.2459938 14.6706733,15.6372118 14.6706733,16.1089746 C14.6706733,16.5807374 14.2794553,16.9719554 13.8076925,16.9719554 L8.05448729,16.9719554 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M8.05448729,12.3693912 C7.58272447,12.3693912 7.19150651,11.9781732 7.19150651,11.5064104 C7.19150651,11.0346476 7.58272447,10.6434296 8.05448729,10.6434296 L17.2596156,10.6434296 C17.7313784,10.6434296 18.1225964,11.0346476 18.1225964,11.5064104 C18.1225964,11.9781732 17.7313784,12.3693912 17.2596156,12.3693912 L8.05448729,12.3693912 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M23.3004811,18.4102567 L23.3004811,9.19362166 C23.3004811,4.32641003 21.6435579,3.33685903 18.3642311,3.16426286 L11.5064104,3.16426286 C11.0346476,3.16426286 10.6434296,2.77304491 10.6434296,2.30128208 C10.6434296,1.82951926 11.0346476,1.4383013 11.5064104,1.4383013 L18.4102567,1.4383013 C23.1278848,1.69144233 25.0264427,3.9351919 25.0264427,9.19362166 L25.0264427,18.4102567 C25.0264427,18.8820195 24.6352247,19.2732374 24.1634619,19.2732374 C23.6916991,19.2732374 23.3004811,18.8820195 23.3004811,18.4102567 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M10.3557694,26.1768138 C3.88916685,26.1768138 2.58894234,23.1966527 2.58894234,18.3869734 L2.58894234,10.3670061 C2.58894234,9.89524328 2.9801603,9.50402532 3.45192312,9.50402532 C3.92368595,9.50402532 4.31490391,9.89524328 4.31490391,10.3670061 L4.31490391,18.3869734 C4.31490391,22.6673581 5.15487189,24.4508522 10.3557694,24.4508522 L17.2596156,24.4508522 C17.7313784,24.4508522 18.1225964,24.8420701 18.1225964,25.313833 C18.1225964,25.7855958 17.7313784,26.1768138 17.2596156,26.1768138 L10.3557694,26.1768138 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M16.9259298,26.1080458 C16.6037503,25.9699689 16.3966348,25.6592952 16.3966348,25.3141029 L16.3966348,21.8621798 C16.3966348,19.0776284 17.9269873,17.5472759 20.7115388,17.5472759 L24.1634619,17.5472759 C24.5086542,17.5472759 24.8308343,17.7543913 24.9574048,18.0765708 C25.0839753,18.3987503 25.014937,18.7669562 24.7733024,19.0200972 L17.8694551,25.9239435 C17.7083653,26.0850332 17.4782374,26.1770837 17.2596156,26.1770837 C17.1445515,26.1770837 17.0409939,26.1540715 16.9259298,26.1080458 Z M18.1225964,21.8621798 L18.1225964,23.2314432 L22.0808022,19.2732374 L20.7115388,19.2732374 C18.8935259,19.2732374 18.1225964,20.0441669 18.1225964,21.8621798 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                    ></path>
                    <path
                      d='M27.615385,0 L27.615385,27.615385 L0,27.615385 L0,0 L27.615385,0 Z'
                      id='Vector'
                      opacity='0'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default RecordsAdded;
