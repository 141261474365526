import React, { useState, memo, useCallback } from 'react';
import ExtendedRichUtils from './../utils/ExtendedRichUtils.js';
import {
  Divider,
  ToggleButton,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import HorizontalRuleIcon from '@mui/icons-material/Remove';
import DataTagIcon from '@mui/icons-material/Label';
import { makeStyles } from '@mui/styles';
import {
  ColorPicker,
  FormatColorTextIcon,
  LinkPicker,
  DataTagPicker,
} from './index';
import 'draft-js/dist/Draft.css';
import {
  DATA_TAGS,
  FONT_COLORS,
  FONT_SIZES,
  DEFAULT_FONT_SIZE,
} from './../constants';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '.25rem',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      margin: '0 .5rem',
      '& .MuiInputBase-input.MuiSelect-select': {
        padding: '8px 32px 8px 8px',
      },
    },
    '& > hr.MuiDivider-vertical:first-child, & > hr.MuiDivider-vertical:last-child, & > hr.MuiDivider-vertical + hr.MuiDivider-vertical':
      {
        display:
          'none' /* hide unwanted vertical dividers when options are toggled */,
      },
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
}));

const ToggleWithTooltip = memo(
  ({
    tooltip,
    show,
    picker,
    icon,
    size = 'small',
    style = {},
    ...childProps
  }) => {
    /*
    Have to wrap the Tooltip/ToggleButton combo or the buttons won't show their value properly in the ToggleButtonGroup since they have to be a direct descendant
  */

    if (show === false) {
      return null;
    }

    return (
      <div style={{ position: 'relative' }}>
        <Tooltip title={tooltip} disableInteractive>
          <div>
            {/* the extra div wrapper lets the tooltip work even if the ToggleButton is disabled */}
            <ToggleButton
              aria-label={tooltip.toLowerCase()}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              size={size}
              style={{ border: 0, margin: '.25rem', ...style }}
              {...childProps}
            >
              {icon}
            </ToggleButton>
          </div>
        </Tooltip>
        {picker}
      </div>
    );
  }
);

const SelectWithTooltip = memo(({ tooltip, show, children, ...childProps }) => {
  /*
      Have to wrap the Tooltip/Select combo to control the tooltip state and hide it when the dropdown is open
    */

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);

  if (show === false) {
    return undefined;
  }

  const _MD = (e) => {
    setTooltipOpen(false);
    e.preventDefault(); // this function saves a few characters on all the onMouseDown events
  };

  const handleTooltipOpen = () => {
    if (!selectOpen) {
      setTooltipOpen(true);
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
    setTooltipOpen(false);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
    setTooltipOpen(false);
  };

  return (
    <Tooltip title={tooltip} open={tooltipOpen} disableInteractive>
      <FormControl variant='outlined' onMouseDown={_MD}>
        <Select
          onMouseEnter={handleTooltipOpen}
          onFocus={handleTooltipOpen}
          onMouseLeave={handleTooltipClose}
          onBlur={handleTooltipClose}
          onOpen={handleSelectOpen}
          onClose={handleSelectClose}
          onMouseDown={_MD}
          {...childProps}
        >
          {children}
        </Select>
      </FormControl>
    </Tooltip>
  );
});

const Toolbar = ({
  editorState,
  handleCatchall,
  options,
  defaultFontSizeOverride,
  toolbarSize,
}) => {
  /*
    This toolbar has a pretty big render statement but converting it to some sort of dynamic array-based render was taking a lot of time
    and was becoming a big spaghetti mess in order to handle all of the different scenarios. So for now it is staying like this...
  */

  const classes = useStyles();
  const [showPicker, setShowPicker] = useState({
    color: false,
    link: false,
    tag: false,
  });
  const currentStyle = editorState?.getCurrentInlineStyle();
  const selection = editorState?.getSelection();
  const blockType = editorState
    ?.getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const alignType = editorState
    ? ExtendedRichUtils.getAlignmentType(editorState)
    : '';
  const fontSize = currentStyle?.find((style) => style?.includes('fontsize-'));
  const fontColor = currentStyle
    ?.find((style) => style?.includes('color-'))
    ?.replace('color-', '');

  const _MD = (e) => {
    e.preventDefault(); // this function saves a few characters on all the onMouseDown events
  };

  const handle = useCallback(
    (type, value) => {
      handleCatchall(type, editorState, value);
    },
    [editorState, handleCatchall]
  );

  const togglePicker = useCallback((picker, show) => {
    setShowPicker((prev) => {
      let next = {};
      for (const key in prev) {
        next[key] =
          key === picker
            ? typeof show === 'boolean'
              ? !!show
              : !prev[key]
            : false;
      }
      return next;
    });
  }, []);

  return (
    <div className={classes.toolbar}>
      <ToggleWithTooltip
        tooltip='Undo'
        value='UNDO'
        onClick={() => {
          handle('undo');
        }}
        icon={<UndoIcon fontSize={toolbarSize} />}
        show={options?.UNDO}
        disabled={!editorState?.getUndoStack().size}
      />
      <ToggleWithTooltip
        tooltip='Redo'
        value='REDO'
        onClick={() => {
          handle('redo');
        }}
        icon={<RedoIcon fontSize={toolbarSize} />}
        show={options?.REDO}
        disabled={!editorState?.getRedoStack()?.size}
      />
      <Divider flexItem orientation='vertical' className={classes.divider} />
      <ToggleWithTooltip
        tooltip='Align Left'
        value='LEFT'
        onClick={(e, value) => {
          handle('align', value);
        }}
        icon={<FormatAlignLeftIcon fontSize={toolbarSize} />}
        show={options?.ALIGN_LEFT}
        selected={alignType === 'LEFT'}
      />
      <ToggleWithTooltip
        tooltip='Align Center'
        value='CENTER'
        onClick={(e, value) => {
          handle('align', value);
        }}
        icon={<FormatAlignCenterIcon fontSize={toolbarSize} />}
        show={options?.ALIGN_CENTER}
        selected={alignType === 'CENTER'}
      />
      <ToggleWithTooltip
        tooltip='Align Right'
        value='RIGHT'
        onClick={(e, value) => {
          handle('align', value);
        }}
        icon={<FormatAlignRightIcon fontSize={toolbarSize} />}
        show={options?.ALIGN_RIGHT}
        selected={alignType === 'RIGHT'}
      />
      <ToggleWithTooltip
        tooltip='Align Justify'
        value='JUSTIFY'
        onClick={(e, value) => {
          handle('align', value);
        }}
        icon={<FormatAlignJustifyIcon fontSize={toolbarSize} />}
        show={options?.ALIGN_JUSTIFY}
        selected={alignType === 'JUSTIFY'}
      />
      <Divider flexItem orientation='vertical' className={classes.divider} />
      <ToggleWithTooltip
        tooltip='Bold'
        value='BOLD'
        onClick={(e, value) => {
          handle('format', value);
        }}
        icon={<FormatBoldIcon fontSize={toolbarSize} />}
        show={options?.BOLD}
        selected={currentStyle?.has('BOLD')}
      />
      <ToggleWithTooltip
        tooltip='Italic'
        value='ITALIC'
        onClick={(e, value) => {
          handle('format', value);
        }}
        icon={<FormatItalicIcon fontSize={toolbarSize} />}
        show={options?.ITALIC}
        selected={currentStyle?.has('ITALIC')}
      />
      <ToggleWithTooltip
        tooltip='Underline'
        value='UNDERLINE'
        onClick={(e, value) => {
          handle('format', value);
        }}
        icon={<FormatUnderlinedIcon fontSize={toolbarSize} />}
        show={options?.UNDERLINE}
        selected={currentStyle?.has('UNDERLINE')}
      />
      <ToggleWithTooltip
        tooltip='Strikethrough'
        value='STRIKETHROUGH'
        onClick={(e, value) => {
          handle('format', value);
        }}
        icon={<FormatStrikethroughIcon fontSize={toolbarSize} />}
        show={options?.STRIKETHROUGH}
        selected={currentStyle?.has('STRIKETHROUGH')}
      />
      <SelectWithTooltip
        tooltip='Font Size'
        value={
          fontSize || `fontsize-${defaultFontSizeOverride || DEFAULT_FONT_SIZE}`
        }
        onChange={(e, value) => {
          if (React.isValidElement(value)) {
            value = e?.target?.value;
          }
          setTimeout(() => {
            handle('fontSize', value?.split('-')[1] || DEFAULT_FONT_SIZE);
          }, 1);
        }}
        show={options?.FONT_SIZE}
      >
        {FONT_SIZES.map((size) => (
          <MenuItem key={size} value={`fontsize-${size}`} onMouseDown={_MD}>
            {size}
          </MenuItem>
        ))}
      </SelectWithTooltip>
      <ToggleWithTooltip
        tooltip='Font Color'
        value='NOVALUE'
        onMouseDown={(e) => {
          //stop propagation to the color pickers's outside click handler
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          _MD(e);
        }}
        onClick={() => {
          togglePicker('color', true);
        }}
        icon={
          <FormatColorTextIcon
            color={FONT_COLORS[fontColor || 'default']?.color}
            fontSize={toolbarSize}
          />
        }
        show={options?.FONT_COLOR}
        picker={
          <ColorPicker
            colors={FONT_COLORS}
            value={fontColor || 'default'}
            show={showPicker?.color}
            onChange={(value) => {
              handle('fontColor', value);
            }}
            onClose={() => {
              togglePicker('color', false);
            }}
          />
        }
      />
      <Divider flexItem orientation='vertical' className={classes.divider} />
      <ToggleWithTooltip
        tooltip='Insert Unordered List'
        value='unordered-list-item'
        onClick={(e, value) => {
          handle('block', value);
        }}
        icon={<FormatListBulletedIcon fontSize={toolbarSize} />}
        show={options?.UL}
        selected={blockType === 'unordered-list-item'}
      />
      <ToggleWithTooltip
        tooltip='Insert Ordered List'
        value='ordered-list-item'
        onClick={(e, value) => {
          handle('block', value);
        }}
        icon={<FormatListNumberedIcon fontSize={toolbarSize} />}
        show={options?.OL}
        selected={blockType === 'ordered-list-item'}
      />
      <ToggleWithTooltip
        tooltip='Insert Link'
        value='link'
        onMouseDown={(e) => {
          //stop propagation to the link picker's outside click handler
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          _MD(e);
        }}
        onClick={() => {
          togglePicker('link', true);
        }}
        icon={<LinkIcon fontSize={toolbarSize} />}
        show={options?.LINK}
        picker={
          <LinkPicker
            editorState={editorState}
            show={showPicker?.link}
            onChange={(title, target) => {
              handle('link', { title, target, targetOption: '_blank' });
            }}
            onClose={() => {
              togglePicker('color', false);
            }}
          />
        }
      />
      <ToggleWithTooltip
        tooltip='Remove Link'
        value='link-off'
        onClick={() => {
          handle('unlink');
        }}
        icon={<LinkOffIcon fontSize={toolbarSize} />}
        show={options?.LINK}
      />
      <ToggleWithTooltip
        tooltip='Insert Horizontal Rule'
        value='horizontal-rule'
        onClick={() => {
          handle('hr');
        }}
        icon={<HorizontalRuleIcon fontSize={toolbarSize} />}
        show={options?.HR}
      />
      <ToggleWithTooltip
        tooltip='Insert Data Tag'
        value='NOVALUE'
        onMouseDown={(e) => {
          //stop propagation to the tag picker's outside click handler
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          _MD(e);
        }}
        onClick={() => {
          togglePicker('tag', true);
        }}
        icon={<DataTagIcon fontSize={toolbarSize} />}
        show={options?.DATA_TAG}
        picker={
          <DataTagPicker
            tags={DATA_TAGS[options?.DATA_TAG]}
            show={showPicker?.tag}
            onChange={(value) => {
              handle('tag', value?.tag);
            }}
            onClose={() => {
              togglePicker('tag', false);
            }}
          />
        }
      />
    </div>
  );
};

export default Toolbar;
