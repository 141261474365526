export { default as AlphaPicker } from './components/alpha/Alpha';
export { default as BlockPicker } from './components/block/Block';
export { default as CirclePicker } from './components/circle/Circle';
import _default from './components/chrome/Chrome';
export { _default as default };
export { default as ChromePicker } from './components/chrome/Chrome';
export { default as CompactPicker } from './components/compact/Compact';
export { default as GithubPicker } from './components/github/Github';
export { default as HuePicker } from './components/hue/Hue';
export { default as MaterialPicker } from './components/material/Material';
export { default as PhotoshopPicker } from './components/photoshop/Photoshop';
export { default as SketchPicker } from './components/sketch/Sketch';
export { default as SliderPicker } from './components/slider/Slider';
export { default as SwatchesPicker } from './components/swatches/Swatches';
export { default as TwitterPicker } from './components/twitter/Twitter';
export { default as GooglePicker } from './components/google/Google';

export { default as CustomPicker } from './components/common/ColorWrap';