import React, { memo } from 'react';
import { Tab, Tooltip } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tabWrap: {
    display: 'contents',
  },
}));

const TooltipTab = memo(({ tooltip, label, disabled }) => {
  return (
    <Tab
      style={{ pointerEvents: 'auto' }}
      disabled={disabled}
      label={
        <Tooltip title={tooltip}>
          <span>{label}</span>
        </Tooltip>
      }
    />

    // <Tooltip title={tooltip}>
    //   <div className={classes.tabWrap}>
    //     <Tab {...rest}  />
    //   </div>
    // </Tooltip>
  );
});

export default TooltipTab;
