import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import jwt from 'jsonwebtoken';

export const EulaAuthContext = createContext();

export function EulaAuthProvider({ children }) {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const [eulaTokenData, setEulaTokenData] = useState(null);

  const getPublicKey = async () => {
    try {
      const res = await fetch('eula-public.key');
      if (res.ok) {
        return await res.text();
      }
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    (async function () {
      let data;

      if (token) {
        const publicKey = await getPublicKey();
        try {
          data = jwt.verify(token, publicKey, { algorithm: 'RS256' });
        } catch (e) {
          data = {
            error: true,
            statusCode: e?.name === 'TokenExpiredError' ? 410 : 500,
          };
        }
      } else {
        data = { error: true, statusCode: 401 }; //unauthorized
      }

      setEulaTokenData({
        token, //store the token with the decoded data for use later
        ...data,
      });
    })();
  }, [token]);

  return (
    <EulaAuthContext.Provider value={eulaTokenData}>
      {children}
    </EulaAuthContext.Provider>
  );
}
