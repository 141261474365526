import React from 'react';
import ErrorScreen from '../components/ErrorScreen';
import RootContainer from '../components/RootContainer';

export const AccessDenied = () => {
  return (
    <RootContainer>
      <ErrorScreen
        title='Access Denied'
        code='403'
        message='You do not have permission to view this resource'
      />
    </RootContainer>
  );
};
