import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const LineChart = ({ data }) => {
  // Element References
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);

  // Dimensions
  let dimensions = {
    width: 250,
    height: 100,
    margins: 10,
  };

  dimensions.containerWidth = dimensions.width - dimensions.margins * 2;
  dimensions.containerHeight = dimensions.height - dimensions.margins * 2;

  useEffect(() => {
    // D3 Code

    // Data
    const dataset = data;

    const yMinValue = d3.min(data, function (d) {
      return Math.floor(d.value - (d.value / 100) * 20);
    });
    const yMaxValue = d3.max(data, function (d) {
      return Math.floor(d.value + (d.value / 100) * 20);
    });

    // Accessors
    const xAccessor = (d) => d.label;
    const yAccessor = (d) => d.value;
    const labelAccessor = (d) => d.dateName;

    // Selections
    const svg = d3.select(svgRef.current).classed('line-chart-svg', true);

    // clear all previous content on refresh
    const everything = svg.selectAll('*');
    everything.remove();

    const container = svg
      .append('g')
      .classed('container', true)
      .style('transform', 'translateX(10px)');

    const tooltip = d3.select(tooltipRef.current).style('opacity', 0);
    const tooltipDot = container
      .append('circle')
      .classed('tool-tip-dot', true)
      .attr('r', 6)
      .attr('fill', '#fff')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 3)
      .style('opacity', 0)
      .style('pointer-events', 'none');

    // Scales
    const yScale = d3
      .scaleLinear()
      .domain([yMinValue, yMaxValue])
      .range([dimensions.containerHeight, 0])
      .nice();
    const xScale = d3
      .scaleLinear()
      .domain(d3.extent(dataset, xAccessor))
      .range([0, dimensions.containerWidth]);

    // Line Generator
    const lineGenerator = d3
      .line()
      .x((d) => xScale(xAccessor(d)))
      .y((d) => yScale(yAccessor(d)))
      .curve(d3.curveMonotoneX)(dataset);

    // Draw Line
    container
      .append('path')
      .datum(dataset)
      .attr('d', lineGenerator)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 3);

    // Axis
    const yAxis = d3.axisLeft(yScale);

    container
      .append('g')
      .classed('yAxis', true)
      .style('display', 'none')
      .style('transform', `translateX(${dimensions.containerWidth}px)`)
      .call(yAxis);

    const xAxis = d3.axisBottom(xScale);

    container
      .append('g')
      .classed('xAxis', true)
      .style('display', 'none')
      .style('transform', `translateY(${dimensions.containerHeight}px)`)
      .call(xAxis);

    // Tooltip
    container
      .append('rect')
      .classed('mouse-tracker', true)
      .attr('width', dimensions.width)
      .attr('height', dimensions.containerHeight)
      .style('opacity', 0)
      .on('touchmouse mousemove', function (event) {
        const mousePos = d3.pointer(event, this);

        // x coordinate stored in mousePos index 0
        const date = xScale.invert(mousePos[0]);

        // Custom Bisector - left, center, right
        const dateBisector = d3.bisector(xAccessor).center;

        const bisectionIndex = dateBisector(dataset, date);
        // math.max prevents negative index reference error
        const hoveredIndexData = dataset[Math.max(0, bisectionIndex)];

        // Update Image
        tooltipDot
          .style('opacity', 1)
          .attr('cx', xScale(xAccessor(hoveredIndexData)))
          .attr('cy', yScale(yAccessor(hoveredIndexData)))
          .raise();

        tooltip
          .style('opacity', 1)
          .style('position', 'absolute')
          .style(
            'width',
            `${((dimensions.width + dimensions.margins * 2) / 100) * 33}px`
          )
          .style('background-color', 'rgba(70, 130, 180, 0.5)')
          .style('box-shadow', '-3px 3px rgba(136, 136, 136, 0.3)')
          .style('z-index', 99)
          .style('text-align', 'center')
          .style('border-radius', '10px')
          .style('font-size', '12px')
          .style('top', `${yScale(yAccessor(hoveredIndexData)) + 15}px`)
          .style('left', `${xScale(xAccessor(hoveredIndexData)) - 15}px`);

        tooltip.select('.data').text(`${yAccessor(hoveredIndexData)}`);

        tooltip.select('.date').text(`${labelAccessor(hoveredIndexData)}`);
      })
      .on('mouseleave', function () {
        tooltipDot.transition().duration(200).style('opacity', 0);
        tooltip.transition().duration(200).style('opacity', 0);
      });
  }, [data]); // redraw chart if data changes

  return (
    <div
      className='line-chart'
      style={{
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <svg
        ref={svgRef}
        width='100%'
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      />
      <div ref={tooltipRef} className='lc-tooltip'>
        <div className='date'></div>
        <div className='data'></div>
      </div>
    </div>
  );
};

export default LineChart;
