import React from 'react';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { StickyActionsBar } from './Layout';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '0 1rem',
  },
  saveButton: {
    '& .MuiLoadingButton-loadingIndicator': {
      position: 'unset',
      marginRight: '1rem',
    },
  },
  unsavedMessage: {
    color: theme.palette.error.main,
  },
}));

const SimpleSaveActionBar = ({
  onCancel,
  isSubmitting,
  onSubmit,
  hasUnsavedChanges,
  isMe,
  handleUserDelete,
  isDeleting,
  deleteButton,
}) => {
  const classes = useStyles();

  return (
    <StickyActionsBar>
      {!isMe && deleteButton ? (
        <div className={classes.actionsWrapper}>
          <LoadingButton
            className={classes.saveButton}
            variant='contained'
            color='primary'
            size='large'
            onClick={handleUserDelete}
            loading={isDeleting}
            loadingPosition='start'
            startIcon={<DeleteForeverIcon />}
          >
            {isDeleting ? 'Deleting' : 'Delete'}
          </LoadingButton>
          <div className={classes.actions}>
            {hasUnsavedChanges && (
              <div className={classes.unsavedMessage}>Unsaved changes</div>
            )}
            {!!onCancel && (
              <Button onClick={onCancel} disabled={isDeleting}>
                CANCEL
              </Button>
            )}
            <LoadingButton
              className={classes.saveButton}
              variant='contained'
              color='primary'
              size='large'
              onClick={onSubmit}
              loading={isSubmitting}
              loadingPosition='start'
              disabled={isDeleting}
              startIcon={
                <span /> /* have to provide this empty span to get rid of a warning */
              }
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </LoadingButton>
          </div>
        </div>
      ) : (
        <div className={classes.actions}>
          {hasUnsavedChanges && (
            <div className={classes.unsavedMessage}>Unsaved changes</div>
          )}
          {!!onCancel && <Button onClick={onCancel}>CANCEL</Button>}
          <LoadingButton
            className={classes.saveButton}
            variant='contained'
            color='primary'
            size='large'
            onClick={onSubmit}
            loading={isSubmitting}
            loadingPosition='start'
            startIcon={
              <span /> /* have to provide this empty span to get rid of a warning */
            }
          >
            {isSubmitting ? 'Saving' : 'Save'}
          </LoadingButton>
        </div>
      )}
    </StickyActionsBar>
  );
};

export default SimpleSaveActionBar;
