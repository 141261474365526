function setQueryStringParameter(name, value) {
  const params = new URLSearchParams(window.location.search);
  if (value === '' || value === undefined || value === null) {
    params.delete(name);
  } else {
    params.set(name, value);
  }
  window.history.replaceState(
    {},
    '',
    decodeURIComponent(
      `${window.location.pathname}${params.toString() ? '?' + params : ''}`
    )
  );
}

export default setQueryStringParameter;
