import React, { memo } from 'react';
import { Tooltip, Chip } from '@mui/material';
import UnpublishedChangesIcon from '@mui/icons-material/HourglassTop';
import PublishedIcon from '@mui/icons-material/Check';
import DraftIcon from '@mui/icons-material/Drafts';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.lightest,
    color: theme.palette.default.dark,
    '& .MuiChip-icon': {
      color: theme.palette.success.main,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.lightest,
    color: theme.palette.default.dark,
    '& .MuiChip-icon': {
      color: theme.palette.secondary.main,
    },
  },
  default: {
    backgroundColor: theme.palette.default.lightest,
    color: theme.palette.default.dark,
  },
}));

const StatusChip = memo(({ status }) => {
  const classes = useStyles();

  let tooltip, icon, color, label;
  switch (status) {
    case 'published':
      tooltip = 'This template has been published and is up-to-date.';
      icon = <PublishedIcon />;
      color = 'success';
      label = 'Actively published';
      break;
    case 'unpublished-changes':
      tooltip = 'This template has changes that have not been published.';
      icon = <UnpublishedChangesIcon />;
      color = 'secondary';
      label = 'Unpublished changes';
      break;
    case 'draft':
    default:
      tooltip = 'This template has not yet been published.';
      icon = <DraftIcon />;
      color = 'default';
      label = 'Unpublished draft';
      break;
  }

  return (
    <Tooltip title={tooltip} disableInteractive>
      <Chip className={classes[color]} size='small' label={label} icon={icon} />
    </Tooltip>
  );
});

export default StatusChip;
