import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'MyriadProBoldItalic',
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: '-.5rem',
    color: theme.palette.text.secondary,
  },
}));

export default function PageTitle(props) {
  const { title, subtitle, variant } = props;
  const classes = useStyles();

  return (
    <>
      <Typography variant={variant || 'h4'} className={classes.title}>
        {title}
      </Typography>
      {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
    </>
  );
}
