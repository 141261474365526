import { Modifier, EditorState, RichUtils } from 'draft-js';
import getCurrentlySelectedBlock from './getCurrentlySelectedBlock';

export const ALIGNMENTS = {
  CENTER: 'center',
  JUSTIFY: 'justify',
  LEFT: 'left',
  RIGHT: 'right',
};

export const ALIGNMENT_DATA_KEY = 'textAlignment';

const ExtendedRichUtils = Object.assign({}, RichUtils, {
  // Largely copied from RichUtils' `toggleBlockType`
  toggleAlignment(editorState, alignment) {
    const { content, currentBlock, hasAtomicBlock, target } =
      getCurrentlySelectedBlock(editorState);

    if (hasAtomicBlock) {
      return editorState;
    }

    const blockData = currentBlock.getData();
    const alignmentToSet =
      blockData && blockData.get(ALIGNMENT_DATA_KEY) === alignment
        ? undefined
        : alignment;

    return EditorState.push(
      editorState,
      Modifier.mergeBlockData(content, target, {
        [ALIGNMENT_DATA_KEY]: alignmentToSet,
      }),
      'change-block-data'
    );
  },

  /*
   * An extension of the default split block functionality, originally pulled from
   * https://github.com/facebook/draft-js/blob/master/src/component/handlers/edit/commands/keyCommandInsertNewline.js
   *
   * This version ensures that the text alignment is copied from the previously selected block.
   */
  splitBlock(editorState) {
    // Original split logic
    const contentState = Modifier.splitBlock(
      editorState.getCurrentContent(),
      editorState.getSelection()
    );
    const splitState = EditorState.push(
      editorState,
      contentState,
      'split-block'
    );

    // Assign alignment if previous block has alignment. Note that `currentBlock` is the block that was selected
    // before the split.
    const { currentBlock } = getCurrentlySelectedBlock(editorState);
    const alignment = currentBlock.getData().get(ALIGNMENT_DATA_KEY);
    if (alignment) {
      return ExtendedRichUtils.toggleAlignment(splitState, alignment);
    } else {
      return splitState;
    }
  },

  /*
   * This function was added by Mic Horvath to get which alignment type is set for the current selection to display in the toolbar button group
   *
   * It takes into account if more than one line is selected and compares the alignment type of each,
   * if they are the same then that type is returned, however, if they are different null is returned so no type is displayed.
   */
  getAlignmentType(editorState) {
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();
    const startBlock = content.getBlockForKey(selection.getStartKey());
    const endBlock = content.getBlockForKey(selection.getEndKey());

    if (startBlock.key === endBlock.key) {
      return startBlock.getData().get(ALIGNMENT_DATA_KEY) || 'LEFT';
    }

    let inSelected = false;
    let aggregrateAlignType = null;
    content.getBlockMap().some((block) => {
      if (!inSelected && block.key === startBlock.key) {
        inSelected = true;
      }

      if (inSelected) {
        let currentAlignType =
          content.getBlockForKey(block.key).getData().get(ALIGNMENT_DATA_KEY) ||
          'LEFT';

        if (aggregrateAlignType === null) {
          aggregrateAlignType = currentAlignType;
        } else if (aggregrateAlignType !== currentAlignType) {
          aggregrateAlignType = null;
          return true;
        } else if (block.key === endBlock.key) {
          return true;
        }
      }

      return false;
    });

    return aggregrateAlignType;
  },
});

export default ExtendedRichUtils;
