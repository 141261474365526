import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Collapse, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: '12px 16px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,
    textDecoration: 'none !important',
    background: theme.palette.white.main,
    transitionProperty: 'color, background',
    transitionDuration: '500ms',
    '&:focus-visible': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      boxShadow: `inset 8px 0 ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      boxShadow: `inset 8px 0 ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
  subItem: {
    '&:focus-visible': {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
  },
  active: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: `inset 8px 0 ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  subItemActive: {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    boxShadow: `inset 8px 0 ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  label: {
    marginLeft: '16px',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  copyRightWrapper: {
    bottom: '0',
    position: 'absolute',
    padding: '5px 10px',
    fontSize: '0.65rem',
  },
  copyRightTxt: {
    verticalAlign: 'top',
  },
  copyRightLogo: {
    marginLeft: '20px',
  },
}));

export function SidebarItem({ label, icon, url, items, isNewLogo }) {
  const classes = useStyles();
  const match = useRouteMatch();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenuIsShown, setSubMenuIsShown] = useState(false);

  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      toggleSubMenu();
    }
  };

  if (url) {
    const active =
      match.path === url || (url === '/' && match.path === '/home');
    return (
      <Link to={url} className={`${classes.item} ${active && classes.active}`}>
        {!!icon && <div className={classes.iconWrapper}>{icon}</div>}
        <div className={classes.label}>{label}</div>
      </Link>
    );
  } else if (items?.length) {
    const active =
      (subMenuIsShown && subMenuOpen) ||
      items.map((i) => i.url).includes(match.path);
    return (
      <>
        <span
          onClick={toggleSubMenu}
          onKeyDown={handleKeypress}
          className={`${classes.item} ${active && classes.active}`}
          tabIndex='0'
        >
          {!!icon && icon}
          <span className={classes.label}>{label}</span>
          <span className={classes.iconWrapper} style={{ marginLeft: 'auto' }}>
            {subMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </span>
        </span>
        <Collapse
          in={subMenuOpen}
          onEnter={() => {
            setSubMenuIsShown(true);
          }}
          onExited={() => {
            setSubMenuIsShown(false);
          }}
        >
          {subMenuIsShown && (
            <>
              {items?.map((subItem) => {
                if (subItem.show) {
                  const subItemActive =
                    match.path === subItem.url ||
                    (subItem.url === '/' && match.path === '/home');
                  return (
                    <Link
                      key={subItem.label + subItem.url}
                      to={subItem.url}
                      className={`${classes.item} ${
                        subItemActive && classes.subItemActive
                      } ${classes.subItem}`}
                    >
                      <span className={classes.label}>{subItem.label}</span>
                    </Link>
                  );
                } else return null;
              })}
            </>
          )}
        </Collapse>
        {isNewLogo && (
          <div className={classes.copyRightWrapper}>
            <span className={classes.copyRightTxt}>Powered by</span>
            <span className={classes.copyRightLogo}>
              <img
                height='24px'
                src={
                  process.env.REACT_APP_STATIC_ASSETS_URL + 'cis-logo-color.png'
                }
              />
            </span>
          </div>
        )}
      </>
    );
  }

  return null;
}
