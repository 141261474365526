import { useEffect } from 'react';
import { useApiPost } from '.';
import { useAuthContext } from '../auth/hooks';

export default function useLog(area) {
  const { user } = useAuthContext();

  const { fire, status: requestStatus } = useApiPost('/log');

  useEffect(() => {
    if (requestStatus === 'error') {
      console.log('Error occurred while logging!');
    }
  }, [requestStatus]);

  function log(status, message) {
    fire({
      user,
      area,
      status,
      message,
    });
  }

  return {
    log,
    requestStatus,
  };
}
