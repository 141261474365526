import React, { useEffect, useState } from 'react';
import { Formik, FastField, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  Divider,
  FormControl,
  MenuItem,
  Button,
  Tooltip,
  Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Select } from 'formik-mui';
import { ROLE_OPTIONS } from '../../../utils/auth/constants';
import UserStatus from './UserStatus';
import updateUserDetailsSchema from '../../../data/schemas/updateUserDetailsSchema';
import TextInput from '../../../components/formik/TextInput';
import SimpleSaveActionBar from '../../../components/SimpleSaveActionBar';
import { useApiPut, useApiDelete } from '../../../utils/hooks';
import { toast } from 'react-toastify';
import UnsavedChangesPrompt from '../../../components/UnsavedChangesPrompt';
import shouldComponentUpdate from '../../../components/formik/shouldComponentUpdate';
import { CIS_INTERNAL } from '../../../utils/auth/constants';
import { useAuthContext } from '../../../utils/auth/hooks';
import ImpersonateIcon from '@mui/icons-material/SupervisedUserCircle';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '26px',
    fontWeight: '300',
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: '-.5rem',
    color: theme.palette.text.secondary,
    minWidth: `75%`,
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    background: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  section: {
    display: `flex`,
  },
  impersonate: {},
  ownidp: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginRight: '0.5rem',
  },
  idpChip: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1),
  },
}));

export default function Details({
  user,
  isMe,
  initialUserDisplayed,
  refreshUser,
  deleteButton,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);
  const { roleIsAtLeast, activateImpersonateUser } = useAuthContext();
  const {
    fire: updateUser,
    error,
    request,
  } = useApiPut(`/users/${encodeURIComponent(user?.email)}`);
  const {
    fire: deleteUser,
    error: deleteError,
    request: requestDelete,
  } = useApiDelete(`/users/${encodeURIComponent(user?.email)}/delete`);

  useEffect(() => {
    if (error || deleteError) {
      toast.error(
        typeof error === 'string' ? error : 'An unexpected error occurred.'
      );
    }

    if (request.isSuccess) {
      toast.success('User successfully updated.');
    }

    if (requestDelete.isSuccess) {
      toast.success('User successfully deleted.');
      history.push('/users');
    }
    (deleteError || requestDelete.isSuccess) && setIsDeleting(false);
  }, [error, deleteError, history, requestDelete.isSuccess]);

  async function handleSubmit(values, actions) {
    if (initialUserDisplayed) {
      return;
    }
    await updateUser(values);
    await refreshUser();
    actions.setSubmitting(false);
  }

  async function handleUserDelete() {
    await setIsDeleting(true);
    await deleteUser({ email: user?.email });
  }
  const handleImpersonateUserClick = () => {
    activateImpersonateUser(user);
    history.push('/');
  };

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        role: '',
        ...user,
      }}
      onSubmit={handleSubmit}
      validationSchema={updateUserDetailsSchema}
      enableReinitialize={true}
    >
      {({ dirty, handleSubmit, isSubmitting, resetForm }) => (
        <>
          <UnsavedChangesPrompt hasUnsavedChanges={dirty} />

          <Form>
            <div className={classes.section}>
              <p className={classes.subtitle}>
                Below {isMe ? 'you' : "the user's"} can modify your basic
                account details and information.
              </p>
              <div className={classes.ownidp}>
                {user?.useExternal && (
                  <Tooltip title={user?.provider}>
                    <Chip
                      label='Uses own IDP'
                      variant='outlined'
                      size='small'
                      className={classes.idpChip}
                    />
                  </Tooltip>
                )}
              </div>
              <div className={classes.impersonate}>
                {!isMe && roleIsAtLeast(CIS_INTERNAL) && (
                  <Button
                    startIcon={<ImpersonateIcon />}
                    onClick={handleImpersonateUserClick}
                    disabled={!user?.email}
                  >
                    Impersonate
                  </Button>
                )}
              </div>
            </div>

            <br />

            <TextInput
              label='Email Address'
              name='email'
              type='email'
              variant='filled'
              disabled
            />

            <TextInput
              label='First Name'
              name='firstName'
              variant='filled'
              disabled={initialUserDisplayed}
            />

            <TextInput
              label='Last Name'
              name='lastName'
              variant='filled'
              disabled={initialUserDisplayed}
            />

            <Divider className={classes.divider} />

            <FormControl variant='standard' className={classes.formControl}>
              <FastField
                component={Select}
                labelId='role-label'
                label='Role'
                type='text'
                name='role'
                fullWidth
                variant='standard'
                disabled={isMe || initialUserDisplayed}
                shouldUpdate={shouldComponentUpdate}
              >
                {ROLE_OPTIONS?.map((option) => (
                  <MenuItem value={option?.value} key={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </FastField>

              <br />
              {!isMe && user?.enabled !== undefined && (
                <UserStatus user={user} />
              )}
            </FormControl>
            <Divider className={classes.divider} />

            <SimpleSaveActionBar
              onCancel={() => {
                resetForm();
                setTimeout(() => {
                  history.push('/users'); //hacky way to wait for resetForm to finish so the unsaved prompt doesn't show
                }, 50);
              }}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              isDeleting={isDeleting}
              hasUnsavedChanges={dirty}
              handleUserDelete={handleUserDelete}
              deleteButton={deleteButton}
              isMe={isMe}
            />
          </Form>
        </>
      )}
    </Formik>
  );
}
