import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import getUser from '../auth/getUser';
import { Auth, Hub } from 'aws-amplify';
import AuthContext from './AuthContext';
import { ROLE_OPTIONS, CIS_INTERNAL } from './constants';
import awsConfig from './awsConfig';

Auth.configure(awsConfig);

const INITIAL_USER = { email: undefined };
const INITIAL_COGNTIO_USER = {
  attributes: { email: undefined },
  email: undefined,
};

export function useAuth(themeLoading = false, setThemeLoading) {
  const [user, setUser] = useState(INITIAL_USER);
  const [userLoading, setUserLoading] = useState(true);
  const [cognitoUser, setCognitoUser] = useState(INITIAL_COGNTIO_USER);
  const [cognitoUserLoading, setCognitoUserLoading] = useState(true);
  const [impersonatedUser, setImpersonatedUser] = useState();
  const [isAuthenticated, setAuthenticated] = useState(false);
  // currently sso is set to always be false, setSso is never called
  // eslint-disable-next-line
  const [sso, setSso] = useState(false);
  const [error, setError] = useState('');

  axios.interceptors.request.use(function (config) {
    return Auth.currentSession()
      .then((session) => {
        // User is logged in. Set auth header on all requests
        config.headers.Authorization = 'Bearer ' + session.idToken.jwtToken;
        setAuthenticated(true);
        return Promise.resolve(config);
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        setAuthenticated(false);
        return Promise.resolve(config);
      });
  });

  useEffect(() => {
    const axiosSource = axios.CancelToken.source();

    // update Cognito identified user
    const updateCognitoUser = async (data) => {
      if (data?.payload?.event === 'signOut') {
        setUser(INITIAL_USER);
        setCognitoUser(INITIAL_COGNTIO_USER);
        setAuthenticated(false);
      } else {
        setCognitoUserLoading(true);
        setError('');
        try {
          const amplifyUser = await Auth.currentAuthenticatedUser();
          setCognitoUser(amplifyUser);
          setAuthenticated(true);
          setCognitoUserLoading(false);
        } catch (e) {
          setAuthenticated(false);
          setCognitoUserLoading(false);
          setThemeLoading && setThemeLoading(false);
        }
      }
    };

    // update DB User
    const updateUser = async () => {
      setUserLoading(true);
      let headers = {};
      try {
        const { idToken } = await Auth.currentSession();
        headers = {
          Authorization: 'Bearer ' + idToken.jwtToken,
        };
        setAuthenticated(true);
      } catch (e) {
        setAuthenticated(false);
        setUserLoading(false);
      }
      try {
        const axiosOptions = headers['Authorization']
          ? { cancelToken: axiosSource.token, headers }
          : { cancelToken: axiosSource.token };
        const newUser = await getUser(
          cognitoUser.signInUserSession.idToken.payload['custom:email'],
          axiosOptions
        );

        if (!newUser) {
          console.log(
            `No user found for ${cognitoUser.signInUserSession.idToken.payload['custom:email']}`
          );
          setAuthenticated(false);
        } else {
          setUser(newUser);
          setAuthenticated(true);
        }
        setUserLoading(false);
      } catch (e) {
        setUserLoading(false);
        setAuthenticated(false);
        console.error(e);
        setError(e);
      }
    };

    if (!cognitoUser.username) {
      updateCognitoUser();
    } else {
      setCognitoUserLoading(false);
    }

    if (!user.email && cognitoUser.username) {
      updateUser();
    } else {
      setUserLoading(false);
    }

    Hub.listen('auth', updateCognitoUser);
    return () => {
      Hub.remove('auth', updateUser);
      axiosSource.cancel();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser.username, cognitoUser?.attributes?.email, user.email]);

  const logout = () => {
    setUser(INITIAL_USER);
    setCognitoUser(INITIAL_COGNTIO_USER);
    clearImpersonatedUser();
    Auth.signOut();
  };

  const roleIsAtLeast = (role) => {
    if (!ROLE_OPTIONS.map((r) => r.value).includes(role)) {
      console.error(`Role ${role} is not valid`);
    }
    const userIndex = ROLE_OPTIONS.findIndex(
      (r) => r.value === (impersonatedUser ? impersonatedUser.role : user.role)
    );
    const targetIndex = ROLE_OPTIONS.findIndex((r) => r.value === role);

    return userIndex >= targetIndex;
  };

  const hasProduct = (product) => {
    if (roleIsAtLeast(CIS_INTERNAL)) {
      return true; //CIS_INTERNAL has access to all products by default
    }
    if (impersonatedUser) {
      return !!impersonatedUser?.config?.[product]?.active;
    }
    return !!user?.config?.[product]?.active;
  };

  const activateImpersonateUser = (impUser) => {
    impUser.organization !== user.organization &&
      setThemeLoading &&
      setThemeLoading(true);
    axios.defaults.headers.common['impUser'] = impUser.email;
    axios.defaults.headers.common['impOrg'] = impUser.organization;
    setImpersonatedUser(impUser);
  };

  const clearImpersonatedUser = () => {
    axios.defaults.headers.common['impOrg'] !== user.organization &&
      setThemeLoading &&
      setThemeLoading(true);
    axios.defaults.headers.common['impUser'] = '';
    axios.defaults.headers.common['impOrg'] = '';
    setImpersonatedUser(null);
  };

  const hasAuths = () => {
    return !!cognitoUser.signInUserSession?.idToken.payload['custom:hasAuths'];
  };

  const clearError = () => {
    setError('');
  };

  return {
    user: impersonatedUser || user,
    cognitoUser,
    sso,
    isLoading: cognitoUserLoading || userLoading || themeLoading,
    setAuthenticated,
    error,
    clearError,
    isAuthenticated,
    logout,
    roleIsAtLeast,
    hasProduct,
    impersonatedUser,
    activateImpersonateUser,
    isImpersonating: !!impersonatedUser,
    clearImpersonatedUser,
    hasAuths,
  };
}

export function useAuthContext() {
  return useContext(AuthContext);
}
