import React from 'react';
import ensureSafeUrl from './../utils/ensureSafeUrl.js';
import { makeStyles } from '@mui/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    position: 'relative',
    '& .link-icon': {
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'translateX(50%) translateY(-25%)',
      display: 'none',
      cursor: 'pointer',
    },
    '&:hover .link-icon': {
      display: 'initial',
    },
  },
}));

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

const Link = ({ entityKey, contentState, children }) => {
  const classes = useStyles();

  const openLink = () => {
    const { url } = contentState.getEntity(entityKey).getData();
    const linkTab = window.open(ensureSafeUrl(url), 'blank');
    // linkTab can be null when the window failed to open.
    if (linkTab) {
      linkTab.focus();
    }
  };

  const { url, targetOption } =
    contentState?.getEntity(entityKey)?.getData() || {};
  return (
    <span className={classes.linkWrapper}>
      <a href={ensureSafeUrl(url)} target={targetOption}>
        {children}
      </a>
      <OpenInNewIcon onClick={openLink} className='link-icon' />
    </span>
  );
};

export default {
  strategy: findLinkEntities,
  component: Link,
};
