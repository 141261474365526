import React, { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Router } from './utils/routes';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { colors, CssBaseline } from '@mui/material';
import { useAuth } from './utils/auth/hooks';
import AuthContext from './utils/auth/AuthContext';
import { shadeColor } from './utils/colors';
import MyriadProBoldItalic from 'url:./assets/fonts/MYRIADPRO-BOLDIT.OTF';

const queryClient = new QueryClient();

const App = () => {
  const [color, setColor] = useState('#0061AD');
  const [logo, setLogo] = useState(
    process.env.REACT_APP_STATIC_ASSETS_URL + 'cis-logo-color.png'
  );
  const [isNewLogo, setIsNewLogo] = useState(false);
  const [orgConfig, setOrgConfig] = useState([]);
  const [orgName, setOrgName] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = useAuth(loading, setLoading);

  useEffect(() => {
    (!auth?.user?.organization || !auth?.cognitoUser) &&
      auth?.impersonatedUser !== null &&
      setLoading(true);
    if (
      auth?.user?.organization &&
      auth?.cognitoUser?.signInUserSession?.idToken?.jwtToken
    ) {
      setOrgName(auth?.user?.organization);
      setToken(auth?.cognitoUser?.signInUserSession?.idToken?.jwtToken);
    }
  }, [auth.user.organization, auth.cognitoUser]);

  useEffect(() => {
    async function colorSetter() {
      if (orgName && token) {
        try {
          const headers = {
            Authorization: 'Bearer ' + token,
          };
          const resp = await axios({
            method: 'get',
            url: process.env.REACT_APP_INVOKE_URL + `/organization/${orgName}`,
            headers: headers,
          });
          resp?.data?.themeColor && setColor(resp.data.themeColor);

          if (resp?.data?.logo && !resp?.data?.isDefaultLogo) {
            setLogo(process.env.ORG_LOGOS_S3_BUKCET + resp.data.logo);
            setIsNewLogo(true); // If and only if logo is changed by user
          } else if (resp?.data?.logo && resp?.data?.isDefaultLogo) {
            setLogo(resp.data.logo);
          }
          resp?.data?.config && setOrgConfig(resp?.data?.config);
          setLoading(false);
        } catch (err) {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        }
      }
    }
    colorSetter();
  }, [orgName, token, color]);

  const theme = createTheme({
    spacing: (factor) => `${0.5 * factor}rem`,
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'MyriadProBoldItalic';
          font-style: italic;
          font-display: swap;
          font-weight: bold;
          src: url(${MyriadProBoldItalic}) format('opentype');
          unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
            U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
            U+FEFF;
        }
        `,
      },
    },
    palette: {
      default: {
        main: colors.grey[500],
        lightest: colors.grey[100],
        dark: 'rgba(0, 0, 0, 0.87)',
      },
      primary: {
        main: color,
        dark: shadeColor(color, 110), //darken primary 10%
        light: shadeColor(color, 10),
        lighter: shadeColor(color, 5),
        cisBlue: '#0061AD',
        cisBlueDark: '#00447a',
        cisBlueLight: '#e9f4fd',
      },
      secondary: {
        main: '#ff9900',
        light: '#ffad32', //lighten secondary 20%
        lightest: '#FFF4E5',
      },
      success: {
        main: '#4aa148',
        lightest: '#EDF7ED',
      },
      error: {
        main: '#c91319',
      },
      background: {
        default: '#f3f6fe80',
      },
      white: {
        main: '#fff',
      },
      grey: {
        main: colors.grey[700],
        lighter: colors.grey[500],
      },
      neutrals: {
        light: colors.grey[200],
        medium: colors.grey[500],
        dark: colors.grey[700],
        darkest: colors.grey[900],
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <AuthContext.Provider value={auth}>
            <Router
              color={color}
              setColor={setColor}
              logo={logo}
              setLogo={setLogo}
              isNewLogo={isNewLogo}
              setIsNewLogo={setIsNewLogo}
              orgConfig={orgConfig}
              loading={loading}
              setLoading={setLoading}
            />
          </AuthContext.Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
