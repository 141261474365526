import { FONT_COLORS } from './../../../../constants';

const inlineTags = {
  code: 'CODE',
  del: 'STRIKETHROUGH',
  em: 'ITALIC',
  strong: 'BOLD',
  ins: 'UNDERLINE',
  sub: 'SUBSCRIPT',
  sup: 'SUPERSCRIPT',
};

const hexToRgb = (hex) => {
  //style.color will be in the rgb format, so we need to convert any hex values from the FONT_COLORS object to rgb

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )})`
    : hex;
};

let reverseMappedColors;
const getColorName = (color) => {
  if (!reverseMappedColors) {
    //invert and cache the FONT_COLORS object for faster lookups
    reverseMappedColors = {};
    Object.keys(FONT_COLORS).forEach((c) => {
      reverseMappedColors[hexToRgb(FONT_COLORS[c].color)] = c;
    });
  }

  return reverseMappedColors[color];
};

export default function processInlineTag(tag, node, currentStyle) {
  const styleToCheck = inlineTags[tag];
  let inlineStyle;
  if (styleToCheck) {
    inlineStyle = currentStyle.add(styleToCheck).toOrderedSet();
  } else if (node instanceof HTMLElement) {
    inlineStyle = currentStyle;
    const htmlElement = node;
    inlineStyle = inlineStyle
      .withMutations((style) => {
        const color = htmlElement.style.color;
        const backgroundColor = htmlElement.style.backgroundColor;
        const fontSize = htmlElement.style.fontSize;
        const fontFamily = htmlElement.style.fontFamily.replace(/^"|"$/g, '');
        const fontWeight = htmlElement.style.fontWeight;
        const textDecoration = htmlElement.style.textDecoration;
        const fontStyle = htmlElement.style.fontStyle;
        if (color) {
          const colorName = getColorName(color.replace(/ /g, '')); //convert the color style to the name of the color so the editor displays properly
          if (colorName) {
            style.add(`color-${colorName}`);
          }
        }
        if (backgroundColor) {
          style.add(`bgcolor-${backgroundColor.replace(/ /g, '')}`);
        }
        if (fontSize) {
          style.add(`fontsize-${fontSize.replace(/pt$/g, '')}`);
        }
        if (fontFamily) {
          style.add(`fontfamily-${fontFamily}`);
        }
        if (fontWeight === 'bold') {
          style.add(inlineTags.strong);
        }
        if (textDecoration === 'underline') {
          style.add(inlineTags.ins);
        }
        if (fontStyle === 'italic') {
          style.add(inlineTags.em);
        }
      })
      .toOrderedSet();
  }
  return inlineStyle;
}
