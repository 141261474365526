import React, { useEffect, useState } from 'react';
import RootContainer from '../../components/RootContainer';
import { useAuthContext } from '../../utils/auth/hooks';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageTitle from '../../components/PageTitle';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';
import SimpleSaveActionBar from '../../components/SimpleSaveActionBar';
import validateColor from 'validate-color';
import { useApiPut } from '../../utils/hooks';
import { Checkbox, FormControlLabel } from '@mui/material';
import UploadLogo from './UploadLogo';

const useStyles = makeStyles((theme) => ({
  cardBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  inner: {
    width: '98%',
    height: '100%',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '20px',
  },
  input: {
    borderTopStyle: 'hidden',
    borderRightStyle: 'hidden',
    borderLeftStyle: 'hidden',
    outline: 'none',
    width: '200px',
    marginBottom: '20px',
  },
}));

export function Configuration({
  color,
  setColor,
  logo,
  setLogo,
  setIsNewLogo,
}) {
  const { user } = useAuthContext();
  const [themeColor, setThemeColor] = useState(color);
  const [logoSelected, setLogoSelected] = useState(logo);
  const [isDefaultLogo, setIsDefaultLogo] = useState(false);

  const {
    fire: updateHubConfig,
    error: configError,
    request,
    data,
    isLoading: loading,
  } = useApiPut('/organizations');

  useEffect(() => {
    if (configError) {
      toast.error(configError);
      console.error(configError);
    }
    if (request.isSuccess) {
      if (data?.Attributes?.logo && data?.Attributes?.isDefaultLogo) {
        setLogo(data?.Attributes?.logo);
        setIsNewLogo(false);
      } else if (data?.Attributes?.logo && !data?.Attributes?.isDefaultLogo) {
        setLogo(process.env.ORG_LOGOS_S3_BUKCET + data?.Attributes?.logo);
        setIsNewLogo(true);
      }

      toast.success('Theme settings updated successfully.');
    }
  }, [configError, request.isSuccess, data]);

  const colorChange = (value) => {
    setThemeColor(value.hex);
  };

  const handleSubmit = async () => {
    const isValid = validateColor(themeColor);
    !isValid && setThemeColor(color);
    if (logo === logoSelected) {
      // Only theme Color change
      var body = {
        themeColor: themeColor,
        PK: 'META',
        SK: `ORG:${user.organization.toLowerCase()}`,
      };
    } else {
      var body = {
        logo: logoSelected,
        isDefaultLogo: isDefaultLogo,
        themeColor: themeColor,
        PK: 'META',
        SK: `ORG:${user.organization.toLowerCase()}`,
      };
    }

    await updateHubConfig(body);
    setColor(themeColor);
  };

  const classes = useStyles();

  return (
    <RootContainer>
      <PageTitle
        title='Hub Configuration'
        subtitle="Below you can personalize your organization's Hub experience"
      />
      <Breadcrumbs
        trail={{
          AccountSettings: null,
          HubConfiguration: '/hub-configuration',
        }}
      />
      <br />

      <div className={classes.cardBox}>
        <div className={classes.inner}>
          <InputLabel style={{ marginBottom: '10px' }}>
            Primary Color
          </InputLabel>
          <ChromePicker
            color={themeColor}
            onChangeComplete={colorChange}
            width={275}
          />
          <FormControlLabel
            value='end'
            control={
              <Checkbox
                checked={themeColor === '#0061AD'}
                label='Switch to Cornerstone Blue'
                onClick={() => {
                  themeColor === '#0061AD'
                    ? setThemeColor(color)
                    : setThemeColor('#0061AD');
                }}
              />
            }
            label='Set to Cornerstone Blue'
            labelPlacement='end'
            style={{ marginBottom: '50px' }}
          />

          <InputLabel style={{ marginBottom: '10px' }}>Logo</InputLabel>
          <UploadLogo
            logoSelected={logoSelected}
            setLogoSelected={setLogoSelected}
            logo={logo}
            setLogo={setLogo}
            isDefaultLogo={isDefaultLogo}
            setIsDefaultLogo={setIsDefaultLogo}
          />
          <SimpleSaveActionBar
            onSubmit={handleSubmit}
            isSubmitting={loading}
            hasUnsavedChanges={false}
          />
        </div>
      </div>
    </RootContainer>
  );
}
