import React from 'react';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ProductsIcon from '@mui/icons-material/AppsOutlined';
import MessagingIcon from '@mui/icons-material/MailOutlined';
import TableChartIcon from '@mui/icons-material/TableChartOutlined';
import { SidebarItem } from './SidebarItem';
import { makeStyles } from '@mui/styles';
import { useAuthContext } from '../../utils/auth/hooks';
import {
  CIS_INTERNAL,
  RISK_MESSENGER,
  IBANK,
  ADMIN,
} from '../../utils/auth/constants';
import Logo from '../../components/Logo';

export const SIDEBAR_WIDTH = 215;

const useStyles = makeStyles((theme) => ({
  root: {
    width: SIDEBAR_WIDTH,
    background: theme.palette.white.main,
  },
}));

export const Sidebar = ({ isMobile, logo, isNewLogo, orgConfig }) => {
  const classes = useStyles();
  const { isAuthenticated, roleIsAtLeast, hasProduct } = useAuthContext();
  const items = [
    {
      label: 'Dashboard',
      icon: <HomeIcon />,
      url: '/',
    },
    {
      label: 'Products',
      icon: <ProductsIcon />,
      items: [
        {
          label: 'Risk Messenger',
          url: '/risk-messenger',
          show: hasProduct(RISK_MESSENGER) && roleIsAtLeast(ADMIN),
        },
        {
          label: 'iBank',
          url: '/iBank',
          show: hasProduct(IBANK),
        },
      ],
    },
    {
      label: 'Messaging',
      icon: <MessagingIcon />,
      url: '/messaging',
      show: roleIsAtLeast(CIS_INTERNAL),
    },
    {
      label: 'Workflow Tables',
      icon: <TableChartIcon />,
      url: '/workflow-tables',
      show:
        roleIsAtLeast(CIS_INTERNAL) ||
        (orgConfig?.iQCX?.active && roleIsAtLeast(ADMIN)),
    },
  ]
    ?.map((item) => {
      if (
        (typeof item.show === 'undefined' && !item.items) ||
        item.show ||
        item.items?.some((subItem) => subItem.show)
      ) {
        return (
          <SidebarItem
            isNewLogo={isNewLogo}
            key={item.label + item.url}
            {...item}
          />
        );
      }

      return false;
    })
    .filter((_) => !!_);

  return (
    <>
      {isAuthenticated && (
        <div className={classes.root}>
          {isMobile && <Logo logo={logo} />}
          {items}
        </div>
      )}
    </>
  );
};
