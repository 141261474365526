import React, {
  useState,
  memo,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  CircularProgress,
  Alert,
  AlertTitle,
  FormControlLabel,
  Checkbox,
  colors,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { useUnsavedChanges, useApiPut } from '../../../utils/hooks';
import { useTemplateContext } from './../TemplateContext';

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    margin: '3rem 0',
    textAlign: 'center',
    width: '100%',
    '& .MuiCircularProgress-root': {
      width: '2rem',
      height: '2rem',
    },
  },
  activeTemplateMessage: {
    marginLeft: '31px', //magic number sorry
    color: colors.grey[600],
    '& a': {
      color: `${colors.grey[600]} !important`,
    },
  },
}));

const Distributions = memo(
  forwardRef(
    (
      {
        template,
        setHasUnsavedChanges: setUnsavedParent,
        setSaving: setParentSaving,
        setTemplate: setParentTemplate,
      },
      ref
    ) => {
      const classes = useStyles();
      const {
        activeRiskMessengerTemplate,
        refreshActiveRiskMessengerTemplate,
      } = useTemplateContext();
      const defaultActive =
        !!template && activeRiskMessengerTemplate?.id === template?.id;
      const [active, setActive] = useState(defaultActive);
      const confirmDialogRef = useRef(null);

      const { hasUnsavedChanges, setSaved } = useUnsavedChanges({ active });

      const {
        isLoading: loading,
        fire: makeRequest,
        request,
      } = useApiPut('/templates/distributions/risk-messenger');

      useEffect(() => {
        if (request.isSuccess) {
          refreshActiveRiskMessengerTemplate(); //refresh active template
          setParentTemplate((prevTemplate) => ({ ...prevTemplate, active }));
          setSaved('active', active);
          toast.success('Your changes were successfully saved.');
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
      }, [request.isSuccess, active]);

      useEffect(() => {
        setUnsavedParent(hasUnsavedChanges); //pass hasChanged up to parent
      }, [setUnsavedParent, hasUnsavedChanges]);

      useEffect(() => {
        setParentSaving(loading); //pass loading/saving up to parent
      }, [setParentSaving, loading]);

      useEffect(() => {
        //reset default active value when template or active template changes

        setActive(defaultActive);
        setSaved('active', defaultActive);
        //eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultActive]);

      useEffect(() => {
        return () => {
          setUnsavedParent(false); //set hasChanged to false on unmount
        };
      }, [setUnsavedParent]);

      const renderActiveTemplateMessage = () => {
        if (!template) {
          return null;
        } else if (!activeRiskMessengerTemplate) {
          return 'There is currently no active Risk Messenger template';
        } else if (activeRiskMessengerTemplate?.id === template?.id) {
          return 'This is currently the active Risk Messenger template';
        } else {
          return (
            <>
              <Link
                to={`/messaging/template/${activeRiskMessengerTemplate?.id}`}
              >
                {activeRiskMessengerTemplate?.name}
              </Link>{' '}
              is currently the active Risk Messenger template
            </>
          );
        }
      };

      useImperativeHandle(ref, () => ({
        //this exposes these methods to the parent ref

        handleSave() {
          let message = '';
          if (!activeRiskMessengerTemplate) {
            message =
              'This template will become the active Risk Messenger template meaning the published version will immediately be available to customers. Do you want to proceed?';
          } else if (
            activeRiskMessengerTemplate?.id !== template?.id &&
            active
          ) {
            message = `You are about to change the active Risk Messenger template from ${activeRiskMessengerTemplate?.name} to this template. Do you want to proceed?`;
          } else if (
            activeRiskMessengerTemplate?.id === template?.id &&
            !active
          ) {
            message =
              'You are about to remove this template from being the active Risk Messenger template. This could stop Risk Messenger emails from being sent out. Do you want to proceed?';
          }

          if (confirmDialogRef?.current) {
            confirmDialogRef.current.open({
              title: 'Confirm Save Changes',
              message,
              onConfirm: () => {
                makeRequest({
                  template_id: template?.id,
                  active,
                });
              },
            });
          }
        },
      }));

      return (
        <>
          {template ? (
            <>
              <Alert severity='info'>
                <AlertTitle>
                  <strong>What are distributions?</strong>
                </AlertTitle>
                Distributions let you control how and when published email
                templates are sent to your users. For some products you can set
                an active template to be used when necessary, and for others you
                can create hooks that send on different schedules and events.
              </Alert>

              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setActive((prevActive) => !prevActive);
                    }}
                  />
                }
                label='Set as active Risk Messenger template'
                checked={active}
                disabled={loading}
              />
              <div className={classes.activeTemplateMessage}>
                {renderActiveTemplateMessage()}
              </div>
            </>
          ) : (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          )}
          <ConfirmDialog ref={confirmDialogRef} />
        </>
      );
    }
  )
);

export default Distributions;
