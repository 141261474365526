import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RecordsAdded = (props) => (
  <SvgIcon {...props} width='40px' height='40px' viewBox='0 0 40 40'>
    <title>Records-Updated</title>
    <g
      id='User-Tables'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Workflow---User-Table-Upload-1.5.1'
        transform='translate(-807.000000, -402.000000)'
      >
        <g id='Content' transform='translate(234.000000, 166.000000)'>
          <g id='Upload-Preview' transform='translate(548.000000, 74.000000)'>
            <g id='Content' transform='translate(20.000000, 13.000000)'>
              <g id='Icons-v3' transform='translate(5.000000, 37.000000)'>
                <g
                  id='Records-Updated'
                  transform='translate(0.000000, 112.000000)'
                >
                  <g
                    id='note-updated'
                    transform='translate(5.692308, 5.692308)'
                  >
                    <g
                      id='Group-3'
                      transform='translate(0.862981, 0.000000)'
                      fill='#3F51B5'
                    >
                      <path
                        d='M0.439084992,8.75499657 C0.096821191,8.41267889 -0.0577549556,7.91575401 0.0195304185,7.39675624 L0.229307694,5.90601124 C0.284511533,5.51952355 0.51637842,5.05575044 0.792397612,4.7796878 L4.70080498,0.870631224 C5.23076182,0.340590964 5.74970218,0.0645296762 6.31278133,0.0093171471 C6.99730893,-0.0569378854 7.68181093,0.230165914 8.32217544,0.870631224 C8.96253995,1.51109653 9.2495991,2.18469762 9.1833545,2.88037547 C9.12815066,3.43250076 8.84109152,3.9625316 8.32217544,4.49257186 L4.4137682,8.4016283 C4.13774901,8.67769095 3.67401519,8.90959382 3.28758833,8.96480635 L1.79707782,9.17461665 C1.70875168,9.18565916 1.63148787,9.19668953 1.54316173,9.19668953 C1.12361256,9.19668953 0.726144942,9.04210171 0.439084992,8.75499657 Z M6.46733455,1.65467339 C6.3127638,1.66571589 6.10297978,1.80924152 5.87112366,2.04113413 L1.96271629,5.9501907 C1.92959399,5.98331822 1.87437128,6.0937581 1.87437128,6.13792812 L1.67564555,7.51824133 L3.05574164,7.31945062 C3.09990471,7.30840811 3.21029352,7.25319963 3.24341582,7.22007211 L7.15185676,3.31104937 C7.38371287,3.06811425 7.5272496,2.86933971 7.53829037,2.71474463 C7.5603719,2.49389451 7.33954981,2.22885673 7.15185676,2.04113413 C6.97520448,1.86445404 6.72127087,1.65467339 6.50045551,1.65467339 C6.48941474,1.65467339 6.47837532,1.65467339 6.46733455,1.65467339 Z'
                        id='Vector'
                      ></path>
                      <path
                        d='M6.57382311,4.80571052 C5.52210467,4.50975321 4.68551834,3.66988231 4.39071848,2.61403457 C4.30307528,2.29408072 4.48632051,1.96610947 4.80502306,1.87012331 C5.12372561,1.78213601 5.45040636,1.9661251 5.53804956,2.28607895 C5.72130353,2.94198433 6.23918058,3.4618946 6.8925208,3.64586806 C7.21122335,3.73385537 7.39449287,4.06982546 7.30684967,4.38977931 C7.23514159,4.65374124 6.99611372,4.82970608 6.73318411,4.82970608 C6.67741117,4.82970608 6.62162849,4.82170821 6.57382311,4.80571052 Z'
                        id='Vector'
                        fillRule='nonzero'
                      ></path>
                    </g>
                    <path
                      d='M8.05448729,16.9719554 C7.58272447,16.9719554 7.19150651,16.5807374 7.19150651,16.1089746 C7.19150651,15.6372118 7.58272447,15.2459938 8.05448729,15.2459938 L13.8076925,15.2459938 C14.2794553,15.2459938 14.6706733,15.6372118 14.6706733,16.1089746 C14.6706733,16.5807374 14.2794553,16.9719554 13.8076925,16.9719554 L8.05448729,16.9719554 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M8.05448729,12.3693912 C7.58272447,12.3693912 7.19150651,11.9781733 7.19150651,11.5064104 C7.19150651,11.0346476 7.58272447,10.6434297 8.05448729,10.6434297 L17.2596156,10.6434297 C17.7313784,10.6434297 18.1225964,11.0346476 18.1225964,11.5064104 C18.1225964,11.9781733 17.7313784,12.3693912 17.2596156,12.3693912 L8.05448729,12.3693912 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M23.3004811,18.4102567 L23.3004811,9.19362168 C23.3004811,4.32641005 21.6435579,3.33685905 18.3642311,3.16426289 L11.5064104,3.16426289 C11.0346476,3.16426289 10.6434296,2.77304493 10.6434296,2.30128211 C10.6434296,1.82951928 11.0346476,1.43830133 11.5064104,1.43830133 L18.4102567,1.43830133 C23.1278848,1.69144235 25.0264427,3.93519193 25.0264427,9.19362168 L25.0264427,18.4102567 C25.0264427,18.8820195 24.6352247,19.2732375 24.1634619,19.2732375 C23.6916991,19.2732375 23.3004811,18.8820195 23.3004811,18.4102567 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M10.3557694,26.1768138 C3.88916685,26.1768138 2.58894234,23.1966528 2.58894234,18.3869734 L2.58894234,10.3670061 C2.58894234,9.8952433 2.9801603,9.50402535 3.45192312,9.50402535 C3.92368595,9.50402535 4.31490391,9.8952433 4.31490391,10.3670061 L4.31490391,18.3869734 C4.31490391,22.6673581 5.15487189,24.4508522 10.3557694,24.4508522 L17.2596156,24.4508522 C17.7313784,24.4508522 18.1225964,24.8420702 18.1225964,25.313833 C18.1225964,25.7855958 17.7313784,26.1768138 17.2596156,26.1768138 L10.3557694,26.1768138 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                      fillRule='nonzero'
                    ></path>
                    <path
                      d='M16.9259298,26.1080459 C16.6037503,25.969969 16.3966348,25.6592953 16.3966348,25.3141029 L16.3966348,21.8621798 C16.3966348,19.0776284 17.9269873,17.5472759 20.7115388,17.5472759 L24.1634619,17.5472759 C24.5086542,17.5472759 24.8308343,17.7543913 24.9574048,18.0765708 C25.0839753,18.3987503 25.014937,18.7669562 24.7733024,19.0200972 L17.8694551,25.9239435 C17.7083653,26.0850332 17.4782374,26.1770837 17.2596156,26.1770837 C17.1445515,26.1770837 17.0409939,26.1540715 16.9259298,26.1080459 Z M18.1225964,21.8621798 L18.1225964,23.2314433 L22.0808022,19.2732375 L20.7115388,19.2732375 C18.8935259,19.2732375 18.1225964,20.0441669 18.1225964,21.8621798 Z'
                      id='Vector'
                      fillOpacity='0.6'
                      fill='#2196F3'
                    ></path>
                    <path
                      d='M27.615385,2.35968787e-08 L27.615385,27.615385 L0,27.615385 L0,2.35968787e-08 L27.615385,2.35968787e-08 Z'
                      id='Vector'
                      opacity='0'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default RecordsAdded;
