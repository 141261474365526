import React from 'react';
import ErrorScreen from '../../components/ErrorScreen';
import RootContainer from '../../components/RootContainer';

class RouteErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //TODO: log error
  }

  render() {
    if (this.state.hasError) {
      return (
        <RootContainer>
          <ErrorScreen
            title='Something went wrong!'
            code={false}
            message='An unexpected error occurred. We are sorry for any inconvenience.'
            goBack={false}
          />
        </RootContainer>
      );
    }

    return this.props.children;
  }
}

export default RouteErrorBoundary;
