import React, { useEffect } from 'react';
import { Field } from 'formik';
import { FormControl, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Checkbox, TextField } from 'formik-mui';
import FormDialog from '../FormDialog';
import organizationSchema from '../../data/schemas/organizationSchema';
import { useHistory } from 'react-router-dom';
import { useApiPost } from '../../utils/hooks';
import { toast } from 'react-toastify';

const initialValues = {
  name: '',
  displayName: '',
  salesforceAccountNumber: '',
  isActive: true,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  container: {
    margin: theme.spacing(4),
  },
  headerTitle: {
    textAlign: 'center',
    fontSize: '26px',
    fontWeight: 300,
    margin: 0,
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const OrganizationCreate = ({ open, setOpen }) => {
  const history = useHistory();
  {
    const {
      fire: createOrgRequest,
      error: createError,
      request: requestCompleted,
      data,
      isLoading: loading,
    } = useApiPost('/organizations');

    useEffect(() => {
      if (createError) {
        toast.error(createError);
      }

      if (requestCompleted.isSuccess) {
        toast.success('Organization successfully created.');
        history.push(`/organization/${encodeURIComponent(data.name)}`);
      }
    }, [createError, requestCompleted.isSuccess, data, history]);

    const classes = useStyles();

    return (
      <FormDialog
        open={open}
        setOpen={setOpen}
        title='Create Organization'
        formikProps={{
          initialValues,
          validationSchema: organizationSchema,
          onSubmit: (values) => {
            if (loading) {
              return;
            }

            createOrgRequest(values);
          },
        }}
        loading={loading}
      >
        {() => (
          <>
            <FormControl variant='outlined' className={classes.formControl}>
              <Field
                component={TextField}
                label='Name'
                type='text'
                name='name'
                fullWidth
                variant='outlined'
                placeholder='A short alphanumeric name to identify this organization'
                disabled={loading}
              />
            </FormControl>
            <FormControl variant='outlined' className={classes.formControl}>
              <Field
                component={TextField}
                label='Salesforce Account Number'
                type='text'
                name='salesforceAccountNumber'
                fullWidth
                variant='outlined'
                placeholder='Salesforce Account Number'
                disabled={loading}
              />
            </FormControl>

            <FormControl variant='outlined' className={classes.formControl}>
              <Field
                component={TextField}
                label='Display Name'
                type='text'
                name='displayName'
                fullWidth
                variant='outlined'
                placeholder='A descriptive name for the organization that will be displayed in lists and searches'
                disabled={loading}
              />
            </FormControl>

            <FormControlLabel
              label='Active'
              control={
                <Field component={Checkbox} type='checkbox' name='isActive' />
              }
              disabled={loading}
            />
          </>
        )}
      </FormDialog>
    );
  }
};
