import React from 'react';
import { makeStyles } from '@mui/styles';
import { HEADER_HEIGHT } from './Header';

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    height: HEADER_HEIGHT - 16,
    margin: '8px',
  },
}));

function Logo({ logo }) {
  const classes = useStyles();
  return (
    <>
      <img className={classes.logoWrapper} src={logo} alt='CIS Logo' />
    </>
  );
}

export default Logo;
