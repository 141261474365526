import React, { useEffect } from 'react';
import { useAuthContext } from '../utils/auth/hooks';
import Loader from '../components/Loader';
import PageTitle from '../components/PageTitle';
import RootContainer from '../components/RootContainer';
import { Formik, Form } from 'formik';
import TextInput from '../components/formik/TextInput';
import SimpleSaveActionBar from '../components/SimpleSaveActionBar';
import { useApiPost } from '../utils/hooks';
import { IBANK_URL } from '../utils/auth/constants';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

function Authorizations() {
  const { isLoading, hasAuths, user, cognitoUser } = useAuthContext();
  const history = useHistory();

  const { fire: createUser, error, request } = useApiPost(`/autogen`);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [history, error]);

  if (isLoading) {
    return <Loader />;
  }

  if (!!user.email || request.isSuccess) {
    window.location.assign(
      `${IBANK_URL}/loginCognitoSSO.cfm?id_token=${cognitoUser?.signInUserSession?.idToken?.jwtToken}`
    );
    return <Loader />;
  }

  if (!hasAuths()) {
    return (
      <RootContainer>
        <PageTitle
          title='No Authorizations'
          subtitle='No travel requests require your authorization'
        />
      </RootContainer>
    );
  }

  const handleFormikSubmit = async (values, actions) => {
    const token = cognitoUser?.signInUserSession?.idToken?.payload;
    // add validation here
    const newUser = {
      ...values,
      email: token['custom:email'],
      agency: token['custom:autogenAgency'],
      account: token['custom:autogenAcct'],
    };
    await createUser(newUser);
  };

  return (
    <div style={{ padding: '20px' }}>
      <PageTitle title='Welcome' subtitle='Please let us know who you are' />
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
        }}
        onSubmit={handleFormikSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <>
            <Form>
              <TextInput label='First Name' name='firstName' />
              <TextInput label='Last Name' name='lastName' />
              <SimpleSaveActionBar
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                hasUnsavedChanges={false}
              />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}

export default Authorizations;
