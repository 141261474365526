import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const UploadDisabled = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        id='User-Tables'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Workflow---User-Tables'
          transform='translate(-1182.000000, -415.000000)'
          fill='#757575'
          fillRule='nonzero'
        >
          <g id='Cards' transform='translate(240.000000, 228.000000)'>
            <g id='Card' transform='translate(0.000000, 160.000000)'>
              <g id='Menu-Icons' transform='translate(892.000000, 26.000000)'>
                <g
                  id='Upload-Not-Allowed'
                  transform='translate(50.611142, 1.000000)'
                >
                  <path
                    d='M5.8129984,13.6606875 L10.1523109,13.6606875 C10.7489664,13.6606875 11.2371391,13.1725148 11.2371391,12.5758593 L11.2371391,7.15171864 L12.9620158,7.15171864 C13.9275129,7.15171864 14.4156855,5.9801151 13.7322438,5.29667337 L8.75288265,0.31731223 C8.32979968,-0.105770743 7.64635795,-0.105770743 7.22327498,0.31731223 L2.24391383,5.29667337 C1.56047211,5.9801151 2.03779649,7.15171864 3.00329353,7.15171864 L4.72817027,7.15171864 L4.72817027,12.5758593 C4.72817027,13.1725148 5.21634293,13.6606875 5.8129984,13.6606875 Z M1.47368586,15.8303437 L14.4916235,15.8303437 C15.088279,15.8303437 15.5764516,16.3185164 15.5764516,16.9151719 C15.5764516,17.5118273 15.088279,18 14.4916235,18 L1.47368586,18 C0.877030384,18 0.388857722,17.5118273 0.388857722,16.9151719 C0.388857722,16.3185164 0.877030384,15.8303437 1.47368586,15.8303437 Z'
                    id='UploadRounded'
                  ></path>
                  <path
                    d='M17.5057408,10.3630821 L-0.728025395,10.3630821 C-1.56373968,10.3630821 -2.24750591,9.8108094 -2.24750591,9.1358094 C-2.24750591,8.4608094 -1.56373968,7.90853667 -0.728025395,7.90853667 L17.5057408,7.90853667 C18.3414551,7.90853667 19.0252214,8.4608094 19.0252214,9.1358094 C19.0252214,9.8108094 18.3414551,10.3630821 17.5057408,10.3630821 Z'
                    id='RemoveRounded'
                    transform='translate(8.388858, 9.135809) rotate(-45.000000) translate(-8.388858, -9.135809) '
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default UploadDisabled;
