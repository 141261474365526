import { useQuery, useMutation } from 'react-query';
import axios from 'axios';
import { useAuthContext } from '../auth/hooks';

export default function useApiPost(endpoint) {
  useAuthContext();

  const mutation = useMutation((data) => {
    return axios.post(process.env.REACT_APP_INVOKE_URL + endpoint, data);
  });

  return {
    request: mutation,
    fire: mutation.mutate,
    fireAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    data: mutation.data?.data,
    error: mutation.error?.error,
    reset: mutation.reset,
    isSuccess: mutation.isSuccess,
    status: mutation.status,
  };
}
