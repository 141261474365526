import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Loader from '../../components/Loader';
import { useAuth } from '../../utils/auth/hooks';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'row',
  },
}));

export const Logout = () => {
  const classes = useStyles();
  const history = useHistory();
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    history.push('/');
  }, [logout, history]);

  return (
    <div className={classes.main}>
      <div>
        <p style={{ fontSize: '1.5rem' }}>Logging out...</p>
        <Loader />
      </div>
    </div>
  );
};
