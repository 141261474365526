import React, { useState, useCallback, useEffect, memo } from 'react';
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  colors,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import DistributionsIcon from '@mui/icons-material/AllInbox';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import Accordion from '../../../components/Accordion';
import Fuse from 'fuse.js';
import { TemplateList, FilterBy, NewTemplate } from './';
import { useTemplateContext } from './../TemplateContext';

const useStyles = makeStyles((theme) => ({
  templateSidebar: {
    padding: '1.5rem 1rem',
    background: colors.grey[100],
    borderRight: `1px solid ${colors.grey[300]}`,
    width: 300,
  },
  accordionTitle: {
    fontSize: '1.15rem',
    fontWeight: 500,
  },
  newTemplateContainer: {
    marginTop: '1rem',
    marginBottom: '2rem',
    textAlign: 'center',
  },
  newButton: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: 'white !important',
  },
  templateListAccordion: {
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  buttonWrapperRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '.5rem 0',
  },
  loadingContainer: {
    margin: '3rem 0',
    textAlign: 'center',
    '& .MuiCircularProgress-root': {
      width: '2rem',
      height: '2rem',
    },
  },
  listMessage: {
    padding: '1rem',
    textAlign: 'center',
    fontSize: '.9rem',
  },
}));

const maxDate = (...args) => {
  const dates =
    args?.map((dateStr) =>
      dateStr instanceof Date ? dateStr : new Date(dateStr)
    ) || []; //convert date strings to date objects
  return dates.reduce(function (a, b) {
    return a > b ? a : b;
  });
};

const sortTemplates = (a, b) => {
  //sorts templates that have been modified most recently to the top of the list

  return (
    maxDate(b?.publishedTime, b?.draftTime, b?.createdAt) -
    maxDate(a?.publishedTime, a?.draftTime, a?.createdAt)
  );
};

const TemplateSidebar = memo(() => {
  const classes = useStyles();
  const history = useHistory();
  const { templates, setTemplates, loadingTemplates } = useTemplateContext();
  const [templateListHeight, setTemplateListHeight] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [filteredTemplates, setFilteredTemplates] = useState(null);
  const [defaultFilters] = useState({
    Status: {
      published: { label: 'Published', value: true },
      'unpublished-changes': { label: 'Unpublished changes', value: true },
      draft: { label: 'Unpublished draft', value: true },
    },
  });
  const [filters, setFilters] = useState({});
  const [newTemplateOpen, setNewTemplateOpen] = useState(false);

  const handleTemplateListHeight = useCallback(
    (height) => {
      setTemplateListHeight(height);
    },
    [setTemplateListHeight]
  );

  const filterTemplates = useCallback(
    (template) => {
      return !!filters?.[template?.status];
    },
    [filters]
  );

  useEffect(() => {
    if (!searchValue) {
      setFilteredTemplates(
        templates && templates?.filter(filterTemplates).sort(sortTemplates)
      );
      return;
    }

    const fuse = new Fuse(templates, {
      threshold: 0.25,
      keys: ['name'],
    });
    const filtered = fuse.search(searchValue)?.map((res) => res.item);
    setFilteredTemplates((prevFiltered) => {
      return filtered
        .map((item, index) =>
          item === prevFiltered[index] ? prevFiltered[index] : item
        )
        .filter(filterTemplates)
        .sort(sortTemplates);
    });
  }, [templates, searchValue, filters, filterTemplates]);

  const getTemplateList = () => {
    if (loadingTemplates || filteredTemplates === null) {
      //filteredTemplates is null for a brief moment after loading is set to false and the initial filtering hasn't occurred, so if we don't check for a null value here it will flash the "no templates" message below before displaying them
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      );
    } else if (filteredTemplates.length) {
      return (
        <TemplateList
          templates={filteredTemplates}
          height={templateListHeight}
          setTemplates={setTemplates}
        />
      );
    } else {
      return (
        <div className={classes.listMessage}>
          {templates.length
            ? 'No templates match your search query'
            : 'No templates were found'}
        </div>
      );
    }
  };

  const handleFilterChange = useCallback((values) => {
    setFilters(values);
  }, []);

  return (
    <div className={classes.templateSidebar}>
      <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
        <div>
          <h2 style={{ marginTop: 0 }}>Saved Templates</h2>
          <TextField
            label='Search templates'
            variant='outlined'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <div className={classes.buttonWrapperRight}>
            <FilterBy filters={defaultFilters} onChange={handleFilterChange} />
          </div>

          <div className={classes.buttonWrapperRight}>
            <Button variant='text' startIcon={<DistributionsIcon />} disabled>
              View All Distributions
            </Button>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <Accordion
            defaultExpanded
            fullHeight
            onHeightAdjust={handleTemplateListHeight}
            Header={<span className={classes.accordionTitle}>Templates</span>}
            className={classes.templateListAccordion}
          >
            {getTemplateList()}
          </Accordion>

          <div className={classes.newTemplateContainer}>
            <IconButton
              className={classes.newButton}
              size='large'
              aria-label='Add new template draft'
              onClick={() => {
                setNewTemplateOpen(true);
              }}
            >
              <AddIcon fontSize='large' />
            </IconButton>
            <br />
            <span style={{ color: colors.grey[600] }}>
              Add new template draft
            </span>
          </div>
        </div>
      </div>
      <NewTemplate
        open={newTemplateOpen}
        setOpen={setNewTemplateOpen}
        templates={templates}
        loadingTemplates={loadingTemplates}
        onSubmit={(values) => {
          if (!values?.id) {
            return;
          }

          //add the new template to the existing list so we don't need to refetch it
          setTemplates((prevTemplates) => {
            history.push(`/messaging/template/${values.id}`);
            return [...prevTemplates, values];
          });
        }}
      />
    </div>
  );
});

export default TemplateSidebar;
