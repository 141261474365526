import React from 'react';
import { Tooltip, colors } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DATA_TAGS } from './../constants';

const useStyles = makeStyles((theme) => ({
  dataTag: {
    background: colors.grey[200],
    border: `1px solid ${colors.grey[300]}`,
    color: colors.grey[700],
    borderRadius: '.25rem',
    padding: '.15rem .5rem',
    margin: '0 .25rem',
    '&:hover, &:focus': {
      background: colors.grey[100],
    },
  },
}));

export const TAG_REGEX = /(?<=[^{]|^)({{[^{}]+}})(?=[^}]|$)/g;

export function findTagEntities(contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = TAG_REGEX.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length, matchArr[0]);
  }
}

const DataTag = ({ contentState, blockKey, start, children }) => {
  const classes = useStyles();
  let tooltip = 'Custom user defined code block';
  const entityKey = contentState?.getBlockForKey(blockKey)?.getEntityAt(start);

  if (entityKey) {
    const { product, tag } = contentState?.getEntity(entityKey)?.getData();
    tooltip = DATA_TAGS?.[product]?.find((_) => _.tag === tag)?.description;
  }

  return (
    <Tooltip title={tooltip || ''} disableInteractive>
      <span className={classes.dataTag} spellCheck={false}>
        {children}
      </span>
    </Tooltip>
  );
};

export default {
  strategy: findTagEntities,
  component: DataTag,
};
