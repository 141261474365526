import React, { useState, useEffect } from 'react';
import RootContainer from '../../components/RootContainer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PageTitle from '../../components/PageTitle';
import { useGetOrgByName } from '../../utils/hooks';
import Loader from '../../components/Loader';
import LineChart from '../../components/D3/LineChart';
import { toast } from 'react-toastify';
import {
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  ListItemText,
  FormControl,
  Box,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useApiPut } from '../../utils/hooks';
import { useAuthContext } from '../../utils/auth/hooks';

const useStyles = makeStyles((theme) => ({
  headerBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  numWrap: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  changeWrap: {
    textAlign: 'end',
  },
  title: {
    fontSize: theme.spacing(4),
    color: theme.palette.neutrals.darkest,
    fontWeight: 600,
  },
  subtitle: {
    fontSize: theme.spacing(3),
    color: theme.palette.neutrals.darkest,
    fontWeight: 400,
  },
  body: {
    fontSize: theme.spacing(2),
    fontWeight: 400,
    color: theme.palette.neutrals.darkest,
  },
}));

export const Home = () => {
  const [range, setRange] = useState('Previous Week');
  const [data, setData] = useState({ numbers: [], records: [] });
  const { user } = useAuthContext();

  const { loading, error, org, clearError } = useGetOrgByName(
    user.organization
  );

  const {
    data: pnrsProcessed,
    isLoading: pnrsLoading,
    error: pnrsError,
    fire: requestPNRsProcessed,
  } = useApiPut('/metrics/pnrs-processed');

  useEffect(() => {
    if (error || pnrsError) {
      console.log(error);
      toast.error(
        typeof error === 'string' ? error : 'An unexpected error occurred.'
      );
      clearError();
    } else if (org) {
      const metricRange = range.toLowerCase().split(' ')[1];
      const requester = async () => {
        const acctList = org.config.iQCX.accts.map((act) => act.value);
        return await requestPNRsProcessed({
          accts: acctList,
          range: metricRange,
        });
      };
      requester();
    }
  }, [error, pnrsError, clearError, org]);

  useEffect(() => {
    if (pnrsProcessed) {
      /*
        * Update for when steady PNRs processed data:

      const thisRange =
        (pnrsProcessed.length > 0 &&
          pnrsProcessed[0]?.Weeks_Ago === 1 &&
          pnrsProcessed[0]?.PNRs_Processed) ||
        0;
      const lastRange =
        (pnrsProcessed.length > 0 &&
          pnrsProcessed[1]?.Weeks_Ago === 2 &&
          pnrsProcessed[1]?.PNRs_Processed) ||
        (pnrsProcessed[0]?.Weeks_Ago === 2 &&
          pnrsProcessed[0]?.PNRs_Processed) ||
        0;
      */
      const thisRange =
        (pnrsProcessed.numbers?.length > 0 &&
          pnrsProcessed?.numbers[0]?.PNRs_Processed) ||
        0;
      const lastRange =
        (pnrsProcessed?.numbers?.length > 0 &&
          pnrsProcessed?.numbers[1]?.PNRs_Processed) ||
        0;
      const changePercent = getPercentageChange(thisRange, lastRange);
      const percentVal = thisRange >= lastRange ? '+' : '-';
      const percentTotal = `${percentVal}${changePercent}%`;
      const positiveChange = changePercent !== 0 && percentVal === '+';
      const copy = [...pnrsProcessed?.records];
      let rangeRecordsReversed = copy?.reverse();
      const rangeRecords = rangeRecordsReversed?.map((record, i) => ({
        dateName: record.Date_Name,
        value: record.PNRs_Processed,
        label: i,
      }));
      setData({
        numbers: [
          ...data.numbers.filter((metric) => metric.label !== 'PNRs Processed'),
          {
            label: 'PNRs Processed',
            figure: thisRange,
            change: percentTotal,
            positive: positiveChange,
          },
        ],
        records: rangeRecords,
      });
    }
  }, [pnrsProcessed]);

  const renderSwitch = () => {
    switch (range) {
      case 'Previous Week':
      default:
        return 'weeks';
    }
  };

  function getPercentageChange(newNumber, oldNumber) {
    if (newNumber === oldNumber) return 0;
    const decreaseValue = oldNumber - newNumber;

    return Math.floor(Math.abs((decreaseValue / oldNumber) * 100));
  }

  const handleChange = (e) => {
    setRange(e.target.value);
  };
  const classes = useStyles();
  return (
    <RootContainer>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} sm={6}>
          <PageTitle title='Dashboard' />
        </Grid>
        <Grid
          item
          xs='auto'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <FormControl variant='standard'>
            <Select
              value={range}
              IconComponent={() => null}
              inputProps={{ sx: { padding: '0 !important' } }}
              onChange={handleChange}
              style={{ backgroundColor: '#F6F8FE', textAlign: 'center' }}
              disableUnderline
              renderValue={(value) => {
                return (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CalendarMonthIcon fontSize='small' />
                    {value}
                  </Box>
                );
              }}
            >
              <MenuItem value='Previous Week'>
                <ListItemText primary='Previous Week' />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {loading || pnrsLoading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {data.numbers.map((metric, i) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i}>
                <Card
                  sx={{
                    bgColor: '#FFFFFF',
                    position: 'relative',
                    overflow: 'visible',
                  }}
                >
                  <CardContent>
                    <Typography
                      variant='h5'
                      sx={{ fontWeight: 600, fontSize: 16, color: '#A0A0AB' }}
                      component='div'
                      gutterBottom
                    >
                      {metric.label}
                    </Typography>
                    <div className={classes.numWrap}>
                      <Typography
                        sx={{ fontSize: 30, fontWeight: 600 }}
                        color='text.primary'
                      >
                        {metric.figure}
                      </Typography>
                      <div className={classes.changeWrap}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: 600 }}
                          color={metric.positive ? 'success.main' : 'error'}
                        >
                          {metric.change}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 600,
                            color: '#A0A0AB',
                          }}
                        >
                          {`2 ${renderSwitch()} ago`}
                        </Typography>
                      </div>
                    </div>
                    {metric.figure > 0 && (
                      <LineChart data={data?.records || []} />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </RootContainer>
  );
};
