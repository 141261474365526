import * as yup from 'yup';

const userCreateSchema = yup.object().shape({
  organization: yup.string().required('Organization is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Must provide a valid email'),
  role: yup.string().required('Role is a required'),
  useExternal: yup.boolean(),
});

export default userCreateSchema;
