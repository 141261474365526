let config;

if (process.env.REACT_APP_IS_PROD === 'True') {
  config = {
    mandatorySignIn: true,
    region: 'us-east-1',
    userPoolId: 'us-east-1_GDcHEV6qU',
    userPoolWebClientId: '5g8ua5puqimf66lbk15ju7f9ne',
    oauth: {
      domain: 'https://cissso.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: document.location.origin,
      redirectSignOut: document.location.origin,
      responseType: 'token',
    },
  };
} else {
  config = {
    mandatorySignIn: true,
    region: 'us-east-2',
    userPoolId: 'us-east-2_4YQ99GNA1',
    userPoolWebClientId: '7n81v27crlfv6r0uh1o07alau0',
    oauth: {
      domain: 'tk421.auth.us-east-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: document.location.origin,
      redirectSignOut: document.location.origin,
      responseType: 'token',
    },
  };
}

export default config;
