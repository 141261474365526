import React, { useRef, useEffect, useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  accordionHeader: {
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
    },
  },
}));

function Accordion({
  hideExpandIcon,
  Header,
  children,
  fullHeight,
  fullHeightMax,
  onHeightAdjust,
  ...rest
}) {
  const classes = useStyles();
  const [detailsHeight, setDetailsHeight] = useState('auto');
  const accordionContainerRef = useRef(null);
  const accordionHeaderRef = useRef(null);

  const adjustHeight = () => {
    setDetailsHeight(0);
    if (fullHeight || fullHeightMax) {
      const parentAvailableHeight =
        accordionContainerRef?.current?.parentElement?.offsetHeight || 0;
      const parentChildren =
        accordionContainerRef?.current?.parentElement?.children;
      let usedHeight = accordionHeaderRef?.current?.offsetHeight || 0;

      for (let i = 0; i < parentChildren.length; i++) {
        const child = parentChildren[i];

        if (child !== accordionContainerRef?.current) {
          const styles = window.getComputedStyle(child);
          usedHeight += child?.offsetHeight || 0;
          usedHeight += parseFloat(styles['marginTop']);
          usedHeight += parseFloat(styles['marginBottom']);
        }
      }

      const newHeight = parentAvailableHeight - usedHeight - 1;
      setDetailsHeight(`${newHeight}px`);
      if (typeof onHeightAdjust === 'function') {
        onHeightAdjust(newHeight);
      }
    } else {
      setDetailsHeight('auto');
    }
  };

  useEffect(() => {
    adjustHeight();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordionContainerRef, accordionHeaderRef]);

  useEffect(() => {
    window.addEventListener('resize', adjustHeight);

    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={accordionContainerRef}>
      <MuiAccordion {...rest}>
        <div ref={accordionHeaderRef}>
          <AccordionSummary
            expandIcon={hideExpandIcon ? null : <ExpandMore />}
            className={classes.accordionHeader}
          >
            {Header}
          </AccordionSummary>
          <Divider />
        </div>
        {!!children && (
          <AccordionDetails
            style={{
              [fullHeightMax ? 'maxHeight' : 'height']: detailsHeight,
              overflowY: 'auto',
            }}
          >
            {children}
          </AccordionDetails>
        )}
      </MuiAccordion>
    </div>
  );
}

export default Accordion;
