import Axios from 'axios';

const API_INVOKE_URL = process.env.REACT_APP_INVOKE_URL;
export default async function getUser(email, options = {}) {
  if (!email) {
    return false;
  }

  return Axios.get(
    `${API_INVOKE_URL}/users/${encodeURIComponent(email)}`,
    options
  ).then((res) => {
    return JSON.parse(res.request.response);
  });
}
