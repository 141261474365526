import React, { useState } from 'react';
import { UserListFilters } from '../../components/TableFilters/UserListFilters';
import { UserTable } from '../../components/Table';
import Loader from '../../components/Loader';
import RootContainer from '../../components/RootContainer.js';
import { useApiGet } from '../../utils/hooks';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageTitle from '../../components/PageTitle';
import { useAuthContext } from '../../utils/auth/hooks';

export const Users = ({ setLoading }) => {
  const { user } = useAuthContext();
  const [searchResults, setSearchResults] = useState([]);
  const defaultSearchOrg =
    new URLSearchParams(window.location.search)?.get('org') ||
    user?.organization;
  const [searchOrg, setSearchOrg] = useState(defaultSearchOrg);
  const { isLoading: loadingUsers, data: users } = useApiGet(
    `/users?org=${encodeURIComponent(searchOrg || defaultSearchOrg)}`,
    { fireOnMount: true }
  );
  const { isLoading: loadingOrgs, data: organizations } = useApiGet(
    '/organizations',
    { fireOnMount: true }
  );

  return (
    <RootContainer>
      <PageTitle title='Users' />
      <Breadcrumbs
        trail={{
          Admin: null,
          Users: '/users',
        }}
      />
      <br />

      <UserListFilters
        setSearchResults={setSearchResults}
        searchOrg={searchOrg}
        setSearchOrg={setSearchOrg}
        users={users}
        organizations={organizations}
        loadingOrgs={loadingOrgs}
      />
      {loadingUsers ? (
        <Loader />
      ) : (
        <UserTable data={searchResults} setLoading={setLoading} />
      )}
    </RootContainer>
  );
};
