import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import RootContainer from '../../components/RootContainer.js';
import { TemplateSidebar, EditTemplate } from './components/';
import MessageIcon from '@mui/icons-material/MailOutline';
import { colors } from '@mui/material';
import { TemplateContext, useTemplateStore } from './TemplateContext';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageTitle from '../../components/PageTitle';

const useStyles = makeStyles((theme) => ({
  messagingRootContainer: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
  },
  selectMessageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
    textAlign: 'center',
    color: colors.grey[600],
  },
}));

export const Messaging = () => {
  const classes = useStyles();
  const templateStore = useTemplateStore();
  const { params: { template: templateId } = {} } = useRouteMatch() || {};

  return (
    <TemplateContext.Provider value={templateStore}>
      <div className={classes.messagingRootContainer}>
        <TemplateSidebar />
        <RootContainer style={{ flex: 1, overflow: 'hidden' }}>
          <PageTitle title='Messaging' />
          <Breadcrumbs trail={{ Home: '/home', Messaging: '/messaging' }} />
          <br />
          {templateId === undefined ? (
            <div className={classes.selectMessageContainer}>
              <MessageIcon sx={{ fontSize: '10rem' }} />
              <span style={{ fontSize: '2rem' }}>
                Select a template
                <br />
                to begin
              </span>
            </div>
          ) : (
            <EditTemplate id={templateId} />
          )}
        </RootContainer>
      </div>
    </TemplateContext.Provider>
  );
};
