export const TOOLBAR_DEFAULTS = {
  UNDO: true,
  REDO: true,
  ALIGN_LEFT: true,
  ALIGN_RIGHT: true,
  ALIGN_CENTER: true,
  ALIGN_JUSTIFY: false,
  BOLD: true,
  ITALIC: true,
  UNDERLINE: true,
  STRIKETHROUGH: true,
  FONT_SIZE: true,
  FONT_COLOR: true,
  UL: true,
  OL: true,
  LINK: true,
  HR: true,
  DATA_TAG: false,
};

export const FONT_COLORS = {
  default: {
    color: 'rgba(0, 0, 0, 0.87)',
    title: 'Default',
  },
  ltgrey: {
    color: '#C3C3C3',
    title: 'Light Grey',
  },
  grey: {
    color: '#666666',
    title: 'Grey',
  },
  red: {
    color: '#CC0000',
    title: 'Red',
  },
  orange: {
    color: '#E57F19',
    title: 'Orange',
  },
  yellow: {
    color: '#EEF026',
    title: 'Yellow',
  },
  ltgreen: {
    color: '#5CE547',
    title: 'Lime',
  },
  green: {
    color: '#28A914',
    title: 'Green',
  },
  blue: {
    color: '#11C0E5',
    title: 'Blue',
  },
  dkblue: {
    color: '#0024D8',
    title: 'Indigo',
  },
  purple: {
    color: '#7F19EA',
    title: 'Purple',
  },
  pink: {
    color: '#EC40EE',
    title: 'Pink',
  },
  brown: {
    color: '#6D511B',
    title: 'Brown',
  },
};

export const FONT_SIZES = [8, 10, 12, 14, 16, 18, 22, 24, 28, 32, 36, 42, 48];

export const DEFAULT_FONT_SIZE = 12;

const tomorrow = new Date();
tomorrow.setDate(new Date().getDate() + 1);
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const DATA_TAGS = {
  'risk-messenger': [
    {
      label: 'Passenger Name',
      description: "The recipient passenger's name",
      tag: 'passenger-name',
      default: 'John Doe',
    },
    {
      label: 'From City Name',
      description: 'The name of the departing city (ex: Indianapolis)',
      tag: 'from-city',
      default: 'Bloomington',
    },
    {
      label: 'To City Name',
      description: 'The name of the destination city (ex: Indianapolis)',
      tag: 'to-city',
      default: 'Chicago',
    },
    {
      label: 'From Location',
      description: 'The complete departing location (ex: Indianapolis IN, USA)',
      tag: 'from-location',
      default: 'Bloomington, IN USA',
    },
    {
      label: 'To Location',
      description:
        'The complete destination location (ex: Indianapolis IN, USA)',
      tag: 'to-location',
      default: 'Chicago, IL USA',
    },
    {
      label: 'Airline',
      description: 'The name of the airline',
      tag: 'airline',
      default: 'United Airlines',
    },
    {
      label: 'Flight Number',
      description: 'The number of the flight from the booking',
      tag: 'flight-number',
      default: '1234',
    },
    {
      label: 'Depart Time',
      description: 'The depart time',
      tag: 'depart-time',
      default: '07:45 AM',
    },
    {
      label: 'Arrive Time',
      description: 'The arrival time',
      tag: 'arrive-time',
      default: '11:12 AM',
    },
    {
      label: 'Confirmation Number',
      description: 'The confirmation number of the booking',
      tag: 'confirmation-number',
      default: 'ABCDEF',
    },
    {
      label: 'Book Date',
      description: 'The date of the flight from the booking',
      tag: 'book-date',
      default: months[tomorrow.getMonth()] + ' ' + tomorrow.getDate(),
    },
    {
      label: 'Signature',
      description:
        'The custom risk messenger signature configured by the client',
      tag: 'signature',
      default:
        '<p><span style="color: #7F19EA"><span style="color: #0024D8"><span style="font-size: 24pt"><span style="font-size: 22pt"><strong>John Doe</strong></span></span></span></span></p><p><span style="font-size: 14pt"><em>Director of Travel Operations</em></span></p><p><a href="https://ciswired.com/">Cornerstone Information Systems</a>&nbsp;</p>',
    },
    {
      label: 'Questions',
      description: 'An array of travel questions from Sitata',
      tag: 'questions',
      default: [
        {
          title: 'Primary Travel Factors',
          questions: [
            {
              question: 'Is quarantine required on arrival?',
              answer: 'No',
            },
            {
              question: 'Is a test certificate required?',
              answer: 'No',
            },
            {
              question: 'Is an entry form required? (health or other)',
              answer: 'Yes',
            },
          ],
        },
        {
          title: 'Destination health Summary',
          questions: [
            {
              question: 'Is a test required on arrival?',
              answer: 'No',
            },
            {
              question: 'Is a vaccination required?',
              answer: 'No, with exceptions',
            },
            {
              question: 'Is insurance required?',
              answer: 'No',
            },
            {
              question: 'Are masks required in public?',
              answer: 'No',
            },
            {
              question: 'Are there social distancing rules?',
              answer: 'Moderate',
            },
          ],
        },
        {
          title: 'Restrictions',
          questions: [
            {
              question: 'Are there airline restrictions?',
              answer: 'Minimal',
            },
            {
              question: 'Are there transportation restrictions?',
              answer: 'Minimal',
            },
            {
              question: 'Are there other restrictions?',
              answer: 'Unkown or Not Applicable',
            },
          ],
        },
        {
          title: 'Food, Shopping, Recreation',
          questions: [
            {
              question: 'Are accommodations open?',
              answer: 'Yes',
            },
            {
              question: 'Are restaurants open?',
              answer: 'Yes',
            },
            {
              question: 'Are bars and cafes open?',
              answer: 'Yes',
            },
            {
              question: 'Are non-essential shops open?',
              answer: 'Yes',
            },
          ],
        },
      ],
    },
  ],
};
