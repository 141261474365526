import React, {
  useState,
  memo,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ConfirmDialog from '../../../components/ConfirmDialog';
import RichTextEditor from '../../../components/RichTextEditor';
import { toast } from 'react-toastify';
import { useUnsavedChanges, useApiPut } from '../../../utils/hooks';
import { useTemplateContext } from './../TemplateContext';

const useStyles = makeStyles((theme) => ({
  templatePage: {
    background: 'white',
    minHeight: '680px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingContainer: {
    margin: '3rem 0',
    textAlign: 'center',
    width: '100%',
    '& .MuiCircularProgress-root': {
      width: '2rem',
      height: '2rem',
    },
  },
}));

const TemplateEditor = memo(
  forwardRef(
    (
      {
        template,
        version,
        setHasUnsavedChanges: setUnsavedParent,
        setSaving: setParentSaving,
        setPublishing: setParentPublishing,
        setTemplate: setParentTemplate,
        setVersion: setParentVersion,
      },
      ref
    ) => {
      const classes = useStyles();
      const { setTemplates: setParentTemplates } = useTemplateContext();
      const [templateHtml, setTemplateHtml] = useState('');
      const [action, setAction] = useState(null);
      const editorRef = useRef(null);
      const confirmDialogRef = useRef(null);

      const { hasUnsavedChanges, setSaved } = useUnsavedChanges({
        templateHtml: templateHtml === '<p><br></p>' ? '' : templateHtml,
      });
      const {
        isLoading: loading,
        error,
        fire: makeRequest,
        request,
      } = useApiPut('/templates');

      useEffect(() => {
        if (error) {
          toast.error(
            typeof error === 'string' ? error : 'An unexpected error occurred.'
          );
        }

        if (request.isSuccess) {
          setSaved('templateHtml', templateHtml);

          //update parent template list and template data so the surrounding ui updates accordingly based on our actions
          let revisedTemplate = {};
          setParentTemplate((prevTemplate) => {
            let status = prevTemplate.status;

            if (action === 'save' && status === 'published') {
              status = 'unpublished-changes';
            } else if (action === 'publish') {
              status = 'published';
            }

            revisedTemplate = {
              ...prevTemplate,
              [action === 'save' ? 'draftContent' : 'publishedContent']:
                templateHtml,
              [action === 'save' ? 'draftTime' : 'publishTime']:
                new Date().toISOString(),
              status,
            };
            return revisedTemplate;
          });
          setParentTemplates((prevTemplates) => {
            return prevTemplates?.map((template) => {
              return template.id === revisedTemplate.id
                ? revisedTemplate
                : template;
            });
          });

          toast.success(
            action === 'save'
              ? 'Your changes were successfully saved.'
              : 'Your draft was successfully published.'
          );
          if (action === 'publish') {
            setTimeout(() => {
              setParentVersion('published'); //switch to the version we just published, the timeout is again a hacky way of waiting for the state to update so unsaved changes aren't a problem
            }, 50);
          }
          setAction(null);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
      }, [request.isSuccess, error, templateHtml, action]);

      useEffect(() => {
        if (typeof editorRef?.current?.resetDefault === 'function') {
          editorRef.current.resetDefault(); //reset text editor on version change
        }

        if (version) {
          setUnsavedParent(false);
        }
      }, [version, editorRef, setUnsavedParent]);

      useEffect(() => {
        setUnsavedParent(hasUnsavedChanges); //pass hasChanged up to parent
      }, [setUnsavedParent, hasUnsavedChanges]);

      useEffect(() => {
        //pass loading (aka saving/publishing) up to parent

        if (action === 'save') {
          setParentSaving(loading);
          setParentPublishing(false); //only one action can be performed at a time so the one not being performed would have to be false
        } else {
          setParentSaving(false); //only one action can be performed at a time so the one not being performed would have to be false
          setParentPublishing(loading);
        }
      }, [setParentSaving, setParentPublishing, action, loading]);

      useEffect(() => {
        let defaultHtml =
          (version === 'draft'
            ? template?.draftContent
            : template?.publishedContent) || '';
        defaultHtml = defaultHtml === '<p><br></p>' ? '' : defaultHtml;

        setTemplateHtml(defaultHtml);
        setSaved('templateHtml', defaultHtml);
        //eslint-disable-next-line react-hooks/exhaustive-deps
      }, [version, template]);

      const handleSavePub = (action) => {
        setAction(action);
        makeRequest({
          template_id: template?.id,
          status: action === 'save' ? 'draft' : 'published',
          content: templateHtml,
        });
      };

      useImperativeHandle(ref, () => ({
        //this exposes these methods to the parent ref

        handleSave() {
          handleSavePub('save');
        },

        handlePublish() {
          if (hasUnsavedChanges && confirmDialogRef?.current) {
            confirmDialogRef.current.open({
              title: 'Confirm Publish',
              message:
                'You currently have unsaved changes in this draft. Are you sure you would like to publish those changes?',
              onConfirm: () => {
                handleSavePub('publish');
              },
            });
          } else {
            handleSavePub('publish');
          }
        },

        getHtml() {
          return templateHtml;
        },
      }));

      return (
        <>
          <div className={classes.templatePage}>
            <div style={{ padding: '1rem', flex: 1, display: 'flex' }}>
              {template ? (
                <RichTextEditor
                  placeholder='A blank canvas to create the message of your dreams...'
                  disabled={loading || version === 'published'}
                  onChangeHTML={setTemplateHtml}
                  ref={editorRef}
                  defaultContentHTML={
                    (version === 'draft'
                      ? template?.draftContent
                      : template?.publishedContent) || ''
                  }
                  toolbarSize='small'
                  fullWidth
                  fullHeight
                  borderless
                  toolbarOptions={{ DATA_TAG: 'risk-messenger' }}
                />
              ) : (
                <div className={classes.loadingContainer}>
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
          <ConfirmDialog ref={confirmDialogRef} />
        </>
      );
    }
  )
);

export default TemplateEditor;
