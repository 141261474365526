import React from 'react';
import ErrorScreen from '../components/ErrorScreen';
import RootContainer from '../components/RootContainer.js';

export const NotFound = () => {
  return (
    <RootContainer>
      <ErrorScreen
        title='Oops!'
        code='404'
        message='Page not found'
        goBack={true}
      />
    </RootContainer>
  );
};
