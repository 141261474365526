import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  sectionNav: {
    marginBottom: `28px`,
    '& > nav > a.Mui-selected': {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      background: ` #ffffff`,
    },
  },
  listItem: {
    width: '200px',
  },
  list: {
    display: 'flex',
  },
}));

export default ({ sections }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const classes = useStyles();

  let sectionLabels = [];
  const sectionItems = sections.map((section, index) => {
    if (section.label) {
      sectionLabels.push(section.label);
    }

    const itemProps = section.url
      ? { component: Link, to: section.url }
      : {
          onClick: () => {
            if (typeof section.onClick) {
              section.onClick();
            }
          },
        };

    return (
      <ListItem
        className={classes.listItem}
        key={index}
        selected={selectedIndex === index}
        onClick={(event) => handleListItemClick(event, index)}
        button
        {...itemProps}
      >
        <ListItemText primary={section.text} />
      </ListItem>
    );
  });

  return (
    <div className={classes.sectionNav}>
      <List className={classes.list} component='nav' aria-label={sectionLabels}>
        {sectionItems}
      </List>
    </div>
  );
};
