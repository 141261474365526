import React, { useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CSVLink } from 'react-csv';
import * as FileSaver from 'file-saver';
import CsvDownloader from 'react-csv-downloader';
import * as XLSX from 'xlsx';
import {
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Tooltip,
  Button,
  Slide,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
  exportDialogSelect: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  exportDialogLabel: {
    display: 'flex',
  },
}));

// Make the export dialog slide from the bottom.
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function TableViewerExportDialog(props) {
  const data = props.data;
  const classes = useStyles();
  const [noHeader, setNoHeader] = useState(false);
  const [exportFormat, setExportFormat] = useState('XLSX');
  const [exportDataSet] = useState('Entire Table');
  const csvHeader = [];
  const csvHeaderWithoutLabel = [];
  const csvData = [];
  const csvXlxsData = [];

  const handleCloseDialog = () => {
    props.handleCloseDialog(false);
  };

  if (data != null && data.tableData != null && data.tableData.length > 0) {
    const isColumnUsed = (column) => {
      for (let item of data.tableData) {
        if (item[column]) {
          return true;
        }
      }
      return false;
    };

    const usedColumns =
      data != null && data.tableData != null && data.tableData.length > 0
        ? Object.keys(data.tableData[0]).filter(isColumnUsed)
        : [];

    for (let item of usedColumns) {
      csvHeader.push({ label: getMyLabel(item), key: item });
    }

    for (let item of usedColumns) {
      csvHeaderWithoutLabel.push({ id: item });
    }

    for (let itemList of data.tableData) {
      const json = {};
      const json1 = {};
      for (let item of usedColumns) {
        const v = itemList[item];
        json[item] = v;
        json1[getMyLabel(item)] = v;
      }
      csvData.push(json);
      csvXlxsData.push(json1);
    }
  }
  function getMyLabel(val) {
    if (
      data.schema[val] != null &&
      data.schema[val].name != null &&
      data.schema[val].name !== ''
    ) {
      return data.schema[val].name;
    }
    return val;
  }

  const handleCloseDialogWithExport = () => {
    if (exportFormat === 'XLSX') {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      if (noHeader) {
        const ws = XLSX.utils.json_to_sheet(csvXlxsData, { skipHeader: 1 });
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(
          wb,
          { bookType: 'xlsx', type: 'array' },
          'No Header'
        );
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'table_view' + fileExtension);
      } else {
        const ws = XLSX.utils.json_to_sheet(csvXlxsData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'table_view' + fileExtension);
      }
    } else {
      if (noHeader) {
        //csvInstanceWithoutHeader.current.link.click();
        document.getElementById('csvInstanceWithoutHeader').click();
      } else {
        csvInstance.current.link.click();
      }
    }
    props.handleCloseDialog(true, noHeader, exportFormat, exportDataSet);
  };

  const tooltipText =
    'Selecting this will remove the column headers of the table, i.e. there will be nothing but data in the exported file.';
  const LabelWithTooltip = (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Typography>Do Not Export Header</Typography>
      <Tooltip title={tooltipText}>
        <InfoIcon color='disabled'></InfoIcon>
      </Tooltip>
    </Box>
  );
  const csvInstance = useRef();
  const csvInstanceWithoutHeader = useRef();
  return (
    <div>
      <Dialog
        open={props.openDialog}
        keepMounted
        TransitionComponent={Transition}
      >
        <DialogTitle>Export</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={noHeader}
                    checked={noHeader}
                    onChange={(e) => setNoHeader(e.target.checked)}
                  />
                }
                label={LabelWithTooltip}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.exportDialogSelect}>
                <InputLabel
                  id='export-format-label'
                  className={classes.exportDialogLabel}
                >
                  Export Format
                </InputLabel>
                <Select
                  value={exportFormat}
                  variant='standard'
                  labelId='export-format-label'
                  onChange={(e) => setExportFormat(e.target.value)}
                >
                  <MenuItem value='XLSX'>XLSX</MenuItem>
                  <MenuItem value='CSV'>CSV</MenuItem>
                  {/* <MenuItem value='JSON'>JSON</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.exportDialogSelect}>
                <InputLabel id='export-dataset-label'>Data Set</InputLabel>
                <Select
                  value={exportDataSet}
                  variant='standard'
                  labelId='export-dataset-label'
                >
                  <MenuItem value='Entire Table'>Entire Table</MenuItem>
                  {/* <MenuItem value='Part of the Table'>
                    Part of the Table
                  </MenuItem>
                  <MenuItem value='First 5 rows'>First 5 rows</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='error'>
            Cancel
          </Button>
          <Button onClick={handleCloseDialogWithExport} variant='contained'>
            Export
          </Button>
        </DialogActions>
      </Dialog>
      <CSVLink
        data={csvData}
        headers={csvHeader}
        filename='table_view.csv'
        className='hidden'
        ref={csvInstance}
        target='_blank'
      ></CSVLink>
      <div style={{ display: 'none' }}>
        <CsvDownloader
          filename='table_view'
          id='csvInstanceWithoutHeader'
          target='_blank'
          extension='.csv'
          datas={csvData}
          ref={csvInstanceWithoutHeader}
          columns={csvHeaderWithoutLabel}
          noHeader={true}
          text=''
        ></CsvDownloader>
      </div>
    </div>
  );
}

export default TableViewerExportDialog;
