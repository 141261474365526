import { AppBar } from '@mui/material';
import React from 'react';
import HeaderContent from './HeaderContent';

export const HEADER_HEIGHT = 64;

export const Header = ({ isMobile, toggleMobileMenu, logo, setLogo }) => {
  return (
    <AppBar
      position='sticky'
      style={{ height: HEADER_HEIGHT, flexDirection: 'row', boxShadow: 'none' }}
      color='white'
    >
      <HeaderContent
        logo={logo}
        setLogo={setLogo}
        isMobile={isMobile}
        toggleMobileMenu={toggleMobileMenu}
      />
    </AppBar>
  );
};
