import React from 'react';
import {
  Button as MuiButton,
  ButtonGroup,
  MenuItem,
  MenuList,
  Grow,
  Paper,
  Popper,
  ClickAwayListener,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Button = withStyles({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
})(MuiButton);

function ButtonWithTooltip({ children, tooltip, ...rest }) {
  return (
    <Tooltip title={tooltip} disableInteractive>
      <Button {...rest} component='div'>
        {children}
      </Button>
    </Tooltip>
  );
}

export default function SplitButton({
  options = [],
  variant = 'contained',
  size = 'small',
  startIcon = null,
  callOnSelect = true,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = (index) => {
    //defaults to selectedIndex but can pass in index if called manually in case state hasn't updated (prevents needing to use a timeout)
    index = typeof index === 'number' ? index : selectedIndex;

    if (
      typeof options[index]?.callback === 'function' &&
      !options[index]?.disabled
    ) {
      options[index].callback();
    }
  };

  const handleMenuItemClick = (e, index) => {
    setSelectedIndex(index);
    setOpen(false);
    if (callOnSelect) {
      handleClick(index);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup ref={anchorRef} variant={variant} size={size}>
        <ButtonWithTooltip
          startIcon={options[selectedIndex]?.icon || startIcon}
          tooltip={options[selectedIndex]?.tooltip || ''}
          onClick={handleClick}
          disabled={options[selectedIndex]?.disabled}
        >
          {options[selectedIndex]?.text}
        </ButtonWithTooltip>
        <Button size='small' component='div' onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <Tooltip
                      key={index}
                      title={option.tooltip || ''}
                      disableInteractive
                    >
                      <div>
                        <MenuItem
                          key={option?.text}
                          disabled={option?.disabled}
                          selected={index === selectedIndex}
                          onClick={(e) => handleMenuItemClick(e, index)}
                        >
                          {option?.text}
                        </MenuItem>
                      </div>
                    </Tooltip>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
