import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import {
  UNSAVED_CHANGES_CONFIRM_MESSAGE,
  UnsavedChangesPrompt,
} from '../UnsavedChangesPrompt';
import usePrevious from '../../utils/hooks/usePrevious.js';

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: '1rem',
  },
}));

function FormDialog({
  children,
  open,
  setOpen,
  onOpen,
  onClose,
  title,
  cancelButtonText,
  saveButtonText,
  formikProps: overrideFormikProps,
  maxWidth,
  checkUnsavedChanges,
  loading,
}) {
  const classes = useStyles();
  const prevProps = usePrevious({ open });

  useEffect(() => {
    if (open && !prevProps.open && typeof onOpen === 'function') {
      onOpen();
    }
  }, [prevProps, open, onOpen]);

  const handleClose = (hasUnsavedChanges) => {
    if (
      !checkUnsavedChanges ||
      !hasUnsavedChanges ||
      window.confirm(UNSAVED_CHANGES_CONFIRM_MESSAGE)
    ) {
      if (typeof onClose === 'function') {
        onClose();
      }
      setOpen(false);
    }
  };

  const defaultFormikProps = {
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  };

  return (
    <Formik {...Object.assign(defaultFormikProps, overrideFormikProps)}>
      {(formikRenderProps) => (
        <Dialog
          open={open}
          onClose={() => {
            handleClose(formikRenderProps.dirty);
          }}
          maxWidth={maxWidth || 'md'}
          fullWidth
          aria-labelledby='form-dialog-title'
          TransitionProps={{
            onEnter: () => {
              formikRenderProps.resetForm(); //since formik wraps the dialog so we can use the dirty check in onClose, we must manually reset the form to its initial state when the dialog opens
            },
          }}
        >
          <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
          <Form>
            <DialogContent dividers>
              {children(formikRenderProps)}
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button
                onClick={() => {
                  handleClose(formikRenderProps.dirty);
                }}
                disabled={loading}
              >
                {cancelButtonText || 'Cancel'}
              </Button>
              <LoadingButton
                onClick={formikRenderProps.handleSubmit}
                disabled={
                  loading || checkUnsavedChanges
                    ? !formikRenderProps.dirty
                    : !formikRenderProps.isValid
                }
                variant='contained'
                color='primary'
                size='large'
                loading={loading}
              >
                {saveButtonText || 'Create'}
              </LoadingButton>
            </DialogActions>
          </Form>
          {checkUnsavedChanges && (
            <UnsavedChangesPrompt hasUnsavedChanges={formikRenderProps.dirty} />
          )}
        </Dialog>
      )}
    </Formik>
  );
}

export default FormDialog;
