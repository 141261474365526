import { v4 as uuidv4 } from 'uuid';

function generatePassword() {
  let password = uuidv4();
  password.replace('-', '');

  // iterate over password, capitalizing some
  // i know i (matt) did this, it is so hacky :(
  password = [...password].forEach((c, i) => {
    if (typeof c === 'string') {
      // every other character to upper case
      return i % 2 === 0 ? c.toUpperCase() : c;
    }
    return c;
  });

  return password;
}

export default generatePassword;
