import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Tooltip, CircularProgress } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import RichTextEditor from '../../../../components/RichTextEditor';
import SimpleSaveActionBar from '../../../../components/SimpleSaveActionBar';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import UnsavedChangesPrompt from '../../../../components/UnsavedChangesPrompt';
import Accordion from '../../../../components/Accordion';
import { useAuthContext } from '../../../../utils/auth/hooks';
import { useUnsavedChanges, useGetOrgByName } from '../../../../utils/hooks';
import { Header, QuestionList } from './components';
import { setQueryStringParameter } from '../../../../utils/routes';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  accordionTitle: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  helpIcon: {
    fontSize: '1.25rem',
    marginLeft: '.5rem',
    marginTop: '.25rem',
    color: theme.palette.secondary.main,
  },
  loadingContainer: {
    margin: '3rem 0',
    textAlign: 'center',
    '& .MuiCircularProgress-root': {
      width: '2rem',
      height: '2rem',
    },
  },
  notFoundWrapper: {
    textAlign: 'center',
    fontSize: '1.25rem',
    margin: '3rem 0',
  },
}));

export default function Configuration() {
  const classes = useStyles();
  const [iqcxAccountId, setIqcxAccountId] = useState(
    new URLSearchParams(window.location.search)?.get('iqcxAccountId') || ''
  );
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signature, setSignature] = useState('');
  const [defaultSignature, setDefaultSignature] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const { user, sso, logout } = useAuthContext();
  const history = useHistory();
  const {
    loading: loadingOrg,
    org,
    error,
    clearError,
  } = useGetOrgByName(user.organization);
  const signatureEditorRef = useRef(null);

  const { hasUnsavedChanges, setSaved, savedValues } = useUnsavedChanges({
    questions,
    signature: signature === '<p><br></p>' ? '' : signature,
  });

  useEffect(() => {
    if (error) {
      //this seems bad
      clearError();
    }
  }, [error, clearError]);

  useEffect(() => {
    if (!loadingOrg && org?.config) {
      if (!iqcxAccountId) {
        setIqcxAccountId(
          org?.config?.riskMessenger?.iQCXAccts?.[0]?.name || ''
        );
      } else {
        getConfigData();
      }
    }
    //eslint-disable-next-line
  }, [loadingOrg, org, iqcxAccountId]);

  const getConfigData = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    let headers = {};
    try {
      const { idToken } = await Auth.currentSession();
      headers = {
        Authorization: 'Bearer ' + idToken.jwtToken,
      };
    } catch (e) {
      setLoading(false);
      setSignature(false);
      headers = {};
      toast.error(
        'Your session has expired. Please sign in again to continue.'
      );
      logout();
      if (sso) {
        history.push('/access-denied');
      } else {
        history.push('/login');
      }
    }
    // TODO: this should use the useApiRequest hook
    Axios.get(
      `${process.env.REACT_APP_INVOKE_URL}/riskmessenger/configuration?iqcxAccountId=${iqcxAccountId}`,
      { headers: headers }
    )
      .then((res) => {
        const data = JSON.parse(res.request.response);
        setQuestions(data.questions);
        setSignature(data.signature);
        setSaved({
          questions: data.questions,
          signature: data.signature,
        });
        setDefaultSignature(data.signature);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setSignature(false); //signature = false - means an error occurred (saves us using another value)
        toast.error(
          'An error occurred while loading your Risk Messenger configuration.'
        );
      });
    // eslint-disable-next-line
  }, [loading, iqcxAccountId]);

  const toggleQuestionValue = useCallback((id, key) => {
    setQuestions((prevQuestions) => {
      const indexToToggle = prevQuestions.findIndex((_) => _?.id === id);
      return prevQuestions.map((item, index) => {
        return index === indexToToggle ? { ...item, [key]: !item[key] } : item;
      });
    });
  }, []);

  const getChangedQuestions = () => {
    const parsedSavedQuestions = JSON.parse(savedValues)?.questions;

    return (
      questions
        //eslint-disable-next-line array-callback-return
        .map((q) => {
          const savedQ = parsedSavedQuestions.find((sq) => sq.id === q.id);
          if (
            q.captureComments !== savedQ.captureComments ||
            q.domEnabled !== savedQ.domEnabled ||
            q.intEnabled !== savedQ.intEnabled
          ) {
            return q;
          }
        })
        .filter((_) => !!_)
    );
  };

  const handleSubmit = async () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    let headers = {};
    try {
      const { idToken } = await Auth.currentSession();
      headers = {
        Authorization: 'Bearer ' + idToken.jwtToken,
      };
    } catch (e) {
      setSubmitting(false);
      headers = {};
      toast.error(
        'Your session has expired. Please sign in again to continue.'
      );
      logout();
      if (sso) {
        history.push('/access-denied');
      } else {
        history.push('/login');
      }
    }

    // TODO: this should be switched to useApiRequest hook
    Axios.put(
      `${process.env.REACT_APP_INVOKE_URL}/riskmessenger/configuration?iqcxAccountId=${iqcxAccountId}`,
      {
        questions: getChangedQuestions(), //only save changed questions
        signature,
      },
      { headers: headers }
    )
      .then((res) => {
        setSaved({
          questions: questions,
          signature: signature,
        });
        setDefaultSignature(signature);
        toast.success('Your changes have been saved.');
        setSubmitting(false);
      })
      .catch((e) => {
        setSubmitting(false);
        toast.error('An error occurred and your changes were not saved.');
      });
  };

  const handleSignatureChange = useCallback((data) => {
    setSignature(data);
  }, []);

  const handleIqcxChange = useCallback((e) => {
    const value = e.target.value;

    setIqcxAccountId(value);
    setQueryStringParameter('iqcxAccountId', value);
  }, []);

  const handleReset = () => {
    setQuestions(JSON.parse(savedValues)?.questions);
    //eslint-disable-next-line no-unused-expressions
    signatureEditorRef?.current?.resetDefault();
  };

  return (
    <>
      <UnsavedChangesPrompt
        hasUnsavedChanges={
          loadingOrg || loading || !questions.length ? false : hasUnsavedChanges
        } /* don't want to show the prompt if loading or an error occurred loading the questions */
      />

      <form>
        <Header
          org={org}
          iqcxAccountId={iqcxAccountId}
          loading={loading || loadingOrg}
          handleIqcxChange={handleIqcxChange}
        />

        <Accordion
          defaultExpanded
          Header={
            <>
              <span className={classes.accordionTitle}>Questions</span>
              <Tooltip
                title='Toggle risk messenger questions on/off for your email notifications.'
                placement='right-end'
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </>
          }
        >
          <div style={{ pointerEvents: submitting ? 'none' : 'all' }}>
            {loadingOrg || loading ? (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            ) : (
              <QuestionList
                questions={questions}
                toggleQuestionValue={toggleQuestionValue}
                retryFn={getConfigData}
              />
            )}
          </div>
        </Accordion>

        <br />

        <Accordion
          defaultExpanded
          Header={
            <>
              <span className={classes.accordionTitle}>
                Customized Signature
              </span>
              <Tooltip
                title='Design a custom signature to be included in your risk messenger email notifications.'
                placement='right-end'
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </>
          }
        >
          <>
            {loadingOrg || loading ? (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            ) : signature === false ? (
              <div className={classes.notFoundWrapper}>
                <p>Your custom signature could not be found at this time.</p>
                <Button onClick={getConfigData}>Retry</Button>
              </div>
            ) : (
              <>
                <br />
                <RichTextEditor
                  placeholder='Customize your signature here...'
                  disabled={submitting}
                  onChangeHTML={handleSignatureChange}
                  defaultContentHTML={defaultSignature}
                  minHeight={160}
                  ref={signatureEditorRef}
                />
              </>
            )}
          </>
        </Accordion>

        <br />

        <SimpleSaveActionBar
          onSubmit={handleSubmit}
          onCancel={hasUnsavedChanges ? handleReset : false}
          isSubmitting={submitting}
          hasUnsavedChanges={hasUnsavedChanges}
        />
      </form>
    </>
  );
}
