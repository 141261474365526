"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = _typeof;
function _typeof(obj) {
    "@swc/helpers - typeof";
    return obj && obj.constructor === Symbol ? "symbol" : typeof obj;
}
;
