import React from 'react';
import {
  useParams,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../components/PageTitle';
import Loader from '../../components/Loader';
import SectionNavigation from '../../components/SectionNavigation';
import Details from './Sections/Details';
import { Products } from './Sections/Products';
import ErrorScreen from '../../components/ErrorScreen';
import RootContainer from '../../components/RootContainer.js';
import Breadcrumbs from '../../components/Breadcrumbs';
import useGetOrgByName from '../../utils/hooks/useGetOrgByName';

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  sectionNav: {
    width: 'auto',
    height: 'fit-content',
    minWidth: '220px',
    maxWidth: '360px',
    backgroundColor: theme.palette.background.paper,
    marginRight: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '.5rem',
    '& > nav > a:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  sectionNavIcon: {
    minWidth: 0,
  },
  section: {
    flex: 1,
  },
}));

const Sections = (props) => {
  const { section } = useParams();

  switch (section) {
    case 'products':
      return <Products {...props} />;
    default:
      return <Details {...props} />;
  }
};

export const OrganizationEdit = () => {
  const classes = useStyles();
  const {
    path,
    url,
    params: { orgName },
  } = useRouteMatch();

  //have to get this separate to keep the path and url sans section for the nested section router below
  const sectionMatch = useRouteMatch('/organization/:orgName/:section');
  const section = sectionMatch?.params?.section;
  const { loading, error, org, refreshOrg } = useGetOrgByName(orgName);

  if (loading) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <Loader />
      </div>
    );
  }

  let crumbsTrail = {
    Admin: null,
    Organizations: '/organizations',
  };

  crumbsTrail[org ? orgName : 'N/A'] = url;
  if (section) {
    let sectionTitle = 'N/A';
    //eslint-disable-next-line default-case
    switch (section) {
      case 'details':
        sectionTitle = 'Profile';
        break;
      case 'products':
        sectionTitle = 'Products';
        break;
    }
    crumbsTrail[sectionTitle] = `${url}/${section}`;
  }

  return (
    <RootContainer>
      <PageTitle title='Edit Organization' />
      <Breadcrumbs trail={crumbsTrail} />
      <br />

      <div>
        <SectionNavigation
          sections={[
            {
              text: 'Profile',
              label: 'details',
              url: `${url}/details`,
            },
            {
              text: 'Product Access',
              label: 'products',
              url: `${url}/products`,
            },
          ]}
        />

        <div>
          {loading ? (
            <Loader />
          ) : !orgName || (error && error !== 'TOKEN FAILURE') ? (
            <ErrorScreen
              title='Not Found'
              code='404'
              message='The organization you were looking for does not exist'
              fontSize='.75rem'
            />
          ) : (
            <Switch>
              <Route path={`${path}/:section`}>
                <Sections org={org} refreshOrg={refreshOrg} />
              </Route>
              <Redirect to={`${path}/details`} />
            </Switch>
          )}
        </div>
      </div>
    </RootContainer>
  );
};
