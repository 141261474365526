import React, { memo } from 'react';
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../../../components/PageTitle';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '26px',
    fontWeight: '300',
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: '-.5rem',
    marginBottom: 0,
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  iqcxAccountSelect: {
    paddingRight: '3rem',
  },
  selectItem: {
    whiteSpace: 'normal',
    paddingRight: '3rem',
    display: 'block',
  },
}));

const Header = memo(({ loading, org, iqcxAccountId, handleIqcxChange }) => {
  const classes = useStyles();

  return (
    <>
      <PageTitle
        title='Manage Configuration'
        subtitle='Customize the details of the Risk Messenger emails that are sent out
      to your clients.'
      />
      <Breadcrumbs
        trail={{
          Products: null,
          'Risk Messenger': '/risk-messenger',
        }}
      />
      <br />

      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {org?.config?.riskMessenger?.iQCXAccts?.length > 1 && (
            <FormControl variant='outlined'>
              <InputLabel htmlFor='iqcx-account-id'>iQCX Account ID</InputLabel>
              <Select
                value={iqcxAccountId}
                onChange={handleIqcxChange}
                label='iQCX Account ID'
                inputProps={{
                  name: 'iqcx-account-id',
                  id: 'iqcx-account-id',
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                disabled={loading}
                className={classes.iqcxAccountSelect}
              >
                {org?.config?.riskMessenger?.iQCXAccts?.map((iqcxAcct) => (
                  <MenuItem
                    key={iqcxAcct?.name}
                    value={iqcxAcct?.name}
                    className={classes.selectItem}
                  >
                    {iqcxAcct?.desc}
                    <br />
                    <span style={{ fontSize: '.85rem', color: 'grey' }}>
                      {iqcxAcct?.name}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
    </>
  );
});

export default Header;
