import React from 'react';
import { Form, Formik } from 'formik';
import organizationSchema from '../../../data/schemas/organizationSchema';
import PageTitle from '../../../components/PageTitle';
import TextInput from '../../../components/formik/TextInput';
import Checkbox from '../../../components/formik/Checkbox';
import { useHistory } from 'react-router-dom';
import NameAndDescList from '../../../components/formik/NameAndDescList';
import { useApiPut } from '../../../utils/hooks';
import ToggleAccordion from '../../../components/formik/ToggleAccordion';
import SimpleSaveActionBar from '../../../components/SimpleSaveActionBar';
import UnsavedChangesPrompt from '../../../components/UnsavedChangesPrompt';

const Details = ({ org, refreshOrg }) => {
  const history = useHistory();
  const { fire: updateOrg } = useApiPut('/organizations');

  const initialValues = {
    name: org?.name,
    displayName: org?.displayName,
    salesforceAccountNumber:
      org?.salesforceAccountNumber === undefined
        ? ''
        : org?.salesforceAccountNumber,
    isActive: org?.isActive,
    PK: org?.PK,
    SK: org?.SK,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        await updateOrg(values);
        await refreshOrg();
        actions.setSubmitting(false);
      }}
      validationSchema={organizationSchema}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        dirty,
        resetForm,
      }) => (
        <>
          <UnsavedChangesPrompt hasUnsavedChanges={dirty} />

          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <PageTitle
              title=''
              subtitle="Modify the organization's basic settings."
            />
            <Checkbox isSwitch label='Active' name='isActive' />

            <TextInput label='Name' name='name' disabled variant='filled' />

            <TextInput
              label='Display Name'
              name='displayName'
              variant='filled'
            />
            <TextInput
              label='Salesforce Account Number'
              name='salesforceAccountNumber'
              variant='filled'
            />

            <ToggleAccordion
              name='usesOwnIdp'
              value={values?.usesOwnIdp}
              setFieldValue={setFieldValue}
              label='Uses own IDP'
            >
              <NameAndDescList
                name='config.providers'
                value={values?.config?.providers || []}
                label='IDP providers'
                nameLabel='Provider'
              />
            </ToggleAccordion>

            <br />

            <SimpleSaveActionBar
              onCancel={() => {
                resetForm();
                setTimeout(() => {
                  history.push('/organizations'); //hacky way to wait for resetForm to finish so the unsaved prompt doesn't show
                }, 50);
              }}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              hasUnsavedChanges={dirty}
            />
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Details;
