import React, { memo } from 'react';
import {
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  itemAvatar: {
    //the values for this itemAvatar and headerAvatar are very specific and may not always line up, may need to switch to using an actual table instead of the material ListItem
    display: 'flex',
    gap: '2.75rem',
    marginLeft: '.5rem',
    marginRight: '2rem',
    alignSelf: 'flex-start',
  },
  itemText: {
    '& > span': {
      display: 'flex',
      '& > hr': {
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, .08)',
        borderBottomWidth: 'thin',
        flex: 1,
        alignSelf: 'center',
        marginLeft: '1rem',
      },
    },
  },
  listSecondaryAction: {
    top: 0,
    right: 0,
    position: 'relative',
    transform: 'none',
    alignItems: 'center',
    display: 'flex',
    padding: '0 16px',
    textAlign: 'center',
    '& > .MuiSwitch-root': {
      marginRight: '2rem', //offset for the longer title
    },
  },
}));

const Question = memo(({ item, toggleQuestionValue }) => {
  const classes = useStyles();
  const enabled = item?.domEnabled || item?.intEnabled;

  return (
    <ListItem>
      <ListItemAvatar className={classes.itemAvatar}>
        <Checkbox
          checked={item?.domEnabled}
          onClick={() => {
            toggleQuestionValue(item?.id, 'domEnabled');
          }}
        />
        <Checkbox
          checked={item?.intEnabled}
          onClick={() => {
            toggleQuestionValue(item?.id, 'intEnabled');
          }}
        />
      </ListItemAvatar>
      <ListItemText
        className={classes.itemText}
        primary={
          <>
            <span>{item?.question}</span>
            <hr />
          </>
        }
        style={{ opacity: enabled ? 1 : 0.65 }}
      />
      <ListItemSecondaryAction className={classes.listSecondaryAction}>
        <Switch
          checked={item?.captureComments}
          disabled={!enabled}
          onClick={() => {
            toggleQuestionValue(item?.id, 'captureComments');
          }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
});

export default Question;
