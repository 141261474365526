import React, { useState, useContext } from 'react';
import {
  Button,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Alert, LoadingButton } from '@mui/lab';
import EulaText from '../components/EulaText';
import { EulaAuthContext } from '../utils/eulaAuth';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import { RISK_MESSENGER_PUT_URL } from '../utils/auth/constants';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    height: '100%',
  },
  columnContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  form: {
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  logo: {
    display: 'block',
    width: '75%',
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
    margin: '0 auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
  },
  messageText: {
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  eulaTextWrapper: {
    border: '1px solid #CCCCCC',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    boxSizing: 'border-box',
    padding: '1rem',
  },
  flexExpandOuter: {
    flex: 1,
    position: 'relative',
  },
  flexExpandInner: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  actions: {
    textAlign: 'right',
    '& button:not(:last-child)': {
      marginRight: `${theme.spacing(1)}`,
    },
  },
}));

const STATUSES = {
  ACCEPT: 'accepted',
  DECLINE: 'declined',
};

const EulaVendorLogo = () => {
  const classes = useStyles();
  const tokenData = useContext(EulaAuthContext);

  const formatAgencyName = (val) => {
    return !val
      ? ''
      : val
          .replace(/\s/g, '-')
          .replace(/[^\w-]/gi, '')
          .toLowerCase();
  };

  return (
    <div
      className={classes.logo}
      style={{
        backgroundImage: `url(${
          process.env.REACT_APP_STATIC_ASSETS_URL
        }eula-logos/${formatAgencyName(
          tokenData?.agencyName
        )}.png), url(eula-risk-messenger-logo.png)`,
      }}
    />
  );
};

export const Eula = () => {
  const classes = useStyles();
  const tokenData = useContext(EulaAuthContext);
  const [hasRead, setHasRead] = useState(false);
  const [userPerformedAction, setUserPerformedAction] = useState(null);
  const [showDeclineConfirm, setShowDeclineConfirm] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [performingAction, setPerformingAction] = useState(false);

  const handleDecline = async () => {
    if (!!userPerformedAction) {
      return; //do nothing if user has already accepted or declined
    }

    setShowDeclineConfirm(true);
  };

  const handleAccept = async () => {
    if (!!userPerformedAction) {
      return; //do nothing if user has already accepted or declined
    }

    if (!hasRead) {
      setMessage(
        'Please click the checkbox to confirm that you have read the EULA terms first, then click accept.'
      );
      setShowMessage(true);
      return;
    }

    setStatus(STATUSES.ACCEPT);
  };

  const setStatus = async (status) => {
    if (
      ![STATUSES.ACCEPT, STATUSES.DECLINE].includes(status) ||
      !!performingAction
    ) {
      return;
    }

    setPerformingAction(status);
    const res = await setStatusAPI(status);
    if (res) {
      setUserPerformedAction(status);
    }
    setPerformingAction(false);
  };

  const setStatusAPI = async (status) => {
    try {
      const res = await fetch(RISK_MESSENGER_PUT_URL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({ token: tokenData?.token, status }),
      });

      const json = await res.json();
      console.log('set status', json);
      return true;
    } catch (e) {
      toast.error('An unexpected error occurred.');
      return false;
    }
  };

  const getErrorMessage = (statusCode) => {
    switch (+statusCode) {
      case 410:
        return 'The link you clicked has expired and is no longer valid. Please contact an administrator for help accepting the EULA Terms and Conditions.';
      default:
        return 'An unexpected error occurred and we could not validate your access token. Please contact an administrator for help accepting the EULA Terms and Conditions.';
    }
  };

  return (
    <>
      <div className={classes.main}>
        <Grid container spacing={0} style={{ height: '100%' }}>
          <Grid item xs={1} sm={1} md={3} />
          <Grid item xs={10} sm={10} md={6}>
            <div className={classes.columnContainer}>
              <form className={classes.form}>
                {!tokenData?.token || tokenData?.error ? (
                  <>
                    <EulaVendorLogo />

                    <div style={{ marginTop: '4rem', fontSize: '1.2rem' }}>
                      {tokenData?.error ? (
                        <>
                          <p>{getErrorMessage(tokenData?.statusCode)}</p>
                          <p>You may now close this page.</p>
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginBottom: '1rem' }}>
                      <EulaVendorLogo />

                      <div className={classes.messageText}>
                        <p>
                          Hello
                          {tokenData?.passengerName
                            ? ' ' + tokenData?.passengerName
                            : ''}
                          ,
                        </p>
                        <p>
                          You must accept these Terms and Conditions to begin
                          receiving destination based risk emails to{' '}
                          <b>{tokenData?.email}</b> on behalf of{' '}
                          <b>{tokenData?.agencyName || 'your travel agency'}</b>
                          .
                        </p>
                      </div>
                    </div>

                    <div className={classes.flexExpandOuter}>
                      <div className={classes.flexExpandInner}>
                        <div className={classes.eulaTextWrapper}>
                          <EulaText />
                        </div>
                      </div>
                    </div>

                    <div style={{ marginTop: '1rem' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={hasRead}
                            onChange={() => {
                              setHasRead(!hasRead);
                            }}
                            name='hasRead'
                            color='primary'
                            disabled={
                              !!userPerformedAction || !!performingAction
                            }
                          />
                        }
                        label='By clicking, you acknowledge that you have read the EULA terms'
                      />

                      {!!userPerformedAction && (
                        <>
                          <Alert
                            severity={
                              userPerformedAction === STATUSES.ACCEPT
                                ? 'success'
                                : 'error'
                            }
                          >
                            {userPerformedAction === STATUSES.ACCEPT
                              ? 'Thank you. You have accepted our Terms and Conditions. No further action is required, you may close this page.'
                              : 'You have declined our Terms and Conditions. You cannot receive Risk Messenger™ notifications. You may close this page.'}
                          </Alert>
                          <br />
                        </>
                      )}

                      <div className={classes.actions}>
                        <LoadingButton
                          variant='outlined'
                          color='secondary'
                          onClick={handleDecline}
                          loading={performingAction === STATUSES.DECLINE}
                          disabled={!!userPerformedAction || !!performingAction}
                        >
                          DECLINE
                        </LoadingButton>
                        <LoadingButton
                          variant='contained'
                          color='primary'
                          size='large'
                          onClick={handleAccept}
                          loading={performingAction === STATUSES.ACCEPT}
                          disabled={!!userPerformedAction || !!performingAction}
                        >
                          ACCEPT
                        </LoadingButton>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={3} />
        </Grid>
      </div>
      <Dialog
        open={showMessage}
        onClose={() => {
          setShowMessage(false);
        }}
        aria-describedby='message-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='message-dialog-description'>
            {message || ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowMessage(false);
            }}
            color='primary'
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDeclineConfirm}
        onClose={() => {
          setShowDeclineConfirm(false);
        }}
        aria-labelledby='decline-confirm-dialog-title'
        aria-describedby='decline-confirm-dialog-description'
      >
        <DialogTitle id='decline-confirm-dialog-title'>
          Are you sure you want to decline?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='decline-confirm-dialog-description'>
            If you decline these Terms and Conditions, you will not receive the
            Risk Messenger™ notifications that{' '}
            {tokenData?.agencyName || 'your travel agency'} requested for you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDeclineConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setStatus(STATUSES.DECLINE);
              setShowDeclineConfirm(false);
            }}
            color='primary'
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
