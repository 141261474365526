// had to make this component to control the two tone color of the icon for setting/displaying the selected font color

import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  svgIconRoot: {
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentColor',
  },
}));

export default memo(({ color, fontSize }) => {
  const classes = useStyles();
  const fontSizes = { small: '1.25rem', large: '2.2rem' };

  return (
    <svg
      className={`MuiSvgIcon-root ${classes.svgIconRoot}`}
      focusable='false'
      viewBox='0 0 24 24'
      aria-hidden='true'
      tabIndex='-1'
      title='FormatColorTextTwoTone'
      style={{ fontSize: fontSizes?.[fontSize] || '1.5rem' }}
    >
      <path fill={color ? `${color}` : 'inherit'} d='M0 20h24v4H0z'></path>
      <path d='M5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2L5.5 17zm8.88-5H9.62L12 5.67 14.38 12z'></path>
    </svg>
  );
});
