import React from 'react';
import Configuration from './Sections/Configuration';
import RootContainer from '../../components/RootContainer';

export const RiskMessenger = () => {
  return (
    <RootContainer>
      <Configuration />
    </RootContainer>
  );
};
