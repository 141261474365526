import React from 'react';
import Loader from '../../components/Loader';
import { Grid } from '@mui/material';
import TextInput from '../../components/formik/TextInput';
import Checkbox from '../../components/formik/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function Notifications({ detailsRequest }) {
  const { isLoading: loading, error } = detailsRequest;

  const classes = useStyles();
  if (loading || error) return <Loader />;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <b>Email</b>
        </Grid>
        <Grid item xs={12} sm={1} className={classes.cell}>
          <Checkbox label='' name='notifyEmailsStatus' isSwitch />
        </Grid>
        <Grid item xs={8} sm={8} className={classes.cell}>
          <TextInput name='notifyEmails' placeholder='Email address' />
        </Grid>
        <Grid
          item
          xs={4}
          sm={3}
          style={{ alignItems: 'center' }}
          className={classes.cell}
        >
          <InfoIcon />
        </Grid>
      </Grid>
    </div>
  );
}

export default Notifications;
