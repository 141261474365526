import * as yup from 'yup';

const organizationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .max(40, 'Name may not exceed 40 characters')
    .matches(
      /^[a-z0-9]+$/i,
      'Name may only contain alphanumeric characters (i.e. no spaces or symbols)'
    ),
  displayName: yup.string().required('Display name is required'),
  salesforceAccountNumber: yup
    .string()
    .required('Salesforce Account Number is required')
    .max(40, 'Salesforce Account Number may not exceed 40 characters')
    .matches(
      /^[0-9]+$/i,
      'Salesforce Account Number  may only contain numeric values(i.e. no spaces, characters and symbols)'
    ),
  isActive: yup.bool(),
  usesOwnIdp: yup.bool(),
});

export default organizationSchema;
