import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RecordsCurrentUserTable = (props) => (
  <SvgIcon {...props} width='40px' height='40px' viewBox='0 0 40 40'>
    <title>Records-Current-User-Table</title>
    <g
      id='User-Tables'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Workflow---User-Table-Upload-1.5.1'
        transform='translate(-807.000000, -290.000000)'
      >
        <g id='Content' transform='translate(234.000000, 166.000000)'>
          <g id='Upload-Preview' transform='translate(548.000000, 74.000000)'>
            <g id='Content' transform='translate(20.000000, 13.000000)'>
              <g id='Icons-v3' transform='translate(5.000000, 37.000000)'>
                <g id='table' transform='translate(6.000000, 7.000000)'>
                  <path
                    d='M16.875,24.75 C22.5,24.75 24.75,22.5 24.75,16.875 L24.75,10.125 C24.75,4.5 22.5,2.25 16.875,2.25 L10.125,2.25 C4.5,2.25 2.25,4.5 2.25,10.125 L2.25,16.875 C2.25,22.5 4.5,24.75 10.125,24.75 L16.875,24.75 Z'
                    id='Vector'
                    strokeOpacity='0.6'
                    stroke='#2196F3'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeDasharray='0,0'
                    fillRule='nonzero'
                  ></path>
                  <line
                    x1='12.0711749'
                    y1='17.5295136'
                    x2='25.8187825'
                    y2='17.457986'
                    id='Vector'
                    strokeOpacity='0.6'
                    stroke='#2196F3'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeDasharray='0,0'
                    transform='translate(18.944979, 17.493750) rotate(90.298102) translate(-18.944979, -17.493750) '
                  ></line>
                  <line
                    x1='6.44617494'
                    y1='17.5295136'
                    x2='20.1937825'
                    y2='17.457986'
                    id='Vector'
                    strokeOpacity='0.6'
                    stroke='#2196F3'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeDasharray='0,0'
                    transform='translate(13.319979, 17.493750) rotate(90.298102) translate(-13.319979, -17.493750) '
                  ></line>
                  <line
                    x1='0.821174939'
                    y1='17.5295136'
                    x2='14.5687825'
                    y2='17.457986'
                    id='Vector'
                    strokeOpacity='0.6'
                    stroke='#2196F3'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeDasharray='0,0'
                    transform='translate(7.694979, 17.493750) rotate(90.298102) translate(-7.694979, -17.493750) '
                  ></line>
                  <line
                    x1='2.50872803'
                    y1='19.2375'
                    x2='24.1312294'
                    y2='19.125'
                    id='Vector'
                    strokeOpacity='0.6'
                    stroke='#2196F3'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeDasharray='0,0'
                  ></line>
                  <line
                    x1='2.50872803'
                    y1='14.7375'
                    x2='24.1312294'
                    y2='14.625'
                    id='Vector'
                    strokeOpacity='0.6'
                    stroke='#2196F3'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeDasharray='0,0'
                  ></line>
                  <line
                    x1='2.50872803'
                    y1='10.2375'
                    x2='24.1312294'
                    y2='10.125'
                    id='Vector'
                    strokeOpacity='0.6'
                    stroke='#2196F3'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeDasharray='0,0'
                  ></line>
                  <path
                    d='M27,0 L27,27 L0,27 L0,0 L27,0 Z'
                    id='Vector'
                    opacity='0'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default RecordsCurrentUserTable;
