import React, { memo, useState, useCallback, useRef } from 'react';
import {
  Tooltip,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  CircularProgress,
  Fade,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import UnpublishedChangesIcon from '@mui/icons-material/HourglassTop';
import PublishedIcon from '@mui/icons-material/Check';
import DraftIcon from '@mui/icons-material/Drafts';
import MoreIcon from '@mui/icons-material/MoreVert';
import PreviewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles, useTheme } from '@mui/styles';
import { FixedSizeList } from 'react-window';
import { Preview } from './';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import Axios from 'axios';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { useTemplateContext } from './../TemplateContext';
import { Auth } from 'aws-amplify';
import { useAuthContext } from '../../../utils/auth/hooks';

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&.deleting': {
      opacity: 0.65,
      pointerEvents: 'none',
    },
    '& .MuiListItemSecondaryAction-root': {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    },
  },
  actionsMenu: {
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: '1rem',
      },
    },
  },
}));

const TemplateListItem = memo(({ data, index, style }) => {
  const theme = useTheme();
  const classes = useStyles();
  const item = data?.templates[index];
  const [active, setActive] = useState(false);
  const menuAnchorRef = useRef(null);

  let statusIcon, statusTip, statusColor;
  switch (item.status) {
    case 'published':
      statusTip = 'Published';
      statusIcon = <PublishedIcon color='success' fontSize='small' />;
      statusColor = theme.palette.success.lightest;
      break;
    case 'unpublished-changes':
      statusTip = 'Unpublished Changes';
      statusIcon = (
        <UnpublishedChangesIcon color='secondary' fontSize='small' />
      );
      statusColor = theme.palette.secondary.lightest;
      break;
    case 'draft':
    default:
      statusTip = 'Unpublished Draft';
      statusIcon = <DraftIcon sx={{ color: '#323232' }} fontSize='small' />;
      statusColor = theme.palette.default.lightest;
      break;
  }

  const handleEnter = () => {
    setActive(true);
  };

  const handleLeave = () => {
    setActive(false);
  };

  const handleSelect = (e) => {
    if (typeof data?.onSelect === 'function') {
      data.onSelect(e);
    }
    handleLeave();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target !== menuAnchorRef?.current) {
      menuAnchorRef.current.click();
    }
  };

  const isDeleting = data?.deleting?.includes(item.id);

  return (
    <ListItem
      className={clsx(classes.listItem, isDeleting && 'deleting')}
      style={style}
      key={item.id}
      divider={index < data?.templates?.length - 1}
      onKeyDown={handleKeyDown}
      secondaryAction={
        isDeleting ? (
          <CircularProgress size='1rem' color='default' />
        ) : (
          <Fade
            in={
              active ||
              (data?.menuAnchorEl &&
                menuAnchorRef?.current === data?.menuAnchorEl)
            }
          >
            <IconButton
              edge='end'
              onClick={handleSelect}
              size='small'
              ref={menuAnchorRef}
              data-template-id={item.id}
            >
              <MoreIcon fontSize='small' />
            </IconButton>
          </Fade>
        )
      }
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      onFocus={handleEnter}
      onBlur={handleLeave}
      tabIndex='0'
    >
      <ListItemAvatar style={{ minWidth: 0, marginRight: '.5rem' }}>
        <Tooltip title={statusTip}>
          <Avatar sx={{ width: 30, height: 30, bgcolor: statusColor }}>
            {statusIcon}
          </Avatar>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography noWrap style={{ fontSize: '.9rem' }}>
            {item.name}
          </Typography>
        }
      />
    </ListItem>
  );
});

//TODO: when I added menuAnchorEl to tell whether the more icon should be shown if the menu is open, it broke the memoization...
const VirtualizedTemplateList = memo(
  ({ templates, height, onSelect, menuAnchorEl, deleting }) => {
    return (
      <FixedSizeList
        itemCount={templates.length}
        itemData={{ templates, onSelect, menuAnchorEl, deleting }}
        itemSize={48}
        height={height}
        width='100%'
      >
        {TemplateListItem}
      </FixedSizeList>
    );
  }
);

const TemplateList = memo(
  ({ templates = [], height = 0, setTemplates }) => {
    const history = useHistory();
    const classes = useStyles();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const menuOpen = !!menuAnchorEl;
    const [preview, setPreview] = useState(false);
    const [deleting, setDeleting] = useState([]);
    const confirmDialogRef = useRef(null);
    const { activeRiskMessengerTemplate, refreshActiveRiskMessengerTemplate } =
      useTemplateContext();

    const handleOpenMenu = useCallback((e) => {
      setMenuAnchorEl(e.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
      setMenuAnchorEl(null);
    }, []);

    const handleEdit = useCallback(() => {
      const templateId = menuAnchorEl?.dataset?.templateId;
      history.push(`/messaging/template/${templateId}`);
      handleMenuClose();
    }, [handleMenuClose, menuAnchorEl, history]);

    const handlePreview = useCallback(() => {
      const templateId = menuAnchorEl?.dataset?.templateId;
      const template = templates.find((_) => _.id === templateId);
      if (template) {
        setPreview(template);
      } else {
        toast.error('An error occurred while loading the selected template.');
      }
      handleMenuClose();
    }, [handleMenuClose, templates, menuAnchorEl]);

    const handleDelete = useCallback(() => {
      const templateId = menuAnchorEl?.dataset?.templateId;
      const template = templates.find((_) => _.id === templateId);
      handleMenuClose();

      let message =
        'Are you sure you want to delete this template? This action cannot be undone.';
      if (activeRiskMessengerTemplate?.id === templateId) {
        message =
          'Are you sure you want to delete this template, it is currently the active Risk Messenger template? This could stop Risk Messenger emails from being sent out and this action cannot be undone.';
      } else if (template?.status !== 'draft') {
        message =
          'Are you sure you want to delete this template, a version of it is currently published and will no longer be available for distributions? This could stop emails from being sent out and this action cannot be undone.';
      }

      if (confirmDialogRef?.current) {
        const { sso, logout } = useAuthContext();
        confirmDialogRef.current.open({
          title: 'Confirm Delete',
          message,
          // TODO: this should all get converted to the useApiRequest hook
          onConfirm: async () => {
            setDeleting((prevDeleting) => [...prevDeleting, templateId]);
            let headers = {};
            try {
              const { idToken } = await Auth.currentSession();
              headers = {
                Authorization: 'Bearer ' + idToken.jwtToken,
              };
            } catch (e) {
              headers = {};
              toast.error(
                'Your session has expired. Please sign in again to continue.'
              );
              logout();
              if (sso) {
                history.push('/access-denied');
              } else {
                history.push('/login');
              }
            }

            Axios.delete(
              `${process.env.REACT_APP_INVOKE_URL}/templates/${templateId}`,
              { headers: headers }
            )
              .then(() => {
                toast.success(
                  'The selected template was successfully deleted.'
                );

                //"hacky" way of finding out if we just deleting the template being viewed without using state ( ͡~ ͜ʖ ͡°)
                const urlParts = window?.location?.pathname?.split('/');
                if (urlParts?.[urlParts?.length - 1] === templateId) {
                  history.push('/messaging');
                }

                //remove from templates list
                setTemplates((prevTemplates) =>
                  prevTemplates.filter((_) => _.id !== templateId)
                );

                //remove id from deleting array
                setDeleting((prevDeleting) =>
                  prevDeleting.filter((_) => _ !== templateId)
                );

                //if we deleted the active template, refresh in the template store
                if (activeRiskMessengerTemplate?.id === templateId) {
                  refreshActiveRiskMessengerTemplate();
                }
              })
              .catch(() => {
                //remove id from deleting array
                setDeleting((prevDeleting) =>
                  prevDeleting.filter((_) => _ !== templateId)
                );
                toast.error(
                  'An error occurred while deleting the selected template.'
                );
              });
          },
        });
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      handleMenuClose,
      menuAnchorEl,
      confirmDialogRef,
      setTemplates,
      history,
    ]);

    return (
      <>
        <VirtualizedTemplateList
          templates={templates}
          onSelect={handleOpenMenu}
          menuAnchorEl={menuAnchorEl}
          height={height}
          deleting={deleting}
        />
        <Menu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          className={classes.actionsMenu}
          disableRestoreFocus={true}
        >
          <MenuItem onClick={handleEdit}>
            <EditIcon /> Edit
          </MenuItem>
          <MenuItem onClick={handlePreview}>
            <PreviewIcon /> Preview
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <DeleteIcon /> Delete
          </MenuItem>
        </Menu>
        <Preview open={!!preview} setOpen={setPreview} template={preview} />
        <ConfirmDialog ref={confirmDialogRef} />
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps?.height === nextProps?.height &&
      prevProps?.templates?.length === nextProps?.templates?.length &&
      nextProps?.templates?.every(
        (item, index) => prevProps?.templates?.[index] === item
      )
    );
  }
);

export default TemplateList;
