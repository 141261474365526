import React from 'react';
import { Avatar, Card, Chip, IconButton, colors, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Create';
import { Link, useHistory } from 'react-router-dom';
import ImpersonateIcon from '@mui/icons-material/SupervisedUserCircle';
import { CIS_INTERNAL } from '../../utils/auth/constants';
import { useAuthContext } from '../../utils/auth/hooks';
import { generateColor } from '../../utils/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginLeft: '0px',
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
  },
  name: {
    fontSize: '1.2rem',
  },
  email: {
    color: colors.grey[600],
  },
  role: {
    margin: theme.spacing(1),
  },
  status: {
    margin: theme.spacing(1),
  },
  edit: {
    marginLeft: theme.spacing(1),
  },
  disabledChip: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  enabledChip: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  idpChip: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1),
  },
}));

export default function PersonCard(props) {
  const {
    person: {
      firstName = '',
      lastName = '',
      email = '',
      enabled = false,
      role = '',
      useExternal = null,
      provider = null,
    },
    setLoading,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const { roleIsAtLeast, activateImpersonateUser } = useAuthContext();

  const initials =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  const avatarColor = generateColor(lastName);

  const handleImpersonateUserClick = (person) => {
    activateImpersonateUser(person);
    history.push('/');
  };

  return (
    <Card className={classes.root}>
      <div className={classes.section}>
        <Avatar style={{ backgroundColor: avatarColor }}>{initials}</Avatar>

        <div className={classes.nameWrapper}>
          <span className={classes.name}>
            {firstName} {lastName}
          </span>
          <span className={classes.email}>{email}</span>
        </div>
      </div>

      <div className={classes.section}>
        {useExternal && (
          <Tooltip title={provider}>
            <Chip
              label='Uses own IDP'
              variant='outlined'
              size='small'
              className={classes.idpChip}
            />
          </Tooltip>
        )}
        <div className={classes.role}>{role}</div>

        <div className={classes.status}>
          {enabled ? (
            <Chip
              variant='outlined'
              label='Enabled'
              size='small'
              className={classes.enabledChip}
            />
          ) : (
            <Chip
              label='Disabled'
              size='small'
              className={classes.disabledChip}
            />
          )}
        </div>

        {roleIsAtLeast(CIS_INTERNAL) && (
          <Tooltip title='Impersonate User'>
            <IconButton
              aria-label='Masquerade'
              className={classes.edit}
              onClick={() => handleImpersonateUserClick(props.person)}
            >
              <ImpersonateIcon />
            </IconButton>
          </Tooltip>
        )}

        <Link
          to={{
            pathname: `/user/${encodeURIComponent(email)}`,
            state: { initialUser: props.person },
          }}
          tabIndex='0'
        >
          <Tooltip title='Edit user'>
            <IconButton aria-label='Edit' className={classes.edit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Link>
      </div>
    </Card>
  );
}
