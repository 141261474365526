export const CIS_INTERNAL = 'CIS_INTERNAL';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const RISK_MESSENGER = 'riskMessenger';
export const IBANK = 'iBank';

export const ROLE_OPTIONS = [
  {
    label: 'User',
    value: USER,
  },
  {
    label: 'Admin',
    value: ADMIN,
  },
  {
    label: 'Cornerstone Internal',
    value: CIS_INTERNAL,
  },
];

// if environment variable is set, IBANK_URL is localhost
// if REACT_APP_IS_PROD environment variable, use production ibank
// else use keystone ibank
export const IBANK_URL =
  process.env.REACT_APP_LOCAL_IBANK === 'True'
    ? 'http://localhost:8500'
    : process.env.REACT_APP_IS_PROD === 'True'
    ? 'https://apps.ciswired.com'
    : 'https://apps.dev.ciswired.com';

export const RISK_MESSENGER_PUT_URL =
  process.env.REACT_APP_IS_PROD === 'True'
    ? 'https://ot6zfiqgtc.execute-api.us-east-1.amazonaws.com/Dev/eula/riskmessenger/status'
    : 'https://9qf5h4t681.execute-api.us-east-2.amazonaws.com/Dev/eula/riskmessenger/status/';
