import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import UploadPreview from './components/UploadPreview';
import { useRouteMatch } from 'react-router';
import { useApiPost } from '../../utils/hooks';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  fileName: {
    marginLeft: '16px',
  },
}));

function Upload({ detailsRequest, setLockedNavigation }) {
  const { data, fire: refreshData } = detailsRequest;
  const classes = useStyles();
  const [fileName, setFileName] = useState('');
  const [rows, setRows] = useState([]);
  const [shiftedRows, setShiftedRows] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [ignoreFirstRow, setIgnoreFirstRow] = useState(false);
  const [openValidationDetails, setOpenValidationDetails] = useState(false);
  const [mode, setMode] = useState('');
  const [fileTooLarge, setFileTooLarge] = useState(false);

  const {
    params: { id, acct },
  } = useRouteMatch('/workflow-tables/:acct/:id') || {
    params: { id: '', acct: '' },
  };

  const {
    fire,
    data: postData,
    isLoading: postIsLoading,
    isError: postError,
  } = useApiPost(`/workflow-tables/validate/${acct}/${id}`);

  const enableUpdate =
    data?.schema?.key?.validation?.disallow_duplicates &&
    data?.schema?.key?.validation?.required;

  // Triggers when we change "Update/Delete" selection.
  function handleSelectChange(event) {
    setMode(event.target.value);
  }

  // Fires when we change the file input.
  function handleFileChange(e) {
    const file = e.target.files[0];
    if (!!file) {
      setFileName(file.name);
    } else {
      setFileName('');
      setPreviewOpen(false);
    }
    const fileInput = document.getElementById('upload-table');
    const reader = new FileReader();
    reader.onload = function (e) {
      // Read in lines of file.
      const str = e.target.result;
      const rows = str.split('\r\n');
      // pop the last record as it is blank (\n at end of file)
      rows.pop();

      if (rows.length > 10000) {
        setFileTooLarge(true);
      } else {
        setRows(rows);
        setShiftedRows(rows.slice(1));
      }
    };
    reader.readAsText(fileInput.files[0]);
  }

  function clearFile() {
    const fileInput = document.getElementById('upload-table');
    fileInput.value = [];
    setFileName('');
    setRows([]);
    setFileTooLarge(false);
    setPreviewOpen(false);
    setOpenValidationDetails(false);
  }

  async function uploadFile() {
    const postedRows = ignoreFirstRow ? shiftedRows : rows;
    await fire({
      data: data,
      fileRows: postedRows,
      mode: mode,
    });
  }

  useEffect(() => {
    if (!postIsLoading && postData) {
      setPreviewOpen(true);
    } else if (!postIsLoading && postError) {
      toast.error('Something went wrong while validating data!');
    }
  }, [postIsLoading]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <label htmlFor='upload-table'>
            <input
              style={{ display: 'none' }}
              id='upload-table'
              type='file'
              accept='.csv'
              onChange={handleFileChange}
              disabled={previewOpen}
            />
            <Button variant='contained' component='span'>
              Choose file
            </Button>
            <OutlinedInput
              id='file-name'
              disabled
              value={fileName}
              className={classes.fileName}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='clear file'
                    onClick={clearFile}
                    edge='end'
                  >
                    <DeleteIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </label>
          <p>This file will replace the current contents of the table</p>
          <FormControl fullWidth variant='standard'>
            <InputLabel id='select-label'>Upload Condition</InputLabel>
            <Tooltip
              title='Updating an existing table is only available if the key field schema is unique and required.'
              disableHoverListener={enableUpdate}
              placement='right-start'
            >
              <Select
                labelId='select-label'
                value={mode}
                label='Upload Condition'
                onChange={handleSelectChange}
                disabled={previewOpen}
              >
                <MenuItem value={'update'} disabled={!enableUpdate}>
                  Update where key exists, append where it does not
                </MenuItem>
                <MenuItem value={'replace'}>
                  Delete all existing records, add new records
                </MenuItem>
              </Select>
            </Tooltip>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                value={ignoreFirstRow}
                onChange={() => setIgnoreFirstRow(!ignoreFirstRow)}
                disabled={previewOpen}
              />
            }
            label='Ignore first row'
          />
          <p>
            The upload process will ignore the first row of the file (headers)
          </p>
          <Button
            variant='contained'
            disabled={!fileName || previewOpen || !mode}
            onClick={uploadFile}
            startIcon={<UploadIcon />}
          >
            Upload
          </Button>
        </div>
        {previewOpen && (
          <UploadPreview
            detailsRequest={detailsRequest}
            fileTooLarge={fileTooLarge}
            previewData={postData}
            rows={ignoreFirstRow ? shiftedRows : rows}
            mode={mode}
            clearFile={clearFile}
            setOpenValidationDetails={setOpenValidationDetails}
            refreshData={refreshData}
            setLockedNavigation={setLockedNavigation}
            setPreviewOpen={setPreviewOpen}
          />
        )}
      </div>
      {openValidationDetails && (
        <div>
          <h3>Validation Details</h3>
          {postData?.validationErrors?.map((e) => (
            <p key={e.msg}>{e.msg}</p>
          ))}
        </div>
      )}
    </div>
  );
}

export default Upload;
