import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { Box, Grid, Paper, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useHistory, Link } from 'react-router-dom';
import { loginStyles } from './loginStyles';
import axios from 'axios';

export const ForgotPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
  });
  const classes = loginStyles();

  function sendForgotPasswordEmail(err, email) {
    if (err.code != null && err.code === 'NotAuthorizedException') {
      // new up Axios to avoid interceptors created for axios in auth/hooks.js
      const newAxios = axios.create();
      newAxios({
        method: 'post',
        url: process.env.REACT_APP_INVOKE_URL + '/forgotPassword',
        data: { email: email },
      }).catch((err) => {
        const message = err?.response?.data?.message;
        console.error(err);
        setLoading(false);
        toast.error(message);
        return;
      });
      toast.success('Password successfully send to your email.');
      history.push('/login');
    } else if (err.code != null && err.code === 'UserNotFoundException') {
      setLoading(false);
      toast.error('User Not Found');
    } else {
      setLoading(false);
      toast.error(err.message);
    }
  }

  function goToReset(data, email) {
    console.log(data);
    setFormData({
      email: '',
    });
    setLoading(false);
    history.push('/confirm-password-reset', { email: email });
  }
  const submitForm = async () => {
    setLoading(true);
    try {
      const trimEmail = formData.email.trim();
      await Auth.forgotPassword(trimEmail)
        .then((data) => goToReset(data, trimEmail))
        .catch((err) => sendForgotPasswordEmail(err, trimEmail));
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(`Failed to reset password. ${error.message}`);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      style={{ minHeight: '100vh' }}
      className={classes.wrapper}
    >
      <Box m='auto' width={'80%'} maxWidth={400}>
        <div className={classes.logo} />

        <Box
          component={Paper}
          width={'100%'}
          bgcolor='white'
          justifyContent='center'
          display='flex'
          elevation={12}
          className={classes.paper}
        >
          {
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault();
                submitForm();
              }}
            >
              <h2 className={classes.title}>Forgot Password?</h2>
              <p className={classes.description}>
                Enter the email associated with your account and we'll send an
                email with instructions to reset your password.
              </p>

              <TextField
                variant='outlined'
                onChange={handleInputChange}
                placeholder='Enter your email address'
                label='Email'
                name='email'
                type='email'
                className={[classes.input, classes.basicInput]}
                InputLabelProps={{
                  style: { color: '#0061AD' },
                }}
              />

              <LoadingButton
                loading={loading}
                color='secondary'
                variant='contained'
                type='submit'
                disabled={!formData.email || loading}
              >
                Send Reset
              </LoadingButton>

              <Link to='/login' style={{ marginTop: '.5rem' }}>
                Return to login
              </Link>
            </form>
          }
        </Box>
      </Box>
    </Grid>
  );
};
