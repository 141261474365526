"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = _arrayLikeToArray;
function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
