import React from 'react';
import { Card, Chip, IconButton, colors } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Create';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginLeft: '0px',
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
  },
  displayName: {
    fontSize: '1.2rem',
  },
  name: {
    color: colors.grey[600],
  },
  status: {
    margin: theme.spacing(1),
  },
  edit: {
    marginLeft: theme.spacing(1),
  },
  disabledChip: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  enabledChip: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  idpChip: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1),
  },
}));

export default function OrganizationCard(props) {
  const {
    organization: {
      name = '',
      displayName = '',
      isActive: { enabled = true },
      usesOwnIdp = null,
    },
  } = props;

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.section}>
        <div className={classes.nameWrapper}>
          <span className={classes.displayName}>{displayName}</span>
          <span className={classes.name}>{name}</span>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.status}>
          {enabled ? (
            <Chip
              variant='outlined'
              label='Enabled'
              size='small'
              className={classes.enabledChip}
            />
          ) : (
            <Chip
              label='Disabled'
              size='small'
              className={classes.disabledChip}
            />
          )}

          {usesOwnIdp && (
            <Chip
              label='Uses own IDP'
              variant='outlined'
              size='small'
              className={classes.idpChip}
            />
          )}
        </div>

        <Link to={`/organization/${encodeURIComponent(name)}`}>
          <IconButton aria-label='Edit' className={classes.edit}>
            <EditIcon />
          </IconButton>
        </Link>
      </div>
    </Card>
  );
}
